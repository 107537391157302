const getDefaultState = () => {
  return {
    guidRichiestaDettaglioImportazione: null,
    statoDettaglioImportazione: null,
    rowsToSkipDettaglioImportazione: 0,
    fetchRowsDettaglioImportazione: 25,
    currentPageDettaglioImportazione: 1,
    sortColumnDettaglioImportazione: "data_richiesta",
    sortOrderDettaglioImportazione: "desc",
  };
};
const state = getDefaultState();

const getters = {
  guidRichiestaDettaglioImportazione: (state) =>
    state.guidRichiestaDettaglioImportazione,
  statoDettaglioImportazione: (state) => state.statoDettaglioImportazione,
  rowsToSkipDettaglioImportazione: (state) =>
    state.rowsToSkipDettaglioImportazione,
  fetchRowsDettaglioImportazione: (state) =>
    state.fetchRowsDettaglioImportazione,
  currentPageDettaglioImportazione: (state) =>
    state.currentPageDettaglioImportazione,
  sortColumnDettaglioImportazione: (state) =>
    state.sortColumnDettaglioImportazione,
  sortOrderDettaglioImportazione: (state) =>
    state.sortOrderDettaglioImportazione,
};

// const actions = {};
const mutations = {
  setGuidRichiestaDettaglioImportazione: (state, value) => {
    state.guidRichiestaDettaglioImportazione = value;
  },
  setStatoDettaglioImportazione: (state, value) => {
    state.statoDettaglioImportazione = value;
  },
  setFetchRowsDettaglioImportazione: (state, value) => {
    state.currentPageDettaglioImportazione = 1;
    state.rowsToSkipDettaglioImportazione = 0;
    state.fetchRowsDettaglioImportazione = value;
  },
  setCurrentPageDettaglioImportazione: (state, value) => {
    state.currentPageDettaglioImportazione = value;
    state.rowsToSkipDettaglioImportazione = parseInt(
      (state.currentPageDettaglioImportazione - 1) *
        state.fetchRowsDettaglioImportazione
    );
  },
  setSortColumnDettaglioImportazione: (state, value) => {
    state.sortColumnDettaglioImportazione = value;
  },
  setSortOrderDettaglioImportazione: (state, value) => {
    state.sortOrderDettaglioImportazione = value;
  },
  resetFiltersDettaglioImportazione: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipDettaglioImportazione: (state) => {
    state.currentPageDettaglioImportazione = 1;
    state.rowsToSkipDettaglioImportazione = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
