const getDefaultState = () => {
  return {
    idTipoAlbo: null,
    idCategoriaAlbo: null,
    idQualificaAlbo: null,
    codiceSnmAlbo: null,
    dataAcqDaAlbo: null,
    dataAcqAAlbo: null,
    dataDecDaAlbo: null,
    dataDecAAlbo: null,
    dataFineDaAlbo: null,
    dataFineAAlbo: null,
    nomeAlbo: null,
    cognomeAlbo: null,
    idSocietaAlbo: null,
    verificaAlbo: null,
    statoAlbo: null,
    statoUtenzaAlbo: null,
    rowsToSkipAlbo: 0,
    fetchRowsAlbo: 25,
    currentPageAlbo: 1,
    sortColumnAlbo: "nominativo_completo",
    sortOrderAlbo: "asc",
    societaDenominazioneAlbo: null,
    statoAggiornamentoAlbo: null,
    statoTesseramentoAlbo: null,
    stagionePagamentoDa: null,
    stagionePagamentoA: null,
    pagamentiNonRegistrati: null,
    idComitatoRegionaleAlbo: null,
  };
};
const state = getDefaultState();

const getters = {
  idTipoAlbo: (state) => state.idTipoAlbo,
  idCategoriaAlbo: (state) => state.idCategoriaAlbo,
  idQualificaAlbo: (state) => state.idQualificaAlbo,
  codiceSnmAlbo: (state) => state.codiceSnmAlbo,
  dataAcqDaAlbo: (state) => state.dataAcqDaAlbo,
  dataAcqAAlbo: (state) => state.dataAcqAAlbo,
  dataDecDaAlbo: (state) => state.dataDecDaAlbo,
  dataDecAAlbo: (state) => state.dataDecAAlbo,
  dataFineDaAlbo: (state) => state.dataFineDaAlbo,
  dataFineAAlbo: (state) => state.dataFineAAlbo,
  nomeAlbo: (state) => state.nomeAlbo,
  cognomeAlbo: (state) => state.cognomeAlbo,
  idSocietaAlbo: (state) => state.idSocietaAlbo,
  verificaAlbo: (state) => state.verificaAlbo,
  statoAlbo: (state) => state.statoAlbo,
  statoUtenzaAlbo: (state) => state.statoUtenzaAlbo,
  societaDenominazioneAlbo: (state) => state.societaDenominazioneAlbo,
  rowsToSkipAlbo: (state) => state.rowsToSkipAlbo,
  fetchRowsAlbo: (state) => state.fetchRowsAlbo,
  currentPageAlbo: (state) => state.currentPageAlbo,
  sortColumnAlbo: (state) => state.sortColumnAlbo,
  sortOrderAlbo: (state) => state.sortOrderAlbo,
  statoAggiornamentoAlbo: (state) => state.statoAggiornamentoAlbo,
  statoTesseramentoAlbo: (state) => state.statoTesseramentoAlbo,
  stagionePagamentoDa: (state) => state.stagionePagamentoDa,
  stagionePagamentoA: (state) => state.stagionePagamentoA,
  pagamentiNonRegistrati: (state) => state.pagamentiNonRegistrati,
  idComitatoRegionaleAlbo: (state) => state.idComitatoRegionaleAlbo,
};

// const actions = {};
const mutations = {
  setIdTipoAlbo: (state, value) => {
    state.idTipoAlbo = value;
  },
  setIdCategoriaAlbo: (state, value) => {
    state.idCategoriaAlbo = value;
  },
  setIdQualificaAlbo: (state, value) => {
    state.idQualificaAlbo = value;
  },
  setCodiceSnmAlbo: (state, value) => {
    state.codiceSnmAlbo = value;
  },
  setDataAcqDaAlbo: (state, value) => {
    state.dataAcqDaAlbo = value;
  },
  setDataAcqAAlbo: (state, value) => {
    state.dataAcqAAlbo = value;
  },
  setDataDecDaAlbo: (state, value) => {
    state.dataDecDaAlbo = value;
  },
  setDataDecAAlbo: (state, value) => {
    state.dataDecAAlbo = value;
  },
  setDataFineDaAlbo: (state, value) => {
    state.dataFineDaAlbo = value;
  },
  setDataFineAAlbo: (state, value) => {
    state.dataFineAAlbo = value;
  },
  setNomeAlbo: (state, value) => {
    state.nomeAlbo = value;
  },
  setCognomeAlbo: (state, value) => {
    state.cognomeAlbo = value;
  },
  setIdSocietaAlbo: (state, value) => {
    state.idSocietaAlbo = value;
  },
  setVerificaAlbo: (state, value) => {
    state.verificaAlbo = value;
  },
  setStatoAlbo: (state, value) => {
    state.statoAlbo = value;
  },
  setStatoUtenzaAlbo: (state, value) => {
    state.statoUtenzaAlbo = value;
  },
  setSocietaDenominazioneAlbo: (state, value) => {
    state.societaDenominazioneAlbo = value;
  },
  setStatoAggiornamentoAlbo: (state, value) => {
    state.statoAggiornamentoAlbo = value;
  },
  setStatoTesseramentoAlbo: (state, value) => {
    state.statoTesseramentoAlbo = value;
  },
  setStagionePagamentoDa: (state, value) => {
    state.stagionePagamentoDa = value;
  },
  setStagionePagamentoA: (state, value) => {
    state.stagionePagamentoA = value;
  },
  setPagamentiNonRegistrati: (state, value) => {
    state.pagamentiNonRegistrati = value;
  },
  setIdComitatoRegionaleAlbo: (state, value) => {
    state.idComitatoRegionaleAlbo = value;
  },
  setFetchRowsAlbo: (state, value) => {
    state.currentPageAlbo = 1;
    state.rowsToSkipAlbo = 0;
    state.fetchRowsAlbo = value;
  },
  setCurrentPageAlbo: (state, value) => {
    state.currentPageAlbo = value;
    state.rowsToSkipAlbo = parseInt(
      (state.currentPageAlbo - 1) * state.fetchRowsAlbo
    );
  },
  setSortColumnAlbo: (state, value) => {
    state.sortColumnAlbo = value;
  },
  setSortOrderAlbo: (state, value) => {
    state.sortOrderAlbo = value;
  },
  resetFiltersAlbo: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipAlbo: (state) => {
    state.currentPageAlbo = 1;
    state.rowsToSkipAlbo = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
