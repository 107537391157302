const getDefaultState = () => {
  return {
    stagioneEconomatoPagamenti: null,
    idComitatoRegionalePagamenti: null,
    dataInsDaPagamenti: null,
    dataInsAPagamenti: null,
    dataApprDaPagamenti: null,
    dataApprAPagamenti: null,
    tipoPagamentoPagamenti: null,
    tipoCredDebPagamenti: null,
    approvatoPagamenti: null,
    idSocietaPagamenti: null,
    nomeSocietaPagamenti: "",
    idTesseratoPagamenti: null,
    nomeTesseratoPagamenti: "",
    numeroPagamenti: null,
    meseApprovazionePagamenti: null,
    tutteStagioniPagamentiEconomato: false,
    rowsToSkipPagamenti: 0,
    fetchRowsPagamenti: 25,
    currentPagePagamenti: 1,
    sortColumnPagamenti: "numero",
    sortOrderPagamenti: "desc",
  };
};
const state = getDefaultState();

const getters = {
  stagioneEconomatoPagamenti: (state) => state.stagioneEconomatoPagamenti,
  idComitatoRegionalePagamenti: (state) => state.idComitatoRegionalePagamenti,
  dataInsDaPagamenti: (state) => state.dataInsDaPagamenti,
  dataInsAPagamenti: (state) => state.dataInsAPagamenti,
  dataApprDaPagamenti: (state) => state.dataApprDaPagamenti,
  dataApprAPagamenti: (state) => state.dataApprAPagamenti,
  tipoPagamentoPagamenti: (state) => state.tipoPagamentoPagamenti,
  tipoCredDebPagamenti: (state) => state.tipoCredDebPagamenti,
  approvatoPagamenti: (state) => state.approvatoPagamenti,
  idSocietaPagamenti: (state) => state.idSocietaPagamenti,
  nomeSocietaPagamenti: (state) => state.nomeSocietaPagamenti,
  idTesseratoPagamenti: (state) => state.idTesseratoPagamenti,
  nomeTesseratoPagamenti: (state) => state.nomeTesseratoPagamenti,
  numeroPagamenti: (state) => state.numeroPagamenti,
  meseApprovazionePagamenti: (state) => state.meseApprovazionePagamenti,
  tutteStagioniPagamentiEconomato: (state) =>
    state.tutteStagioniPagamentiEconomato,
  rowsToSkipPagamenti: (state) => state.rowsToSkipPagamenti,
  fetchRowsPagamenti: (state) => state.fetchRowsPagamenti,
  currentPagePagamenti: (state) => state.currentPagePagamenti,
  sortColumnPagamenti: (state) => state.sortColumnPagamenti,
  sortOrderPagamenti: (state) => state.sortOrderPagamenti,
};

// const actions = {};
const mutations = {
  setNomeSocietaPagamenti: (state, value) => {
    state.nomeSocietaPagamenti = value ? value : null;
  },
  setNomeTesseratoPagamenti: (state, value) => {
    state.nomeTesseratoPagamenti = value ? value : null;
  },
  setIdComitatoRegionalePagamenti: (state, value) => {
    state.idComitatoRegionalePagamenti = value;
  },
  setDataInsDaPagamenti: (state, value) => {
    state.dataInsDaPagamenti = value;
  },
  setDataInsAPagamenti: (state, value) => {
    state.dataInsAPagamenti = value;
  },
  setDataApprDaPagamenti: (state, value) => {
    state.dataApprDaPagamenti = value;
  },
  setDataApprAPagamenti: (state, value) => {
    state.dataApprAPagamenti = value;
  },
  setTipoPagamentoPagamenti: (state, value) => {
    state.tipoPagamentoPagamenti = value;
  },
  setTipoCredDebPagamenti: (state, value) => {
    state.tipoCredDebPagamenti = value;
  },
  setApprovatoPagamenti: (state, value) => {
    state.approvatoPagamenti = value;
  },
  setSocietaPagamenti: (state, value) => {
    state.idSocietaPagamenti = value ? value.value : null;
    state.nomeSocietaPagamenti = value ? value.name : null;
  },
  setTesseratoPagamenti: (state, value) => {
    state.idTesseratoPagamenti = value ? value.value : null;
    state.nomeTesseratoPagamenti = value ? value.name : null;
  },
  setNumeroPagamenti: (state, value) => {
    state.numeroPagamenti = value;
  },
  setMeseApprovazionePagamenti: (state, value) => {
    state.meseApprovazionePagamenti = value;
  },

  setFetchRowsPagamenti: (state, value) => {
    state.currentPagePagamenti = 1;
    state.rowsToSkipPagamenti = 0;
    state.fetchRowsPagamenti = value;
  },
  setCurrentPagePagamenti: (state, value) => {
    state.currentPagePagamenti = value;
    state.rowsToSkipPagamenti = parseInt(
      (state.currentPagePagamenti - 1) * state.fetchRowsPagamenti
    );
  },
  setSortColumnPagamenti: (state, value) => {
    state.sortColumnPagamenti = value;
  },
  setSortOrderPagamenti: (state, value) => {
    state.sortOrderPagamenti = value;
  },
  setCategoriaTesseraPagamenti: (state, value) => {
    state.id_categoriaPagamenti = value;
  },
  resetFiltersPagamenti: (state) => {
    Object.assign(state, getDefaultState());
  },
  resetRowsToSkipPagamenti: (state) => {
    state.currentPagePagamenti = 1;
    state.rowsToSkipPagamenti = 0;
  },
  setTutteLeStagioniPagamentiEconomato: (state) => {
    state.tutteStagioniPagamentiEconomato =
      !state.tutteStagioniPagamentiEconomato;
    state.currentPagePagamenti = 1;
    state.rowsToSkipPagamenti = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
