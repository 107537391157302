const getDefaultState = () => {
  return {
    rowsToSkipDocPersona: 0,
    fetchRowsDocPersona: 25,
    sortColumnDocPersona: "id",
    sortOrderDocPersona: "asc",
    currentPageDocPersona: 1,
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipDocPersona: (state) => state.rowsToSkipDocPersona,
  fetchRowsDocPersona: (state) => state.fetchRowsDocPersona,
  sortColumnDocPersona: (state) => state.sortColumnDocPersona,
  sortOrderDocPersona: (state) => state.sortOrderDocPersona,
  currentPageDocPersona: (state) => state.currentPageDocPersona,
};

// const actions = {};
const mutations = {
  setFetchRowsDocPersona: (state, value) => {
    state.currentPageDocPersona = 1;
    state.rowsToSkipDocPersona = 0;
    state.fetchRowsDocPersona = value;
  },
  setCurrentPageDocPersona: (state, value) => {
    state.currentPageDocPersona = value;
    state.rowsToSkipDocPersona = parseInt(
      (state.currentPageDocPersona - 1) * state.fetchRowsDocPersona
    );
  },
  setSortColumnDocPersona: (state, value) => {
    state.sortColumnDocPersona = value;
  },
  setSortOrderDocPersona: (state, value) => {
    state.sortOrderDocPersona = value;
  },
  resetFiltersDocPersona: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
