import services from "../axios/dbManag";

export const Login = (credentials) => {
  return services.dbManag
    .post("/management/user/login", {
      ...credentials,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const recuperaUsername = (values) => {
  return services.dbManag
    .post("/management/user/recupera/username", { ...values })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const recuperaPassword = (values) => {
  return services.dbManag
    .post("/management/recupera/password", { ...values })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const newPassword = (internal_guid, password, confirm_password) => {
  return services.dbManag
    .post("/management/reset/password", {
      internal_guid,
      password,
      confirm_password,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
