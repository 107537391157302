const state = {
  isInManutenzione: false,
};

const getters = {
  isInManutenzione: (state) => state.isInManutenzione,
};
const mutations = {
  setisInManutenzione: (state, value) => {
    state.isInManutenzione = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
