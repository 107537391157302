const getDefaultState = () => {
  return {
    rowsToSkipPrivacyTesEnte: 0,
    fetchRowsPrivacyTesEnte: 25,
    currentPagePrivacyTesEnte: 1,
    sortColumnPrivacyTesEnte: "nominativo",
    sortOrderPrivacyTesEnte: "asc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipPrivacyTesEnte: (state) => state.rowsToSkipPrivacyTesEnte,
  fetchRowsPrivacyTesEnte: (state) => state.fetchRowsPrivacyTesEnte,
  currentPagePrivacyTesEnte: (state) => state.currentPagePrivacyTesEnte,
  sortColumnPrivacyTesEnte: (state) => state.sortColumnPrivacyTesEnte,
  sortOrderPrivacyTesEnte: (state) => state.sortOrderPrivacyTesEnte,
};

// const actions = {};
const mutations = {
  setFetchRowsPrivacyTesEnte: (state, value) => {
    state.currentPagePrivacyTesEnte = 1;
    state.rowsToSkipPrivacyTesEnte = 0;
    state.fetchRowsPrivacyTesEnte = value;
  },
  setCurrentPagePrivacyTesEnte: (state, value) => {
    state.currentPagePrivacyTesEnte = value;
    state.rowsToSkipPrivacyTesEnte = parseInt(
      (state.currentPagePrivacyTesEnte - 1) * state.fetchRowsPrivacyTesEnte
    );
  },
  setSortColumnPrivacyTesEnte: (state, value) => {
    state.sortColumnPrivacyTesEnte = value;
  },
  setSortOrderPrivacyTesEnte: (state, value) => {
    state.sortOrderPrivacyTesEnte = value;
  },
  resetFiltersPrivacyTesEnte: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
