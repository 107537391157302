const getDefaultState = () => {
  return {
    statoRichiesteUnaSoc: null,
    rowsToSkipRichiesteUnaSoc: 0,
    fetchRowsRichiesteUnaSoc: 25,
    currentPageRichiesteUnaSoc: 1,
    sortColumnRichiesteUnaSoc: "data_richiesta",
    sortOrderRichiesteUnaSoc: "desc",
  };
};
const state = getDefaultState();

const getters = {
  statoRichiesteUnaSoc: (state) => state.statoRichiesteUnaSoc,
  rowsToSkipRichiesteUnaSoc: (state) => state.rowsToSkipRichiesteUnaSoc,
  fetchRowsRichiesteUnaSoc: (state) => state.fetchRowsRichiesteUnaSoc,
  currentPageRichiesteUnaSoc: (state) => state.currentPageRichiesteUnaSoc,
  sortColumnRichiesteUnaSoc: (state) => state.sortColumnRichiesteUnaSoc,
  sortOrderRichiesteUnaSoc: (state) => state.sortOrderRichiesteUnaSoc,
};

// const actions = {};
const mutations = {
  setStatoRichiesteUnaSoc: (state, value) => {
    state.statoRichiesteUnaSoc = value;
  },
  setFetchRowsRichiesteUnaSoc: (state, value) => {
    state.currentPageRichiesteUnaSoc = 1;
    state.rowsToSkipRichiesteUnaSoc = 0;
    state.fetchRowsRichiesteUnaSoc = value;
  },
  setCurrentPageRichiesteUnaSoc: (state, value) => {
    state.currentPageRichiesteUnaSoc = value;
    state.rowsToSkipRichiesteUnaSoc = parseInt(
      (state.currentPageRichiesteUnaSoc - 1) * state.fetchRowsRichiesteUnaSoc
    );
  },
  setSortColumnRichiesteUnaSoc: (state, value) => {
    state.sortColumnRichiesteUnaSoc = value;
  },
  setSortOrderRichiesteUnaSoc: (state, value) => {
    state.sortOrderRichiesteUnaSoc = value;
  },
  resetFiltersRichiesteUnaSoc: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipRichiesteUnaSoc: (state) => {
    state.currentPageRichiesteUnaSoc = 1;
    state.rowsToSkipRichiesteUnaSoc = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
