const getDefaultState = () => {
  return {
    rowsToSkipRichiestaImportazioni: 0,
    fetchRowsRichiestaImportazioni: 25,
    currentPageRichiestaImportazioni: 1,
    sortColumnRichiestaImportazioni: "",
    sortOrderRichiestaImportazioni: "asc",

    id_tipologiaRichiestaImportazioni: null,
    data_richiesta_daRichiestaImportazioni: null,
    data_richiesta_aRichiestaImportazioni: null,
    id_societaRichiestaImportazioni: null,
    id_statoRichiestaImportazioni: null,
    nome_societaRichiestaImportazioni: "",
    chiaveRichiestaImportazioni: null,
  };
};
const state = getDefaultState();

const getters = {
  tipo_qualificaRichiestaImportazioni: (state) =>
    state.tipo_qualificaRichiestaImportazioni,
  rowsToSkipRichiestaImportazioni: (state) =>
    state.rowsToSkipRichiestaImportazioni,
  fetchRowsRichiestaImportazioni: (state) =>
    state.fetchRowsRichiestaImportazioni,
  currentPageRichiestaImportazioni: (state) =>
    state.currentPageRichiestaImportazioni,
  sortColumnRichiestaImportazioni: (state) =>
    state.sortColumnRichiestaImportazioni,
  sortOrderRichiestaImportazioni: (state) =>
    state.sortOrderRichiestaImportazioni,

  id_tipologiaRichiestaImportazioni: (state) =>
    state.id_tipologiaRichiestaImportazioni,
  data_richiesta_daRichiestaImportazioni: (state) =>
    state.data_richiesta_daRichiestaImportazioni,
  data_richiesta_aRichiestaImportazioni: (state) =>
    state.data_richiesta_aRichiestaImportazioni,
  id_statoRichiestaImportazioni: (state) => state.id_statoRichiestaImportazioni,
  id_societaRichiestaImportazioni: (state) =>
    state.id_societaRichiestaImportazioni,
  nome_societaRichiestaImportazioni: (state) =>
    state.nome_societaRichiestaImportazioni,
  chiaveRichiestaImportazioni: (state) => state.chiaveRichiestaImportazioni,
};

// const actions = {};
const mutations = {
  setFilterRichiestaImportazioni: (
    state,
    { nome_variabile, valore_variabile }
  ) => {
    state[nome_variabile] = valore_variabile;
  },
  setCurrentPageRichiestaImportazioni: (state, value) => {
    state.currentPageRichiestaImportazioni = value;
    state.rowsToSkipRichiestaImportazioni = parseInt(
      (state.currentPageRichiestaImportazioni - 1) *
        state.fetchRowsRichiestaImportazioni
    );
  },
  resetFiltersRichiestaImportazioni: (state) => {
    Object.assign(state, getDefaultState());
  },
  setSortColumnRichiestaImportazioni: (state, value) => {
    state.sortColumnRichiestaImportazioni = value;
  },
  setSortOrderRichiestaImportazioni: (state, value) => {
    state.sortOrderRichiestaImportazioni = value;
  },
  setId_personaRichiestaImportazioni: (state, value) => {
    state.id_personaRichiestaImportazioni = value;
  },
  setNomepersonaRichiestaImportazioni: (state, value) => {
    state.nomePersonaRichiestaImportazioni = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
