import store from "@/store/index";
const checkIfArrayElements = (element) => {
  const arrayElements = "dismesso";
  if (arrayElements.includes(element)) return element;
};
export default function useDisableElement(actualRoute) {
  store.state.CampiLocked.arrayCampiLocked.forEach((objCampiLocked) => {
    if (objCampiLocked.route === actualRoute) {
      if (objCampiLocked.campi_Locked) {
        const arrayCampiLocked = objCampiLocked.campi_Locked
          ? objCampiLocked.campi_Locked.split(",")
          : null;
        const interval = setInterval(() => {
          arrayCampiLocked.forEach((element) => {
            // const elementsToDisable = document.getElementsByName(element);
            const elementsToDisable = document.querySelectorAll(
              `${
                element === checkIfArrayElements(element)
                  ? `[name^=${checkIfArrayElements(element)}]`
                  : `[name=${element}]`
              }`
            );
            elementsToDisable.forEach((htmlElement) => {
              // controllo svg datepicker
              const svgDPElements = htmlElement.offsetParent
                ? htmlElement.offsetParent.getElementsByTagName("svg")
                : null;
              if (svgDPElements) {
                for (const key in svgDPElements) {
                  if (Object.hasOwnProperty.call(svgDPElements, key)) {
                    const element = svgDPElements[key];
                    if (element.className.baseVal.includes("dp__clear_icon "))
                      element.remove();
                  }
                }
              }
              if (htmlElement) {
                htmlElement.disabled = true;
                clearInterval(interval);
              }
              if (htmlElement.classList.contains("dp__input"))
                htmlElement.classList.add("dp__disabled");
            });
          });
        }, 300);
      }
    }
  });
  store.commit("setFilteredLimitations", actualRoute);
}
