const getDefaultState = () => {
  return {
    idTipologiaImportazioni: null,
    dataImportazioniDaSelected: null,
    dataImportazioniASelected: null,
    rowsToSkipImportazioni: 0,
    fetchRowsImportazioni: 25,
    currentPageImportazioni: 1,
    sortColumnImportazioni: "data_richiesta",
    sortOrderImportazioni: "desc",
  };
};
const state = getDefaultState();

const getters = {
  idTipologiaImportazioni: (state) => state.idTipologiaImportazioni,
  dataImportazioniDaSelected: (state) => state.dataImportazioniDaSelected,
  dataImportazioniASelected: (state) => state.dataImportazioniASelected,
  rowsToSkipImportazioni: (state) => state.rowsToSkipImportazioni,
  fetchRowsImportazioni: (state) => state.fetchRowsImportazioni,
  currentPageImportazioni: (state) => state.currentPageImportazioni,
  sortColumnImportazioni: (state) => state.sortColumnImportazioni,
  sortOrderImportazioni: (state) => state.sortOrderImportazioni,
};

// const actions = {};
const mutations = {
  setTipologiaImportazioni: (state, value) => {
    state.idTipologiaImportazioni = value;
  },
  setDataImportazioniDaSelected: (state, value) => {
    state.dataImportazioniDaSelected = value;
  },
  setDataImportazioniASelected: (state, value) => {
    state.dataImportazioniASelected = value;
  },
  setFetchRowsImportazioni: (state, value) => {
    state.currentPageImportazioni = 1;
    state.rowsToSkipImportazioni = 0;
    state.fetchRowsImportazioni = value;
  },
  setCurrentPageImportazioni: (state, value) => {
    state.currentPageImportazioni = value;
    state.rowsToSkipImportazioni = parseInt(
      (state.currentPageImportazioni - 1) * state.fetchRowsImportazioni
    );
  },
  setSortColumnImportazioni: (state, value) => {
    state.sortColumnImportazioni = value;
  },
  setSortOrderImportazioni: (state, value) => {
    state.sortOrderImportazioni = value;
  },
  resetFiltersImportazioni: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipImportazioni: (state) => {
    state.currentPageImportazioni = 1;
    state.rowsToSkipImportazioni = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
