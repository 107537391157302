const getDefaultStateUtentiOmologatori = () => {
  return {
    nomeUtenteUtentiOmologatori: null,
    cognomeUtentiOmologatori: null,
    rowsToSkipUtentiOmologatori: 0,
    fetchRowsUtentiOmologatori: 25,
    currentPageUtentiOmologatori: 1,
    sortColumnUtentiOmologatori: "persona",
    sortOrderUtentiOmologatori: "asc",
  };
};
const state = getDefaultStateUtentiOmologatori();

const getters = {
  nomeUtenteUtentiOmologatori: (state) => state.nomeUtenteUtentiOmologatori,
  cognomeUtentiOmologatori: (state) => state.cognomeUtentiOmologatori,
  rowsToSkipUtentiOmologatori: (state) => state.rowsToSkipUtentiOmologatori,
  fetchRowsUtentiOmologatori: (state) => state.fetchRowsUtentiOmologatori,
  currentPageUtentiOmologatori: (state) => state.currentPageUtentiOmologatori,
  sortColumnUtentiOmologatori: (state) => state.sortColumnUtentiOmologatori,
  sortOrderUtentiOmologatori: (state) => state.sortOrderUtentiOmologatori,
};

// const actions = {};
const mutations = {
  setNomeUtenteUtentiOmologatori: (state, value) => {
    state.nomeUtenteUtentiOmologatori = value;
  },
  setCognomeUtentiOmologatori: (state, value) => {
    state.cognomeUtentiOmologatori = value;
  },
  setFetchRowsUtentiOmologatori: (state, value) => {
    state.currentPageUtentiOmologatori = 1;
    state.rowsToSkipUtentiOmologatori = 0;
    state.fetchRowsUtentiOmologatori = value;
  },
  setCurrentPageUtentiOmologatori: (state, value) => {
    state.currentPageUtentiOmologatori = value;
    state.rowsToSkipUtentiOmologatori = parseInt(
      (state.currentPageUtentiOmologatori - 1) *
        state.fetchRowsUtentiOmologatori
    );
  },
  setSortColumnUtentiOmologatori: (state, value) => {
    state.sortColumnUtentiOmologatori = value;
  },
  setSortOrderUtentiOmologatori: (state, value) => {
    state.sortOrderUtentiOmologatori = value;
  },
  setCategoriaUtentiOmologatori: (state, value) => {
    state.id_categoriaUtentiOmologatori = value;
  },
  resetFiltersUtentiOmologatori: (state) => {
    Object.assign(state, getDefaultStateUtentiOmologatori());
  },
  resetRowsToSkipUtentiOmologatori: (state) => {
    state.currentPageUtentiOmologatori = 1;
    state.rowsToSkipUtentiOmologatori = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
