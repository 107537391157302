const getDefaultState = () => {
  return {
    rowsToSkipSanzioniUnaSoc: 0,
    fetchRowsSanzioniUnaSoc: 25,
    currentPageSanzioniUnaSoc: 1,
    sortColumnSanzioniUnaSoc: "data_reg",
    sortOrderSanzioniUnaSoc: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipSanzioniUnaSoc: (state) => state.rowsToSkipSanzioniUnaSoc,
  fetchRowsSanzioniUnaSoc: (state) => state.fetchRowsSanzioniUnaSoc,
  currentPageSanzioniUnaSoc: (state) => state.currentPageSanzioniUnaSoc,
  sortColumnSanzioniUnaSoc: (state) => state.sortColumnSanzioniUnaSoc,
  sortOrderSanzioniUnaSoc: (state) => state.sortOrderSanzioniUnaSoc,
};

// const actions = {};
const mutations = {
  setFetchRowsSanzioniUnaSoc: (state, value) => {
    state.currentPageSanzioniUnaSoc = 1;
    state.rowsToSkipSanzioniUnaSoc = 0;
    state.fetchRowsSanzioniUnaSoc = value;
  },
  setCurrentPageSanzioniUnaSoc: (state, value) => {
    state.currentPageSanzioniUnaSoc = value;
    state.rowsToSkipSanzioniUnaSoc = parseInt(
      (state.currentPageSanzioniUnaSoc - 1) * state.fetchRowsSanzioniUnaSoc
    );
  },
  setSortColumnSanzioniUnaSoc: (state, value) => {
    state.sortColumnSanzioniUnaSoc = value;
  },
  setSortOrderSanzioniUnaSoc: (state, value) => {
    state.sortOrderSanzioniUnaSoc = value;
  },
  resetFiltersSanzioniUnaSoc: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
