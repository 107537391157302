const getDefaultStateUtentiPersone = () => {
  return {
    nomeUtenteUtentiPersone: null,
    cognomeUtentiPersone: null,
    rowsToSkipUtentiPersone: 0,
    fetchRowsUtentiPersone: 25,
    currentPageUtentiPersone: 1,
    sortColumnUtentiPersone: "persona",
    sortOrderUtentiPersone: "asc",
  };
};
const state = getDefaultStateUtentiPersone();

const getters = {
  nomeUtenteUtentiPersone: (state) => state.nomeUtenteUtentiPersone,
  cognomeUtentiPersone: (state) => state.cognomeUtentiPersone,
  rowsToSkipUtentiPersone: (state) => state.rowsToSkipUtentiPersone,
  fetchRowsUtentiPersone: (state) => state.fetchRowsUtentiPersone,
  currentPageUtentiPersone: (state) => state.currentPageUtentiPersone,
  sortColumnUtentiPersone: (state) => state.sortColumnUtentiPersone,
  sortOrderUtentiPersone: (state) => state.sortOrderUtentiPersone,
};

// const actions = {};
const mutations = {
  setNomeUtenteUtentiPersone: (state, value) => {
    state.nomeUtenteUtentiPersone = value;
  },
  setCognomeUtentiPersone: (state, value) => {
    state.cognomeUtentiPersone = value;
  },
  setFetchRowsUtentiPersone: (state, value) => {
    state.currentPageUtentiPersone = 1;
    state.rowsToSkipUtentiPersone = 0;
    state.fetchRowsUtentiPersone = value;
  },
  setCurrentPageUtentiPersone: (state, value) => {
    state.currentPageUtentiPersone = value;
    state.rowsToSkipUtentiPersone = parseInt(
      (state.currentPageUtentiPersone - 1) * state.fetchRowsUtentiPersone
    );
  },
  setSortColumnUtentiPersone: (state, value) => {
    state.sortColumnUtentiPersone = value;
  },
  setSortOrderUtentiPersone: (state, value) => {
    state.sortOrderUtentiPersone = value;
  },
  setCategoriaUtentiPersone: (state, value) => {
    state.id_categoriaUtentiPersone = value;
  },
  resetFiltersUtentiPersone: (state) => {
    Object.assign(state, getDefaultStateUtentiPersone());
  },
  resetRowsToSkipUtentiPersone: (state) => {
    state.currentPageUtentiPersone = 1;
    state.rowsToSkipUtentiPersone = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
