const getDefaultState = () => {
  return {
    denominazioneImpAss: null,
    provinciaImpAss: null,
    comuneImpAss: null,
    rowsToSkipImpAss: 0,
    fetchRowsImpAss: 25,
    currentPageImpAss: 1,
    sortColumnImpAss: "id",
    sortOrderImpAss: "desc",
  };
};
const state = getDefaultState();

const getters = {
  denominazioneImpAss: (state) => state.denominazioneImpAss,
  provinciaImpAss: (state) => state.provinciaImpAss,
  comuneImpAss: (state) => state.comuneImpAss,
  rowsToSkipImpAss: (state) => state.rowsToSkipImpAss,
  fetchRowsImpAss: (state) => state.fetchRowsImpAss,
  currentPageImpAss: (state) => state.currentPageImpAss,
  sortColumnImpAss: (state) => state.sortColumnImpAss,
  sortOrderImpAss: (state) => state.sortOrderImpAss,
};

// const actions = {};
const mutations = {
  setDenominazioneImpAss: (state, value) => {
    state.denominazioneImpAss = value;
  },
  setProvinciaImpAss: (state, value) => {
    state.provinciaImpAss = value;
  },
  setComuneImpAss: (state, value) => {
    state.comuneImpAss = value;
  },
  setFetchRowsImpAss: (state, value) => {
    state.currentPageImpAss = 1;
    state.rowsToSkipImpAss = 0;
    state.fetchRowsImpAss = value;
  },
  setCurrentPageImpAss: (state, value) => {
    state.currentPageImpAss = value;
    state.rowsToSkipImpAss = parseInt(
      (state.currentPageImpAss - 1) * state.fetchRowsImpAss
    );
  },
  setSortColumnImpAss: (state, value) => {
    state.sortColumnImpAss = value;
  },
  setSortOrderImpAss: (state, value) => {
    state.sortOrderImpAss = value;
  },
  resetFiltersImpAss: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipImpAss: (state) => {
    state.currentPageImpAss = 1;
    state.rowsToSkipImpAss = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
