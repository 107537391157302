const getDefaultState = () => {
  return {
    idFamigliaQuote: null,
    idSottofamigliaQuote: null,
    tipoQuote: null,
    descrizioneQuote: null,
    idReportPrimoLivelloQuote: null,
    idReportSecondoLivelloQuote: null,
    causaleQuote: null,
    nonAssociateQuote: false,

    rowsToSkipQuote: 0,
    fetchRowsQuote: 25,
    currentPageQuote: 1,
    sortColumnQuote: "id_famiglia",
    sortOrderQuote: "desc",
  };
};
const state = getDefaultState();

const getters = {
  idFamigliaQuote: (state) => state.idFamigliaQuote,
  idSottofamigliaQuote: (state) => state.idSottofamigliaQuote,
  tipoQuote: (state) => state.tipoQuote,
  descrizioneQuote: (state) => state.descrizioneQuote,
  idReportPrimoLivelloQuote: (state) => state.idReportPrimoLivelloQuote,
  idReportSecondoLivelloQuote: (state) => state.idReportSecondoLivelloQuote,
  causaleQuote: (state) => state.causaleQuote,
  nonAssociateQuote: (state) => state.nonAssociateQuote,

  rowsToSkipQuote: (state) => state.rowsToSkipQuote,
  fetchRowsQuote: (state) => state.fetchRowsQuote,
  currentPageQuote: (state) => state.currentPageQuote,
  sortColumnQuote: (state) => state.sortColumnQuote,
  sortOrderQuote: (state) => state.sortOrderQuote,
};

// const actions = {};
const mutations = {
  setIdFamigliaQuote: (state, value) => {
    state.idFamigliaQuote = value;
  },
  setIdSottofamigliaQuote: (state, value) => {
    state.idSottofamigliaQuote = value;
  },
  setTipoQuote: (state, value) => {
    state.tipoQuote = value;
  },
  setDescrizioneQuote: (state, value) => {
    state.descrizioneQuote = value;
  },
  setIdReportPrimoLivelloQuote: (state, value) => {
    state.idReportPrimoLivelloQuote = value;
  },
  setIdReportSecondoLivelloQuote: (state, value) => {
    state.idReportSecondoLivelloQuote = value;
  },
  setCausaleQuote: (state, value) => {
    state.causaleQuote = value;
  },
  setNonAssociateQuote: (state, value) => {
    state.nonAssociateQuote = value;
  },
  setFetchRowsQuote: (state, value) => {
    state.currentPageQuote = 1;
    state.rowsToSkipQuote = 0;
    state.fetchRowsQuote = value;
  },
  setCurrentPageQuote: (state, value) => {
    state.currentPageQuote = value;
    state.rowsToSkipQuote = parseInt(
      (state.currentPageQuote - 1) * state.fetchRowsQuote
    );
  },
  setSortColumnQuote: (state, value) => {
    state.sortColumnQuote = value;
  },
  setSortOrderQuote: (state, value) => {
    state.sortOrderQuote = value;
  },
  resetFiltersQuote: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipQuote: (state) => {
    state.currentPageQuote = 1;
    state.rowsToSkipQuote = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
