const getDefaultState = () => {
  return {
    idSocietaVivaio: null,
    idPersonaVivaio: null,
    societaDenominazioneVivaio: null,
    personaDenominazioneVivaio: null,
    rowsToSkipVivaio: 0,
    fetchRowsVivaio: 25,
    currentPageVivaio: 1,
    sortColumnVivaio: "nominativo_completo",
    sortOrderVivaio: "asc",
  };
};
const state = getDefaultState();

const getters = {
  idSocietaVivaio: (state) => state.idSocietaVivaio,
  idPersonaVivaio: (state) => state.idPersonaVivaio,
  societaDenominazioneVivaio: (state) => state.societaDenominazioneVivaio,
  personaDenominazioneVivaio: (state) => state.personaDenominazioneVivaio,
  rowsToSkipVivaio: (state) => state.rowsToSkipVivaio,
  fetchRowsVivaio: (state) => state.fetchRowsVivaio,
  currentPageVivaio: (state) => state.currentPageVivaio,
  sortColumnVivaio: (state) => state.sortColumnVivaio,
  sortOrderVivaio: (state) => state.sortOrderVivaio,
};

// const actions = {};
const mutations = {
  setIdSocietaVivaio: (state, value) => {
    state.idSocietaVivaio = value;
  },
  setIdPersonaVivaio: (state, value) => {
    state.idPersonaVivaio = value;
  },
  setSocietaDenominazioneVivaio: (state, value) => {
    state.societaDenominazioneVivaio = value;
  },
  setPersonaDenominazioneVivaio: (state, value) => {
    state.personaDenominazioneVivaio = value;
  },
  setFetchRowsVivaio: (state, value) => {
    state.currentPageVivaio = 1;
    state.rowsToSkipVivaio = 0;
    state.fetchRowsVivaio = value;
  },
  setCurrentPageVivaio: (state, value) => {
    state.currentPageVivaio = value;
    state.rowsToSkipVivaio = parseInt(
      (state.currentPageVivaio - 1) * state.fetchRowsVivaio
    );
  },
  setSortColumnVivaio: (state, value) => {
    state.sortColumnVivaio = value;
  },
  setSortOrderVivaio: (state, value) => {
    state.sortOrderVivaio = value;
  },
  resetFiltersVivaio: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipVivaio: (state) => {
    state.currentPageVivaio = 1;
    state.rowsToSkipVivaio = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
