const getDefaultState = () => {
  return {
    stagioneEconomatoSaldo: null,
    idComitatoRegionaleSaldo: null,
    dataApprovazioneDaSaldo: null,
    dataApprovazioneASaldo: null,
    idSocietaSaldo: null,
    nomeSocietaSaldo: "",
    rowsToSkipSaldo: 0,
    fetchRowsSaldo: 25,
    currentPageSaldo: 1,
    sortColumnSaldo: "cod_affiliazione",
    sortOrderSaldo: "desc",
  };
};
const state = getDefaultState();

const getters = {
  stagioneEconomatoSaldo: (state) => state.stagioneEconomatoSaldo,
  idComitatoRegionaleSaldo: (state) => state.idComitatoRegionaleSaldo,
  dataApprovazioneDaSaldo: (state) => state.dataApprovazioneDaSaldo,
  dataApprovazioneASaldo: (state) => state.dataApprovazioneASaldo,
  idSocietaSaldo: (state) => state.idSocietaSaldo,
  nomeSocietaSaldo: (state) => state.nomeSocietaSaldo,
  rowsToSkipSaldo: (state) => state.rowsToSkipSaldo,
  fetchRowsSaldo: (state) => state.fetchRowsSaldo,
  currentPageSaldo: (state) => state.currentPageSaldo,
  sortColumnSaldo: (state) => state.sortColumnSaldo,
  sortOrderSaldo: (state) => state.sortOrderSaldo,
};

// const actions = {};
const mutations = {
  setNomeSocietaSaldo: (state, value) => {
    state.nomeSocietaSaldo = value ? value : null;
  },
  setIdComitatoRegionaleSaldo: (state, value) => {
    state.idComitatoRegionaleSaldo = value;
  },
  setDataApprovazioneDaSaldo: (state, value) => {
    state.dataApprovazioneDaSaldo = value;
  },
  setDataApprovazioneASaldo: (state, value) => {
    state.dataApprovazioneASaldo = value;
  },
  setSocietaSaldo: (state, value) => {
    state.idSocietaSaldo = value ? value.value : null;
    state.nomeSocietaSaldo = value ? value.name : null;
  },
  setFetchRowsSaldo: (state, value) => {
    state.currentPageSaldo = 1;
    state.rowsToSkipSaldo = 0;
    state.fetchRowsSaldo = value;
  },
  setCurrentPageSaldo: (state, value) => {
    state.currentPageSaldo = value;
    state.rowsToSkipSaldo = parseInt(
      (state.currentPageSaldo - 1) * state.fetchRowsSaldo
    );
  },
  setSortColumnSaldo: (state, value) => {
    state.sortColumnSaldo = value;
  },
  setSortOrderSaldo: (state, value) => {
    state.sortOrderSaldo = value;
  },
  setCategoriaTesseraSaldo: (state, value) => {
    state.id_categoriaSaldo = value;
  },
  resetFiltersSaldo: (state) => {
    Object.assign(state, getDefaultState());
  },
  resetRowsToSkipSaldo: (state) => {
    state.currentPageSaldo = 1;
    state.rowsToSkipSaldo = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
