const getDefaultState = () => {
  return {
    rowsToSkipPrivacyTesSoc: 0,
    fetchRowsPrivacyTesSoc: 25,
    currentPagePrivacyTesSoc: 1,
    sortColumnPrivacyTesSoc: "nominativo",
    sortOrderPrivacyTesSoc: "asc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipPrivacyTesSoc: (state) => state.rowsToSkipPrivacyTesSoc,
  fetchRowsPrivacyTesSoc: (state) => state.fetchRowsPrivacyTesSoc,
  currentPagePrivacyTesSoc: (state) => state.currentPagePrivacyTesSoc,
  sortColumnPrivacyTesSoc: (state) => state.sortColumnPrivacyTesSoc,
  sortOrderPrivacyTesSoc: (state) => state.sortOrderPrivacyTesSoc,
};

// const actions = {};
const mutations = {
  setFetchRowsPrivacyTesSoc: (state, value) => {
    state.currentPagePrivacyTesSoc = 1;
    state.rowsToSkipPrivacyTesSoc = 0;
    state.fetchRowsPrivacyTesSoc = value;
  },
  setCurrentPagePrivacyTesSoc: (state, value) => {
    state.currentPagePrivacyTesSoc = value;
    state.rowsToSkipPrivacyTesSoc = parseInt(
      (state.currentPagePrivacyTesSoc - 1) * state.fetchRowsPrivacyTesSoc
    );
  },
  setSortColumnPrivacyTesSoc: (state, value) => {
    state.sortColumnPrivacyTesSoc = value;
  },
  setSortOrderPrivacyTesSoc: (state, value) => {
    state.sortOrderPrivacyTesSoc = value;
  },
  resetFiltersPrivacyTesSoc: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
