const getDefaultState = () => {
  return {
    rowsToSkipHPRichieste: 0,
    fetchRowsHPRichieste: 25,
    currentPageHPRichieste: 1,
    sortColumnHPRichieste: "id",
    sortOrderHPRichieste: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipHPRichieste: (state) => state.rowsToSkipHPRichieste,
  fetchRowsHPRichieste: (state) => state.fetchRowsHPRichieste,
  currentPageHPRichieste: (state) => state.currentPageHPRichieste,
  sortColumnHPRichieste: (state) => state.sortColumnHPRichieste,
  sortOrderHPRichieste: (state) => state.sortOrderHPRichieste,
};

// const actions = {};
const mutations = {
  setFetchRowsHPRichieste: (state, value) => {
    state.currentPageHPRichieste = 1;
    state.rowsToSkipHPRichieste = 0;
    state.fetchRowsHPRichieste = value;
  },
  setCurrentPageHPRichieste: (state, value) => {
    state.currentPageHPRichieste = value;
    state.rowsToSkipHPRichieste = parseInt(
      (state.currentPageHPRichieste - 1) * state.fetchRowsHPRichieste
    );
  },
  setSortColumnHPRichieste: (state, value) => {
    state.sortColumnHPRichieste = value;
  },
  setSortOrderHPRichieste: (state, value) => {
    state.sortOrderHPRichieste = value;
  },
  resetFiltersHPRichieste: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipHPRichieste: (state) => {
    state.currentPageHPRichieste = 1;
    state.rowsToSkipHPRichieste = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
