import { RouteLocationNormalized, Router, RouterLink } from "vue-router";
import { msalInstance, loginRequest } from "../authConfig";
import {
  InteractionType,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import store from "@/store/index";
import { checkTokenFromFCH } from "@/requests/checkTokenFromFCH";

export function registerGuard(router: Router) {
  const accounts = msalInstance.getAllAccounts();

  router.beforeEach(
    async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      // if (localStorage.getItem("tokenAdb2c")) {
      //   await msalInstance.acquireTokenSilent(loginRequest).then((res) => {
      //     console.log(res);
      //   });
      // }
      if (to.fullPath === "/logout" || to.fullPath === "/logout-post")
        return true;
      if (to.query.FCHT) {
        const puoEntrareUtenteFCHStatus = await checkTokenFromFCH(
          to.query.FCHT
        );
        localStorage.setItem("utenteFCH", "1");
        localStorage.setItem("FCHT", to.query.FCHT as string);
        localStorage.setItem(
          "puoEntrareUtenteFCHStatus",
          puoEntrareUtenteFCHStatus.toString()
        );
      }
      if (to.hash.includes("AADB2C90118") || to.hash.includes("AADB2C90091")) {
        store.commit("setIsLoggingAdb2c");
      }
      //console.log(to, from);
      if (!accounts.length && to.meta.noAuth === true) {
        return true;
      } else {
        if (to.fullPath === "/sign-in") return "/";

        const request = {
          ...loginRequest,
          redirectStartPage: to.fullPath,
        };
        const shouldProceed = await isAuthenticated(
          msalInstance,
          InteractionType.Redirect,
          request
        );

        return shouldProceed;
      }
    }
  );
}

export async function isAuthenticated(
  instance: PublicClientApplication,
  interactionType: InteractionType,
  loginRequest: PopupRequest | RedirectRequest
): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not

  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        return true;
      }

      // User is not signed in and attempting to access protected route. Sign them in.
      if (interactionType === InteractionType.Popup) {
        return instance
          .loginPopup(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      } else if (interactionType === InteractionType.Redirect) {
        return instance
          .loginRedirect(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }

      return false;
    })
    .catch((e) => {
      console.log(e);
      // instance.logoutRedirect();
      return false;
    });
}
