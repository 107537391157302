const getDefaultState = () => {
  return {
    campiEnabled: [],
    arrayCampiLocked: [],
    limitationsSelectedRoute: {},
    readOnlySelectedRoute: false,
  };
};
const state = getDefaultState();
const getters = {
  campiEnabled: (state) => state.campiEnabled,
  arrayCampiLocked: (state) => state.arrayCampiLocked,
  campiLockedClient: (state) => state.campiLockedClient,
  limitationsSelectedRoute: (state) => state.limitationsSelectedRoute,
  readOnlySelectedRoute: (state) => state.readOnlySelectedRoute,
};
const mutations = {
  setFilteredLimitations: (state, actualRoute) => {
    // for (var member in state.limitationsSelectedRoute)
    //   delete state.limitationsSelectedRoute[member];
    state.readOnlySelectedRoute = false;
    state.arrayCampiLocked.forEach((element) => {
      if (element.route === actualRoute) {
        state.readOnlySelectedRoute = element.read_only;
        return;
      }
    });
  },
  resetStateCampiLocked: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
