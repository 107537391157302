const getDefaultState = () => {
  return {
    nomeHPAnagrafiche: "",
    cognomeHPAnagrafiche: "",
    codFiscHPAnagrafiche: null,
    emailHPAnagrafiche: null,
    rowsToSkipHPAnagrafiche: 0,
    fetchRowsHPAnagrafiche: 25,
    currentPageHPAnagrafiche: 1,
    sortColumnHPAnagrafiche: "soggetto",
    sortOrderHPAnagrafiche: "asc",
  };
};
const state = getDefaultState();

const getters = {
  nomeHPAnagrafiche: (state) => state.nomeHPAnagrafiche,
  cognomeHPAnagrafiche: (state) => state.cognomeHPAnagrafiche,
  codFiscHPAnagrafiche: (state) => state.codFiscHPAnagrafiche,
  emailHPAnagrafiche: (state) => state.emailHPAnagrafiche,
  rowsToSkipHPAnagrafiche: (state) => state.rowsToSkipHPAnagrafiche,
  fetchRowsHPAnagrafiche: (state) => state.fetchRowsHPAnagrafiche,
  currentPageHPAnagrafiche: (state) => state.currentPageHPAnagrafiche,
  sortColumnHPAnagrafiche: (state) => state.sortColumnHPAnagrafiche,
  sortOrderHPAnagrafiche: (state) => state.sortOrderHPAnagrafiche,
};

// const actions = {};
const mutations = {
  setNomeHPAnagrafiche: (state, value) => {
    state.nomeHPAnagrafiche = value;
  },

  setCognomeHPAnagrafiche: (state, value) => {
    state.cognomeHPAnagrafiche = value;
  },

  setCodFiscHPAnagrafiche: (state, value) => {
    state.codFiscHPAnagrafiche = value;
  },

  setEmailHPAnagrafiche: (state, value) => {
    state.emailHPAnagrafiche = value;
  },

  setFetchRowsHPAnagrafiche: (state, value) => {
    state.currentPageHPAnagrafiche = 1;
    state.rowsToSkipHPAnagrafiche = 0;
    state.fetchRowsHPAnagrafiche = value;
  },
  setCurrentPageHPAnagrafiche: (state, value) => {
    state.currentPageHPAnagrafiche = value;
    state.rowsToSkipHPAnagrafiche = parseInt(
      (state.currentPageHPAnagrafiche - 1) * state.fetchRowsHPAnagrafiche
    );
  },
  setSortColumnHPAnagrafiche: (state, value) => {
    state.sortColumnHPAnagrafiche = value;
  },
  setSortOrderHPAnagrafiche: (state, value) => {
    state.sortOrderHPAnagrafiche = value;
  },
  resetFiltersHPAnagrafiche: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipHPAnagrafiche: (state) => {
    state.currentPageHPAnagrafiche = 1;
    state.rowsToSkipHPAnagrafiche = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
