const getDefaultStateSanzioni = () => {
  return {
    stagioneEconomatoSanzioni: null,
    idComitatoRegionaleSanzioni: null,
    idOrganoSanzioniEconomato: null,
    dataEmissioneDaSanzioni: null,
    dataEmissioneASanzioni: null,
    dataApprovazioneDaSanzioni: null,
    dataApprovazioneASanzioni: null,
    idSocietaSanzioniEconomato: null,
    nomeSocietaSanzioniEconomato: "",
    rowsToSkipSanzioniEconomato: 0,
    fetchRowsSanzioniEconomato: 25,
    currentPageSanzioniEconomato: 1,
    sortColumnSanzioniEconomato: "numero",
    sortOrderSanzioniEconomato: "desc",
  };
};
const state = getDefaultStateSanzioni();

const getters = {
  stagioneEconomatoSanzioni: (state) => state.stagioneEconomatoSanzioni,
  idComitatoRegionaleSanzioni: (state) => state.idComitatoRegionaleSanzioni,
  idOrganoSanzioniEconomato: (state) => state.idOrganoSanzioniEconomato,
  dataEmissioneDaSanzioni: (state) => state.dataEmissioneDaSanzioni,
  dataEmissioneASanzioni: (state) => state.dataEmissioneASanzioni,
  dataApprovazioneDaSanzioni: (state) => state.dataApprovazioneDaSanzioni,
  dataApprovazioneASanzioni: (state) => state.dataApprovazioneASanzioni,
  idSocietaSanzioniEconomato: (state) => state.idSocietaSanzioniEconomato,
  nomeSocietaSanzioniEconomato: (state) => state.nomeSocietaSanzioniEconomato,
  rowsToSkipSanzioniEconomato: (state) => state.rowsToSkipSanzioniEconomato,
  fetchRowsSanzioniEconomato: (state) => state.fetchRowsSanzioniEconomato,
  currentPageSanzioniEconomato: (state) => state.currentPageSanzioniEconomato,
  sortColumnSanzioniEconomato: (state) => state.sortColumnSanzioniEconomato,
  sortOrderSanzioniEconomato: (state) => state.sortOrderSanzioniEconomato,
};

// const actions = {};
const mutations = {
  setNomeSocietaSanzioni: (state, value) => {
    state.nomeSocietaSanzioniEconomato = value ? value : null;
  },
  setIdComitatoRegionaleSanzioni: (state, value) => {
    state.idComitatoRegionaleSanzioni = value;
  },
  setidOrganoSanzioniEconomato: (state, value) => {
    state.idOrganoSanzioniEconomato = value;
  },
  setDataEmissioneDaSanzioni: (state, value) => {
    state.dataEmissioneDaSanzioni = value;
  },
  setDataEmissioneASanzioni: (state, value) => {
    state.dataEmissioneASanzioni = value;
  },
  setDataApprovazioneDaSanzioni: (state, value) => {
    state.dataApprovazioneDaSanzioni = value;
  },
  setDataApprovazioneASanzioni: (state, value) => {
    state.dataApprovazioneASanzioni = value;
  },
  setSocietaSanzioniEconomato: (state, value) => {
    state.idSocietaSanzioniEconomato = value ? value.value : null;
    state.nomeSocietaSanzioniEconomato = value ? value.name : null;
  },
  setFetchRowsSanzioniEconomato: (state, value) => {
    state.currentPageSanzioniEconomato = 1;
    state.rowsToSkipSanzioniEconomato = 0;
    state.fetchRowsSanzioniEconomato = value;
  },
  setCurrentPageSanzioniEconomato: (state, value) => {
    state.currentPageSanzioniEconomato = value;
    state.rowsToSkipSanzioniEconomato = parseInt(
      (state.currentPageSanzioniEconomato - 1) *
        state.fetchRowsSanzioniEconomato
    );
  },
  setSortColumnSanzioniEconomato: (state, value) => {
    state.sortColumnSanzioniEconomato = value;
  },
  setSortOrderSanzioniEconomato: (state, value) => {
    state.sortOrderSanzioniEconomato = value;
  },
  setCategoriaTesseraSanzioniEconomato: (state, value) => {
    state.id_categoriaSanzioniEconomato = value;
  },
  resetFiltersSanzioni: (state) => {
    Object.assign(state, getDefaultStateSanzioni());
  },
  resetRowsToSkipSanzioniEconomato: (state) => {
    state.currentPageSanzioniEconomato = 1;
    state.rowsToSkipSanzioniEconomato = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
