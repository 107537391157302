const getDefaultState = () => {
  return {
    rowsToSkipOmologList: 0,
    fetchRowsOmologList: 25,
    currentPageOmologList: 1,
    sortColumnOmologList: "id",
    sortOrderOmologList: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipOmologList: (state) => state.rowsToSkipOmologList,
  fetchRowsOmologList: (state) => state.fetchRowsOmologList,
  currentPageOmologList: (state) => state.currentPageOmologList,
  sortColumnOmologList: (state) => state.sortColumnOmologList,
  sortOrderOmologList: (state) => state.sortOrderOmologList,
};

// const actions = {};
const mutations = {
  setFetchRowsOmologList: (state, value) => {
    state.currentPageOmologList = 1;
    state.rowsToSkipOmologList = 0;
    state.fetchRowsOmologList = value;
  },
  setCurrentPageOmologList: (state, value) => {
    state.currentPageOmologList = value;
    state.rowsToSkipOmologList = parseInt(
      (state.currentPageOmologList - 1) * state.fetchRowsOmologList
    );
  },
  setSortColumnOmologList: (state, value) => {
    state.sortColumnOmologList = value;
  },
  setSortOrderOmologList: (state, value) => {
    state.sortOrderOmologList = value;
  },
  resetFiltersOmologList: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
