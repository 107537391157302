const getDefaultState = () => {
  return {
    idTipologiaEsportazioni: null,
    dataEsportazioneDaSelected: null,
    dataEsportazioneASelected: null,
    rowsToSkipEsportazioni: 0,
    fetchRowsEsportazioni: 25,
    currentPageEsportazioni: 1,
    sortColumnEsportazioni: "data_richiesta",
    sortOrderEsportazioni: "desc",
  };
};
const state = getDefaultState();

const getters = {
  idTipologiaEsportazioni: (state) => state.idTipologiaEsportazioni,
  dataEsportazioneDaSelected: (state) => state.dataEsportazioneDaSelected,
  dataEsportazioneASelected: (state) => state.dataEsportazioneASelected,
  rowsToSkipEsportazioni: (state) => state.rowsToSkipEsportazioni,
  fetchRowsEsportazioni: (state) => state.fetchRowsEsportazioni,
  currentPageEsportazioni: (state) => state.currentPageEsportazioni,
  sortColumnEsportazioni: (state) => state.sortColumnEsportazioni,
  sortOrderEsportazioni: (state) => state.sortOrderEsportazioni,
};

// const actions = {};
const mutations = {
  setTipologiaEsportazioni: (state, value) => {
    state.idTipologiaEsportazioni = value;
  },
  setDataEsportazioneDaSelected: (state, value) => {
    state.dataEsportazioneDaSelected = value;
  },
  setDataEsportazioneASelected: (state, value) => {
    state.dataEsportazioneASelected = value;
  },
  setFetchRowsEsportazioni: (state, value) => {
    state.currentPageEsportazioni = 1;
    state.rowsToSkipEsportazioni = 0;
    state.fetchRowsEsportazioni = value;
  },
  setCurrentPageEsportazioni: (state, value) => {
    state.currentPageEsportazioni = value;
    state.rowsToSkipEsportazioni = parseInt(
      (state.currentPageEsportazioni - 1) * state.fetchRowsEsportazioni
    );
  },
  setSortColumnEsportazioni: (state, value) => {
    state.sortColumnEsportazioni = value;
  },
  setSortOrderEsportazioni: (state, value) => {
    state.sortOrderEsportazioni = value;
  },
  resetFiltersEsportazioni: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipEsportazioni: (state) => {
    state.currentPageEsportazioni = 1;
    state.rowsToSkipEsportazioni = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
