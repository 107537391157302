const state = {
  tokenAdb2c: "",
  user: {},
  isLoggingAdb2c: false,
};

const getters = {
  tokenAdb2c: (state) => state.tokenAdb2c,
  user: (state) => state.user,
  userName: (state) => state.user.name,
  isLoggingAdb2c: (state) => state.isLoggingAdb2c,
};
const actions = {
  cleanLocalStorage: (stateObj) => {
    localStorage.clear();
    localStorage.removeItem("tokenAdb2c");
    sessionStorage.clear();
    stateObj.commit("setToken", null);
  },
};
const mutations = {
  setToken: (state, value) => {
    state.tokenAdb2c = value;
  },
  setUser: (state, value) => {
    state.user = { ...value };
  },
  setIsLoggingAdb2c: (state) => {
    state.isLoggingAdb2c = true;
    // setTimeout(() => {
    //   state.isLoggingAdb2c = false;
    // }, 30000);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
