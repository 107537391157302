const getDefaultStateUtentiComitati = () => {
  return {
    nomeUtenteUtentiComitati: null,
    denominazioneUtentiComitati: null,
    rowsToSkipUtentiComitati: 0,
    fetchRowsUtentiComitati: 25,
    currentPageUtentiComitati: 1,
    sortColumnUtentiComitati: "comitato",
    sortOrderUtentiComitati: "asc",
  };
};
const state = getDefaultStateUtentiComitati();

const getters = {
  nomeUtenteUtentiComitati: (state) => state.nomeUtenteUtentiComitati,
  denominazioneUtentiComitati: (state) => state.denominazioneUtentiComitati,
  rowsToSkipUtentiComitati: (state) => state.rowsToSkipUtentiComitati,
  fetchRowsUtentiComitati: (state) => state.fetchRowsUtentiComitati,
  currentPageUtentiComitati: (state) => state.currentPageUtentiComitati,
  sortColumnUtentiComitati: (state) => state.sortColumnUtentiComitati,
  sortOrderUtentiComitati: (state) => state.sortOrderUtentiComitati,
};

// const actions = {};
const mutations = {
  setNomeUtenteUtentiComitati: (state, value) => {
    state.nomeUtenteUtentiComitati = value;
  },
  setDenominazioneUtentiComitati: (state, value) => {
    state.denominazioneUtentiComitati = value;
  },
  setFetchRowsUtentiComitati: (state, value) => {
    state.currentPageUtentiComitati = 1;
    state.rowsToSkipUtentiComitati = 0;
    state.fetchRowsUtentiComitati = value;
  },
  setCurrentPageUtentiComitati: (state, value) => {
    state.currentPageUtentiComitati = value;
    state.rowsToSkipUtentiComitati = parseInt(
      (state.currentPageUtentiComitati - 1) * state.fetchRowsUtentiComitati
    );
  },
  setSortColumnUtentiComitati: (state, value) => {
    state.sortColumnUtentiComitati = value;
  },
  setSortOrderUtentiComitati: (state, value) => {
    state.sortOrderUtentiComitati = value;
  },
  setCategoriaUtentiComitati: (state, value) => {
    state.id_categoriaUtentiComitati = value;
  },
  resetFiltersUtentiComitati: (state) => {
    Object.assign(state, getDefaultStateUtentiComitati());
  },
  resetRowsToSkipUtentiComitati: (state) => {
    state.currentPageUtentiComitati = 1;
    state.rowsToSkipUtentiComitati = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
