const getDefaultState = () => {
  return {
    rowsToSkipSanzioniUnoEnte: 0,
    fetchRowsSanzioniUnoEnte: 25,
    currentPageSanzioniUnoEnte: 1,
    sortColumnSanzioniUnoEnte: "data_reg",
    sortOrderSanzioniUnoEnte: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipSanzioniUnoEnte: (state) => state.rowsToSkipSanzioniUnoEnte,
  fetchRowsSanzioniUnoEnte: (state) => state.fetchRowsSanzioniUnoEnte,
  currentPageSanzioniUnoEnte: (state) => state.currentPageSanzioniUnoEnte,
  sortColumnSanzioniUnoEnte: (state) => state.sortColumnSanzioniUnoEnte,
  sortOrderSanzioniUnoEnte: (state) => state.sortOrderSanzioniUnoEnte,
};

// const actions = {};
const mutations = {
  setFetchRowsSanzioniUnoEnte: (state, value) => {
    state.currentPageSanzioniUnoEnte = 1;
    state.rowsToSkipSanzioniUnoEnte = 0;
    state.fetchRowsSanzioniUnoEnte = value;
  },
  setCurrentPageSanzioniUnoEnte: (state, value) => {
    state.currentPageSanzioniUnoEnte = value;
    state.rowsToSkipSanzioniUnoEnte = parseInt(
      (state.currentPageSanzioniUnoEnte - 1) * state.fetchRowsSanzioniUnoEnte
    );
  },
  setSortColumnSanzioniUnoEnte: (state, value) => {
    state.sortColumnSanzioniUnoEnte = value;
  },
  setSortOrderSanzioniUnoEnte: (state, value) => {
    state.sortOrderSanzioniUnoEnte = value;
  },
  resetFiltersSanzioniUnoEnte: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
