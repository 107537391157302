const getDefaultState = () => {
  return {
    causaleEconomatoSoc: null,
    tipoEconomatoSoc: null,
    descrizioneEconomatoSoc: null,
    statoApprovazioneEconomatoSoc: null,
    dataRegistDaEconomatoSoc: null,
    dataRegistAEconomatoSoc: null,
    dataApprovDaEconomatoSoc: null,
    dataApprovAEconomatoSoc: null,
    rowsToSkipEconomatoSoc: 0,
    fetchRowsEconomatoSoc: 25,
    currentPageEconomatoSoc: 1,
    sortColumnEconomatoSoc: "numero",
    sortOrderEconomatoSoc: "desc",
  };
};
const state = getDefaultState();

const getters = {
  causaleEconomatoSoc: (state) => state.causaleEconomatoSoc,
  tipoEconomatoSoc: (state) => state.tipoEconomatoSoc,
  descrizioneEconomatoSoc: (state) => state.descrizioneEconomatoSoc,
  statoApprovazioneEconomatoSoc: (state) => state.statoApprovazioneEconomatoSoc,
  dataRegistDaEconomatoSoc: (state) => state.dataRegistDaEconomatoSoc,
  dataRegistAEconomatoSoc: (state) => state.dataRegistAEconomatoSoc,
  dataApprovDaEconomatoSoc: (state) => state.dataApprovDaEconomatoSoc,
  dataApprovAEconomatoSoc: (state) => state.dataApprovAEconomatoSoc,
  rowsToSkipEconomatoSoc: (state) => state.rowsToSkipEconomatoSoc,
  fetchRowsEconomatoSoc: (state) => state.fetchRowsEconomatoSoc,
  currentPageEconomatoSoc: (state) => state.currentPageEconomatoSoc,
  sortColumnEconomatoSoc: (state) => state.sortColumnEconomatoSoc,
  sortOrderEconomatoSoc: (state) => state.sortOrderEconomatoSoc,
};

// const actions = {};
const mutations = {
  setCausaleEconomatoSoc: (state, value) => {
    state.causaleEconomatoSoc = value;
  },
  setTipoEconomatoSoc: (state, value) => {
    state.tipoEconomatoSoc = value;
  },
  setDescrizioneEconomatoSoc: (state, value) => {
    state.descrizioneEconomatoSoc = value;
  },
  setStatoApprovazioneEconomatoSoc: (state, value) => {
    state.statoApprovazioneEconomatoSoc = value;
  },
  setDataRegistDaEconomatoSoc: (state, value) => {
    state.dataRegistDaEconomatoSoc = value;
  },
  setDataRegistAEconomatoSoc: (state, value) => {
    state.dataRegistAEconomatoSoc = value;
  },
  setDataApprovDaEconomatoSoc: (state, value) => {
    state.dataApprovDaEconomatoSoc = value;
  },
  setDataApprovAEconomatoSoc: (state, value) => {
    state.dataApprovAEconomatoSoc = value;
  },
  setFetchRowsEconomatoSoc: (state, value) => {
    state.currentPageEconomatoSoc = 1;
    state.rowsToSkipEconomatoSoc = 0;
    state.fetchRowsEconomatoSoc = value;
  },
  setCurrentPageEconomatoSoc: (state, value) => {
    state.currentPageEconomatoSoc = value;
    state.rowsToSkipEconomatoSoc = parseInt(
      (state.currentPageEconomatoSoc - 1) * state.fetchRowsEconomatoSoc
    );
  },
  setSortColumnEconomatoSoc: (state, value) => {
    state.sortColumnEconomatoSoc = value;
  },
  setSortOrderEconomatoSoc: (state, value) => {
    state.sortOrderEconomatoSoc = value;
  },
  resetFiltersEconomatoSoc: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipEconomatoSoc: (state) => {
    state.currentPageEconomatoSoc = 1;
    state.rowsToSkipEconomatoSoc = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
