// by convention, composable function names start with "use"
export function useFormatDate(date) {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);
  // expose managed state as return value
  return `${day}/${month}/${year}`;
}
export function useFormatDateDMY(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  // expose managed state as return value
  return `${day}-${month}-${year}`;
}
export function useFormatDateYMD(dateString) {
  const day = dateString.substring(0, 2);
  const month = dateString.substring(3, 5);
  const year = dateString.substring(6, dateString.length);
  // expose managed state as return value
  return `${year}/${month}/${day}`;
}

export function useFormatDateYMDPersoneList(dateString) {
  const day = dateString.substring(0, 2);
  const month = dateString.substring(3, 5);
  const year = dateString.substring(6, dateString.length);
  // expose managed state as return value
  return `${year}-${month}-${day}`;
}

export function format(date) {
  const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
  const month =
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
export function fromUTCtoISODate(date) {
  const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
  const month =
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function fromUTCtoISODateDMY(date) {
  const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
  const month =
    date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
