const getDefaultStateTesseramenti = () => {
  return {
    stagioneEconomatoTesseramenti: null,
    idComitatoRegionaleTesseramenti: null,
    idSocietaTesseramenti: null,
    nomeSocietaTesseramenti: "",
    dataEmissioneDaTesseramenti: null,
    dataEmissioneATesseramenti: null,
    dataApprovazioneDaTesseramenti: null,
    dataApprovazioneATesseramenti: null,
    approvatoTesseramenti: null,
    meseApprovazioneTesseramenti: null,
    numeroTesseramenti: null,
    causaleTesseramenti: null,
    rowsToSkipTesseramenti: 0,
    fetchRowsTesseramenti: 25,
    currentPageTesseramenti: 1,
    sortColumnTesseramenti: "numero",
    sortOrderTesseramenti: "desc",
  };
};
const state = getDefaultStateTesseramenti();

const getters = {
  stagioneEconomatoTesseramenti: (state) => state.stagioneEconomatoTesseramenti,
  idComitatoRegionaleTesseramenti: (state) =>
    state.idComitatoRegionaleTesseramenti,
  idSocietaTesseramenti: (state) => state.idSocietaTesseramenti,
  nomeSocietaTesseramenti: (state) => state.nomeSocietaTesseramenti,
  dataEmissioneDaTesseramenti: (state) => state.dataEmissioneDaTesseramenti,
  dataEmissioneATesseramenti: (state) => state.dataEmissioneATesseramenti,
  dataApprovazioneDaTesseramenti: (state) =>
    state.dataApprovazioneDaTesseramenti,
  dataApprovazioneATesseramenti: (state) => state.dataApprovazioneATesseramenti,
  approvatoTesseramenti: (state) => state.approvatoTesseramenti,
  meseApprovazioneTesseramenti: (state) => state.meseApprovazioneTesseramenti,
  numeroTesseramenti: (state) => state.numeroTesseramenti,
  causaleTesseramenti: (state) => state.causaleTesseramenti,
  rowsToSkipTesseramenti: (state) => state.rowsToSkipTesseramenti,
  fetchRowsTesseramenti: (state) => state.fetchRowsTesseramenti,
  currentPageTesseramenti: (state) => state.currentPageTesseramenti,
  sortColumnTesseramenti: (state) => state.sortColumnTesseramenti,
  sortOrderTesseramenti: (state) => state.sortOrderTesseramenti,
};

// const actions = {};
const mutations = {
  setNomeSocietaTesseramenti: (state, value) => {
    state.nomeSocietaTesseramenti = value ? value : null;
  },
  setSocietaTesseramenti: (state, value) => {
    state.idSocietaTesseramenti = value ? value.value : null;
    state.nomeSocietaTesseramenti = value ? value.name : null;
  },
  setIdComitatoRegionaleTesseramenti: (state, value) => {
    state.idComitatoRegionaleTesseramenti = value;
  },
  setDataEmissioneDaTesseramenti: (state, value) => {
    state.dataEmissioneDaTesseramenti = value;
  },
  setDataEmissioneATesseramenti: (state, value) => {
    state.dataEmissioneATesseramenti = value;
  },
  setDataApprovazioneDaTesseramenti: (state, value) => {
    state.dataApprovazioneDaTesseramenti = value;
  },
  setDataApprovazioneATesseramenti: (state, value) => {
    state.dataApprovazioneATesseramenti = value;
  },
  setApprovatoTesseramenti: (state, value) => {
    state.approvatoTesseramenti = value;
  },
  setMeseApprovazioneTesseramenti: (state, value) => {
    state.meseApprovazioneTesseramenti = value;
  },
  setNumeroTesseramenti: (state, value) => {
    state.numeroTesseramenti = value;
  },
  setCausaleTesseramenti: (state, value) => {
    state.causaleTesseramenti = value;
  },
  setFetchRowsTesseramenti: (state, value) => {
    state.currentPageTesseramenti = 1;
    state.rowsToSkipTesseramenti = 0;
    state.fetchRowsTesseramenti = value;
  },
  setCurrentPageTesseramenti: (state, value) => {
    state.currentPageTesseramenti = value;
    state.rowsToSkipTesseramenti = parseInt(
      (state.currentPageTesseramenti - 1) * state.fetchRowsTesseramenti
    );
  },
  setSortColumnTesseramenti: (state, value) => {
    state.sortColumnTesseramenti = value;
  },
  setSortOrderTesseramenti: (state, value) => {
    state.sortOrderTesseramenti = value;
  },
  setCategoriaTesseramenti: (state, value) => {
    state.id_categoriaTesseramenti = value;
  },
  resetFiltersTesseramenti: (state) => {
    Object.assign(state, getDefaultStateTesseramenti());
  },
  resetRowsToSkipTesseramenti: (state) => {
    state.currentPageTesseramenti = 1;
    state.rowsToSkipTesseramenti = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
