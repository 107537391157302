const getDefaultState = () => {
  return {
    rowsToSkipBacheca: 0,
    fetchRowsBacheca: 25,
    currentPageBacheca: 1,
    sortColumnBacheca: "data",
    sortOrderBacheca: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipBacheca: (state) => state.rowsToSkipBacheca,
  fetchRowsBacheca: (state) => state.fetchRowsBacheca,
  currentPageBacheca: (state) => state.currentPageBacheca,
  sortColumnBacheca: (state) => state.sortColumnBacheca,
  sortOrderBacheca: (state) => state.sortOrderBacheca,
};

// const actions = {};
const mutations = {
  setFetchRowsBacheca: (state, value) => {
    state.currentPageBacheca = 1;
    state.rowsToSkipBacheca = 0;
    state.fetchRowsBacheca = value;
  },
  setCurrentPageBacheca: (state, value) => {
    state.currentPageBacheca = value;
    state.rowsToSkipBacheca = parseInt(
      (state.currentPageBacheca - 1) * state.fetchRowsBacheca
    );
  },
  setSortColumnBacheca: (state, value) => {
    state.sortColumnBacheca = value;
  },
  setSortOrderBacheca: (state, value) => {
    state.sortOrderBacheca = value;
  },
  resetFiltersBacheca: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
