const getDefaultState = () => {
  return {
    rowsToSkipTrasferimentiUnaSoc: 0,
    fetchRowsTrasferimentiUnaSoc: 25,
    currentPageTrasferimentiUnaSoc: 1,
    sortColumnTrasferimentiUnaSoc: "id",
    sortOrderTrasferimentiUnaSoc: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipTrasferimentiUnaSoc: (state) => state.rowsToSkipTrasferimentiUnaSoc,
  fetchRowsTrasferimentiUnaSoc: (state) => state.fetchRowsTrasferimentiUnaSoc,
  currentPageTrasferimentiUnaSoc: (state) =>
    state.currentPageTrasferimentiUnaSoc,
  sortColumnTrasferimentiUnaSoc: (state) => state.sortColumnTrasferimentiUnaSoc,
  sortOrderTrasferimentiUnaSoc: (state) => state.sortOrderTrasferimentiUnaSoc,
};

// const actions = {};
const mutations = {
  setFetchRowsTrasferimentiUnaSoc: (state, value) => {
    state.currentPageTrasferimentiUnaSoc = 1;
    state.rowsToSkipTrasferimentiUnaSoc = 0;
    state.fetchRowsTrasferimentiUnaSoc = value;
  },
  setCurrentPageTrasferimentiUnaSoc: (state, value) => {
    state.currentPageTrasferimentiUnaSoc = value;
    state.rowsToSkipTrasferimentiUnaSoc = parseInt(
      (state.currentPageTrasferimentiUnaSoc - 1) *
        state.fetchRowsTrasferimentiUnaSoc
    );
  },
  setSortColumnTrasferimentiUnaSoc: (state, value) => {
    state.sortColumnTrasferimentiUnaSoc = value;
  },
  setSortOrderTrasferimentiUnaSoc: (state, value) => {
    state.sortOrderTrasferimentiUnaSoc = value;
  },
  resetFiltersTrasferimentiUnaSoc: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
