const getDefaultState = () => {
  return {
    dataDaDocEnte: null,
    dataADocEnte: null,
    idTipoDocEnte: null,
    permessiDocEnte: null,
    rowsToSkipDocEnte: 0,
    fetchRowsDocEnte: 25,
    currentPageDocEnte: 1,
    sortColumnDocEnte: "data",
    sortOrderDocEnte: "desc",
  };
};
const state = getDefaultState();

const getters = {
  dataDaDocEnte: (state) => state.dataDaDocEnte,
  dataADocEnte: (state) => state.dataADocEnte,
  idTipoDocEnte: (state) => state.idTipoDocEnte,
  permessiDocEnte: (state) => state.permessiDocEnte,
  rowsToSkipDocEnte: (state) => state.rowsToSkipDocEnte,
  fetchRowsDocEnte: (state) => state.fetchRowsDocEnte,
  currentPageDocEnte: (state) => state.currentPageDocEnte,
  sortColumnDocEnte: (state) => state.sortColumnDocEnte,
  sortOrderDocEnte: (state) => state.sortOrderDocEnte,
};

// const actions = {};
const mutations = {
  setDataDaDocEnte: (state, value) => {
    state.dataDaDocEnte = value;
  },
  setDataADocEnte: (state, value) => {
    state.dataADocEnte = value;
  },
  setIdTipoDocEnte: (state, value) => {
    state.idTipoDocEnte = value;
  },
  setPermessiDocEnte: (state, value) => {
    state.permessiDocEnte = value;
  },
  setFetchRowsDocEnte: (state, value) => {
    state.currentPageDocEnte = 1;
    state.rowsToSkipDocEnte = 0;
    state.fetchRowsDocEnte = value;
  },
  setCurrentPageDocEnte: (state, value) => {
    state.currentPageDocEnte = value;
    state.rowsToSkipDocEnte = parseInt(
      (state.currentPageDocEnte - 1) * state.fetchRowsDocEnte
    );
  },
  setSortColumnDocEnte: (state, value) => {
    state.sortColumnDocEnte = value;
  },
  setSortOrderDocEnte: (state, value) => {
    state.sortOrderDocEnte = value;
  },
  resetFiltersDocEnte: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipDocEnte: (state) => {
    state.currentPageDocEnte = 1;
    state.rowsToSkipDocEnte = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
