const getDefaultState = () => {
  return {
    idPersonaCorsiAreaInsegnante: null,
    tipologiaCorsiAreaInsegnante: null,
    specializzCorsiAreaInsegnante: null,
    denominazioneCorsiAreaInsegnante: null,
    rowsToSkipCorsiAreaInsegnante: 0,
    fetchRowsCorsiAreaInsegnante: 25,
    currentPageCorsiAreaInsegnante: 1,
    sortColumnCorsiAreaInsegnante: "id",
    sortOrderCorsiAreaInsegnante: "asc",
    tutteStagioniCorsiAreaInsegnante: false,
  };
};
const state = getDefaultState();

const getters = {
  idPersonaCorsiAreaInsegnante: (state) => state.idPersonaCorsiAreaInsegnante,
  tipologiaCorsiAreaInsegnante: (state) => state.tipologiaCorsiAreaInsegnante,
  specializzCorsiAreaInsegnante: (state) => state.specializzCorsiAreaInsegnante,
  denominazioneCorsiAreaInsegnante: (state) =>
    state.denominazioneCorsiAreaInsegnante,
  rowsToSkipCorsiAreaInsegnante: (state) => state.rowsToSkipCorsiAreaInsegnante,
  fetchRowsCorsiAreaInsegnante: (state) => state.fetchRowsCorsiAreaInsegnante,
  currentPageCorsiAreaInsegnante: (state) =>
    state.currentPageCorsiAreaInsegnante,
  sortColumnCorsiAreaInsegnante: (state) => state.sortColumnCorsiAreaInsegnante,
  sortOrderCorsiAreaInsegnante: (state) => state.sortOrderCorsiAreaInsegnante,
  tutteStagioniCorsiAreaInsegnante: (state) =>
    state.tutteStagioniCorsiAreaInsegnante,
};

// const actions = {};
const mutations = {
  setIdPersonaCorsiAreaInsegnante: (state, value) => {
    state.idPersonaCorsiAreaInsegnante = value;
  },
  setTipologiaCorsiAreaInsegnante: (state, value) => {
    state.tipologiaCorsiAreaInsegnante = value;
  },
  setSpecializzCorsiAreaInsegnante: (state, value) => {
    state.specializzCorsiAreaInsegnante = value;
  },
  setDenominazioneCorsiAreaInsegnante: (state, value) => {
    state.denominazioneCorsiAreaInsegnante = value;
  },
  setFetchRowsCorsiAreaInsegnante: (state, value) => {
    state.currentPageCorsiAreaInsegnante = 1;
    state.rowsToSkipCorsiAreaInsegnante = 0;
    state.fetchRowsCorsiAreaInsegnante = value;
  },
  setCurrentPageCorsiAreaInsegnante: (state, value) => {
    state.currentPageCorsiAreaInsegnante = value;
    state.rowsToSkipCorsiAreaInsegnante = parseInt(
      (state.currentPageCorsiAreaInsegnante - 1) *
        state.fetchRowsCorsiAreaInsegnante
    );
  },
  setSortColumnCorsiAreaInsegnante: (state, value) => {
    state.sortColumnCorsiAreaInsegnante = value;
  },
  setSortOrderCorsiAreaInsegnante: (state, value) => {
    state.sortOrderCorsiAreaInsegnante = value;
  },
  resetFiltersCorsiAreaInsegnante: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipCorsiAreaInsegnante: (state) => {
    state.currentPageCorsiAreaInsegnante = 1;
    state.rowsToSkipCorsiAreaInsegnante = 0;
  },
  setTutteLeStagioniCorsiAreaInsegnante: (state) => {
    state.tutteStagioniCorsiAreaInsegnante =
      !state.tutteStagioniCorsiAreaInsegnante;
    state.currentPageCorsiAreaInsegnante = 1;
    state.rowsToSkipCorsiAreaInsegnante = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
