const getDefaultState = () => {
  return {
    annoProvTrasfRichieste: null,
    annoDestTrasfRichieste: null,
    idSocietaProvTrasfRichieste: null,
    nomeSocietaProvTrasfRichieste: null,
    idSocietaDestTrasfRichieste: null,
    nomeSocietaDestTrasfRichieste: null,
    cognomeTrasfRichieste: null,
    nomeTrasfRichieste: null,
    id_categoriaTrasfRichieste: null,
    id_disciplinaTrasfRichieste: null,
    codiceTesseraTrasfRichieste: null,
    rowsToSkipTrasfRichieste: 0,
    fetchRowsTrasfRichieste: 25,
    currentPageTrasfRichieste: 1,
    sortColumnTrasfRichieste: "tesserato",
    sortOrderTrasfRichieste: "asc",
  };
};
const state = getDefaultState();

const getters = {
  annoProvTrasfRichieste: (state) => state.annoProvTrasfRichieste,
  annoDestTrasfRichieste: (state) => state.annoDestTrasfRichieste,
  idSocietaProvTrasfRichieste: (state) => state.idSocietaProvTrasfRichieste,
  nomeSocietaProvTrasfRichieste: (state) => state.nomeSocietaProvTrasfRichieste,
  idSocietaDestTrasfRichieste: (state) => state.idSocietaDestTrasfRichieste,
  nomeSocietaDestTrasfRichieste: (state) => state.nomeSocietaDestTrasfRichieste,
  cognomeTrasfRichieste: (state) => state.cognomeTrasfRichieste,
  nomeTrasfRichieste: (state) => state.nomeTrasfRichieste,
  id_categoriaTrasfRichieste: (state) => state.id_categoriaTrasfRichieste,
  id_disciplinaTrasfRichieste: (state) => state.id_disciplinaTrasfRichieste,
  codiceTesseraTrasfRichieste: (state) => state.codiceTesseraTrasfRichieste,
  rowsToSkipTrasfRichieste: (state) => state.rowsToSkipTrasfRichieste,
  fetchRowsTrasfRichieste: (state) => state.fetchRowsTrasfRichieste,
  currentPageTrasfRichieste: (state) => state.currentPageTrasfRichieste,
  sortColumnTrasfRichieste: (state) => state.sortColumnTrasfRichieste,
  sortOrderTrasfRichieste: (state) => state.sortOrderTrasfRichieste,
};

// const actions = {};
const mutations = {
  setNomeSocietaProv: (state, value) => {
    state.nomeSocietaProvTrasfRichieste = value;
  },
  setNomeSocietaDest: (state, value) => {
    state.nomeSocietaDestTrasfRichieste = value;
  },
  setAnnoProvTrasfRichieste: (state, value) => {
    state.annoProvTrasfRichieste = value;
  },
  setAnnoDestTrasfRichieste: (state, value) => {
    state.annoDestTrasfRichieste = value;
  },
  setIdSocietaProvTrasfRichieste: (state, value) => {
    state.idSocietaProvTrasfRichieste = value;
  },
  setNomeSocietaProvTrasfRichieste: (state, value) => {
    state.nomeSocietaProvTrasfRichieste = value;
  },
  setIdSocietaDestTrasfRichieste: (state, value) => {
    state.idSocietaDestTrasfRichieste = value;
  },
  setNomeSocietaDestTrasfRichieste: (state, value) => {
    state.nomeSocietaDestTrasfRichieste = value;
  },
  setCognomeTrasfRichieste: (state, value) => {
    state.cognomeTrasfRichieste = value;
  },
  setNomeTrasfRichieste: (state, value) => {
    state.nomeTrasfRichieste = value;
  },
  setCategoriaTrasfRichieste: (state, value) => {
    state.id_categoriaTrasfRichieste = value;
  },
  setDisciplinaTrasfRichieste: (state, value) => {
    state.id_disciplinaTrasfRichieste = value;
  },
  setCodiceTesseraTrasfRichieste: (state, value) => {
    state.codiceTesseraTrasfRichieste = value;
  },
  setFetchRowsTrasfRichieste: (state, value) => {
    state.currentPageTrasfRichieste = 1;
    state.rowsToSkipTrasfRichieste = 0;
    state.fetchRowsTrasfRichieste = value;
  },
  setCurrentPageTrasfRichieste: (state, value) => {
    state.currentPageTrasfRichieste = value;
    state.rowsToSkipTrasfRichieste = parseInt(
      (state.currentPageTrasfRichieste - 1) * state.fetchRowsTrasfRichieste
    );
  },
  setSortColumnTrasfRichieste: (state, value) => {
    state.sortColumnTrasfRichieste = value;
  },
  setSortOrderTrasfRichieste: (state, value) => {
    state.sortOrderTrasfRichieste = value;
  },
  resetFiltersTrasfRichieste: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipTrasfRichieste: (state) => {
    state.currentPageTrasfRichieste = 1;
    state.rowsToSkipTrasfRichieste = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
