const getDefaultState = () => {
  return {
    stagioneTesseratoFederale: null,
    nomeTesseratoFederale: null,
    cognomeTesseratoFederale: null,
    numeroAlboTesseratoFederale: null,
    idQualificaTesseratoFederale: null,
    idComitatoRegionaleTesseratoFederale: null,
    dataEmissioneDaTesseratoFederale: null,
    dataEmissioneATesseratoFederale: null,
    rowsToSkipTesseratoFederale: 0,
    fetchRowsTesseratoFederale: 25,
    currentPageTesseratoFederale: 1,
    sortColumnTesseratoFederale: "tesserato",
    sortOrderTesseratoFederale: "asc",
  };
};
const state = getDefaultState();

const getters = {
  stagioneTesseratoFederale: (state) => state.stagioneTesseratoFederale,
  nomeTesseratoFederale: (state) => state.nomeTesseratoFederale,
  cognomeTesseratoFederale: (state) => state.cognomeTesseratoFederale,
  idPersonaTesseratoFederale: (state) => state.idPersonaTesseratoFederale,
  numeroAlboTesseratoFederale: (state) => state.numeroAlboTesseratoFederale,
  idQualificaTesseratoFederale: (state) => state.idQualificaTesseratoFederale,
  idComitatoRegionaleTesseratoFederale: (state) =>
    state.idComitatoRegionaleTesseratoFederale,
  dataEmissioneDaTesseratoFederale: (state) =>
    state.dataEmissioneDaTesseratoFederale,
  dataEmissioneATesseratoFederale: (state) =>
    state.dataEmissioneATesseratoFederale,
  rowsToSkipTesseratoFederale: (state) => state.rowsToSkipTesseratoFederale,
  fetchRowsTesseratoFederale: (state) => state.fetchRowsTesseratoFederale,
  currentPageTesseratoFederale: (state) => state.currentPageTesseratoFederale,
  sortColumnTesseratoFederale: (state) => state.sortColumnTesseratoFederale,
  sortOrderTesseratoFederale: (state) => state.sortOrderTesseratoFederale,
};

// const actions = {};
const mutations = {
  setNomeTesseratoFederale: (state, value) => {
    state.nomeTesseratoFederale = value;
  },
  setCognomeTesseratoFederale: (state, value) => {
    state.cognomeTesseratoFederale = value;
  },
  setIdPersonaTesseratoFederale: (state, value) => {
    state.idPersonaTesseratoFederale = value;
  },
  setNumeroAlboTesseratoFederale: (state, value) => {
    state.numeroAlboTesseratoFederale = value;
  },
  setFineTesseramento: (state, value) => {
    state.fineTesseramentoTesseratoFederale = value;
  },
  setIdQualificaTesseratoFederale: (state, value) => {
    state.idQualificaTesseratoFederale = value;
  },
  setIdComitatoRegionaleTesseratoFederale: (state, value) => {
    state.idComitatoRegionaleTesseratoFederale = value;
  },
  setDataEmissioneDaTesseratoFederale: (state, value) => {
    state.dataEmissioneDaTesseratoFederale = value;
  },
  setDataEmissioneATesseratoFederale: (state, value) => {
    state.dataEmissioneATesseratoFederale = value;
  },
  setFetchRowsTesseratoFederale: (state, value) => {
    state.currentPageTesseratoFederale = 1;
    state.rowsToSkipTesseratoFederale = 0;
    state.fetchRowsTesseratoFederale = value;
  },
  setCurrentPageTesseratoFederale: (state, value) => {
    state.currentPageTesseratoFederale = value;
    state.rowsToSkipTesseratoFederale = parseInt(
      (state.currentPageTesseratoFederale - 1) *
        state.fetchRowsTesseratoFederale
    );
  },
  setSortColumnTesseratoFederale: (state, value) => {
    state.sortColumnTesseratoFederale = value;
  },
  setSortOrderTesseratoFederale: (state, value) => {
    state.sortOrderTesseratoFederale = value;
  },
  setCategoriaTessera: (state, value) => {
    state.id_categoriaTesseratoFederale = value;
  },
  resetFiltersTesseratiFederali: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipTesseratoFederale: (state) => {
    state.currentPageTesseratoFederale = 1;
    state.rowsToSkipTesseratoFederale = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
