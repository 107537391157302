const getDefaultState = () => {
  return {
    // anno: 0,
    data_ultimo_invio_raas_aff: null, // "2023-01-02T13:35:10.715Z",
    denominazione_raas_aff: null, //"string",
    codice_fiscale_raas_aff: null, //"string",
    codice_affiliazione_raas_aff: null, //"string",
    id_comitato_raas_aff: null, //0,
    stati_invio_raas_aff: null, //"string",
    rowtoskip_raas_aff: 0, //0,
    fetchrows_raas_aff: 25, //0,
    sortcolumn_raas_aff: "ultimo_invio", //"string",
    sortorder_raas_aff: "desc", //"string",
    currentPage_raas_aff: 1,
  };
};
const state = getDefaultState();

const getters = {
  //   anno: (state) => state.disciplinaRanking,
  data_ultimo_invio_raas_aff: (state) => state.data_ultimo_invio_raas_aff,
  denominazione_raas_aff: (state) => state.denominazione_raas_aff,
  codice_fiscale_raas_aff: (state) => state.codice_fiscale_raas_aff,
  codice_affiliazione_raas_aff: (state) => state.codice_affiliazione_raas_aff,
  id_comitato_raas_aff: (state) => state.id_comitato_raas_aff,
  stati_invio_raas_aff: (state) => state.stati_invio_raas_aff,
  rowtoskip_raas_aff: (state) => state.rowtoskip_raas_aff,
  fetchrows_raas_aff: (state) => state.fetchrows_raas_aff,
  sortcolumn_raas_aff: (state) => state.sortcolumn_raas_aff,
  sortorder_raas_aff: (state) => state.sortorder_raas_aff,
  currentPage_raas_aff: (state) => state.currentPage_raas_aff,
};

const actions = {
  setInviateAffiliazioniRaas: (context) => {
    context.commit("setStati_invio_raas_aff", "2");
  },
  setDaInviareAffiliazioniRaas: (context) => {
    context.commit("setStati_invio_raas_aff", "1");
  },
  setErroriAffiliazioniRaas: (context) => {
    context.commit("setStati_invio_raas_aff", "3");
  },
  setDaReinviareAffiliazioniRaas: (context) => {
    context.commit("setStati_invio_raas_aff", "4");
  },
};
const mutations = {
  setData_ultimo_invio_raas_aff: (state, value) => {
    state.data_ultimo_invio_raas_aff = value;
  },
  setDenominazione_raas_aff: (state, value) => {
    state.denominazione_raas_aff = value;
  },
  setCodice_fiscale_raas_aff: (state, value) => {
    state.codice_fiscale_raas_aff = value;
  },
  setCodice_affiliazione_raas_aff: (state, value) => {
    state.codice_affiliazione_raas_aff = value;
  },
  setId_comitato_raas_aff: (state, value) => {
    state.id_comitato_raas_aff = value;
  },
  setStati_invio_raas_aff: (state, value) => {
    if (
      state.stati_invio_raas_aff &&
      state.stati_invio_raas_aff.includes(value)
    ) {
      if (state.stati_invio_raas_aff.includes(`,${value}`))
        state.stati_invio_raas_aff = state.stati_invio_raas_aff.replace(
          `,${value}`,
          ""
        );
      else if (state.stati_invio_raas_aff.includes(`${value},`))
        state.stati_invio_raas_aff = state.stati_invio_raas_aff.replace(
          `${value},`,
          ""
        );
      else
        state.stati_invio_raas_aff = state.stati_invio_raas_aff.replace(
          `${value}`,
          ""
        );
    } else {
      state.stati_invio_raas_aff = `${
        state.stati_invio_raas_aff ? state.stati_invio_raas_aff + "," : ""
      }${value}`;
    }
    if (state.stati_invio_raas_aff === "") state.stati_invio_raas_aff = null;
  },
  //   setInviateAffiliazioniRaas: (state, value) => {
  //     state.stati_invio_raas_aff =  `${state.stati_invio_raas_aff},${value}`;
  //   },

  setFetchRowsRaasAff: (state, value) => {
    state.currentPage_raas_aff = 1;
    state.rowtoskip_raas_aff = 0;
    state.fetchrows_raas_aff = value;
  },
  setCurrentPageRaasAff: (state, value) => {
    state.currentPage_raas_aff = value;
    state.rowtoskip_raas_aff = parseInt(
      (state.currentPage_raas_aff - 1) * state.fetchrows_raas_aff
    );
  },
  setSortColumnRaasAff: (state, value) => {
    state.sortcolumn_raas_aff = value;
  },
  setSortOrderRaasAff: (state, value) => {
    state.sortorder_raas_aff = value;
  },
  resetFiltersRaasAff: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipRaasAff: (state) => {
    state.currentPage_raas_aff = 1;
    state.rowtoskip_raas_aff = 0;
  },
  //   setTutteLeStagioniRanking: (state) => {
  //     state.tutteStagioniRanking = !state.tutteStagioniRanking;
  //     state.currentPageRanking = 1;
  //     state.rowsToSkipRanking = 0;
  //   },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
