const getDefaultState = () => {
  return {
    rowsToSkipComitatoUtenti: 0,
    fetchRowsComitatoUtenti: 25,
    currentPageComitatoUtenti: 1,
    sortColumnComitatoUtenti: "data_creazione",
    sortOrderComitatoUtenti: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipComitatoUtenti: (state) => state.rowsToSkipComitatoUtenti,
  fetchRowsComitatoUtenti: (state) => state.fetchRowsComitatoUtenti,
  currentPageComitatoUtenti: (state) => state.currentPageComitatoUtenti,
  sortColumnComitatoUtenti: (state) => state.sortColumnComitatoUtenti,
  sortOrderComitatoUtenti: (state) => state.sortOrderComitatoUtenti,
};

// const actions = {};
const mutations = {
  setFetchRowsComitatoUtenti: (state, value) => {
    state.currentPageComitatoUtenti = 1;
    state.rowsToSkipComitatoUtenti = 0;
    state.fetchRowsComitatoUtenti = value;
  },
  setCurrentPageComitatoUtenti: (state, value) => {
    state.currentPageComitatoUtenti = value;
    state.rowsToSkipComitatoUtenti = parseInt(
      (state.currentPageComitatoUtenti - 1) * state.fetchRowsComitatoUtenti
    );
  },
  setSortColumnComitatoUtenti: (state, value) => {
    state.sortColumnComitatoUtenti = value;
  },
  setSortOrderComitatoUtenti: (state, value) => {
    state.sortOrderComitatoUtenti = value;
  },
  resetFiltersComitatoUtenti: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
