const getDefaultStateAffiliazioni = () => {
  return {
    stagioneEconomatoAffiliazioni: null,
    idComitatoRegionaleAffiliazioni: null,
    idSocietaAffiliazioni: null,
    nomeSocietaAffiliazioni: "",
    dataEmissioneDaAffiliazioni: null,
    dataEmissioneAAffiliazioni: null,
    dataApprovazioneDaAffiliazioni: null,
    dataApprovazioneAAffiliazioni: null,
    approvatoAffiliazioni: null,
    meseApprovazioneAffiliazioni: null,
    rowsToSkipAffiliazioni: 0,
    fetchRowsAffiliazioni: 25,
    currentPageAffiliazioni: 1,
    sortColumnAffiliazioni: "numero",
    sortOrderAffiliazioni: "desc",
  };
};
const state = getDefaultStateAffiliazioni();

const getters = {
  stagioneEconomatoAffiliazioni: (state) => state.stagioneEconomatoAffiliazioni,
  idComitatoRegionaleAffiliazioni: (state) =>
    state.idComitatoRegionaleAffiliazioni,
  idSocietaAffiliazioni: (state) => state.idSocietaAffiliazioni,
  nomeSocietaAffiliazioni: (state) => state.nomeSocietaAffiliazioni,
  dataEmissioneDaAffiliazioni: (state) => state.dataEmissioneDaAffiliazioni,
  dataEmissioneAAffiliazioni: (state) => state.dataEmissioneAAffiliazioni,
  dataApprovazioneDaAffiliazioni: (state) =>
    state.dataApprovazioneDaAffiliazioni,
  dataApprovazioneAAffiliazioni: (state) => state.dataApprovazioneAAffiliazioni,
  approvatoAffiliazioni: (state) => state.approvatoAffiliazioni,
  meseApprovazioneAffiliazioni: (state) => state.meseApprovazioneAffiliazioni,
  rowsToSkipAffiliazioni: (state) => state.rowsToSkipAffiliazioni,
  fetchRowsAffiliazioni: (state) => state.fetchRowsAffiliazioni,
  currentPageAffiliazioni: (state) => state.currentPageAffiliazioni,
  sortColumnAffiliazioni: (state) => state.sortColumnAffiliazioni,
  sortOrderAffiliazioni: (state) => state.sortOrderAffiliazioni,
};

// const actions = {};
const mutations = {
  setNomeSocietaAffiliazioni: (state, value) => {
    state.nomeSocietaAffiliazioni = value ? value : null;
  },
  setSocietaAffiliazioni: (state, value) => {
    state.idSocietaAffiliazioni = value ? value.value : null;
    state.nomeSocietaAffiliazioni = value ? value.name : null;
  },
  setIdComitatoRegionaleAffiliazioni: (state, value) => {
    state.idComitatoRegionaleAffiliazioni = value;
  },
  setDataEmissioneDaAffiliazioni: (state, value) => {
    state.dataEmissioneDaAffiliazioni = value;
  },
  setDataEmissioneAAffiliazioni: (state, value) => {
    state.dataEmissioneAAffiliazioni = value;
  },
  setDataApprovazioneDaAffiliazioni: (state, value) => {
    state.dataApprovazioneDaAffiliazioni = value;
  },
  setDataApprovazioneAAffiliazioni: (state, value) => {
    state.dataApprovazioneAAffiliazioni = value;
  },
  setApprovatoAffiliazioni: (state, value) => {
    state.approvatoAffiliazioni = value;
  },
  setMeseApprovazioneAffiliazioni: (state, value) => {
    state.meseApprovazioneAffiliazioni = value;
  },
  setFetchRowsAffiliazioni: (state, value) => {
    state.currentPageAffiliazioni = 1;
    state.rowsToSkipAffiliazioni = 0;
    state.fetchRowsAffiliazioni = value;
  },
  setCurrentPageAffiliazioni: (state, value) => {
    state.currentPageAffiliazioni = value;
    state.rowsToSkipAffiliazioni = parseInt(
      (state.currentPageAffiliazioni - 1) * state.fetchRowsAffiliazioni
    );
  },
  setSortColumnAffiliazioni: (state, value) => {
    state.sortColumnAffiliazioni = value;
  },
  setSortOrderAffiliazioni: (state, value) => {
    state.sortOrderAffiliazioni = value;
  },
  setCategoriaTesseraAffiliazioni: (state, value) => {
    state.id_categoriaAffiliazioni = value;
  },
  resetFiltersEconomato: (state) => {
    Object.assign(state, getDefaultStateAffiliazioni());
  },
  setRowsToSkipAffiliazioni: (state) => {
    state.currentPageAffiliazioni = 1;
    state.rowsToSkipAffiliazioni = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
