const getDefaultState = () => {
  return {
    stagioneEconomatoCompensazioni: null,
    idComitatoRegionaleCompensazioni: null,
    idSocietaCompensazioni: null,
    nomeSocietaCompensazioni: "",
    dataEmissioneDaCompensazioni: null,
    dataEmissioneACompensazioni: null,
    dataApprovazioneDaCompensazioni: null,
    dataApprovazioneACompensazioni: null,
    meseApprovazioneCompensazioni: null,
    approvatoCompensazioni: null,
    numeroCompensazioni: null,
    causaleCompensazioni: null,
    rowsToSkipCompensazioni: 0,
    fetchRowsCompensazioni: 25,
    currentPageCompensazioni: 1,
    sortColumnCompensazioni: "numero",
    sortOrderCompensazioni: "asc",
  };
};
const state = getDefaultState();

const getters = {
  stagioneEconomatoCompensazioni: (state) =>
    state.stagioneEconomatoCompensazioni,
  idComitatoRegionaleCompensazioni: (state) =>
    state.idComitatoRegionaleCompensazioni,
  idSocietaCompensazioni: (state) => state.idSocietaCompensazioni,
  nomeSocietaCompensazioni: (state) => state.nomeSocietaCompensazioni,
  dataEmissioneDaCompensazioni: (state) => state.dataEmissioneDaCompensazioni,
  dataEmissioneACompensazioni: (state) => state.dataEmissioneACompensazioni,
  dataApprovazioneDaCompensazioni: (state) =>
    state.dataApprovazioneDaCompensazioni,
  dataApprovazioneACompensazioni: (state) =>
    state.dataApprovazioneACompensazioni,
  meseApprovazioneCompensazioni: (state) => state.meseApprovazioneCompensazioni,
  approvatoCompensazioni: (state) => state.approvatoCompensazioni,
  numeroCompensazioni: (state) => state.numeroCompensazioni,
  causaleCompensazioni: (state) => state.causaleCompensazioni,
  rowsToSkipCompensazioni: (state) => state.rowsToSkipCompensazioni,
  fetchRowsCompensazioni: (state) => state.fetchRowsCompensazioni,
  currentPageCompensazioni: (state) => state.currentPageCompensazioni,
  sortColumnCompensazioni: (state) => state.sortColumnCompensazioni,
  sortOrderCompensazioni: (state) => state.sortOrderCompensazioni,
};

// const actions = {};
const mutations = {
  setNomeSocietaCompensazioni: (state, value) => {
    state.nomeSocietaCompensazioni = value ? value : null;
  },
  setSocietaCompensazioni: (state, value) => {
    state.idSocietaCompensazioni = value ? value.value : null;
    state.nomeSocietaCompensazioni = value ? value.name : null;
  },
  setIdComitatoRegionaleCompensazioni: (state, value) => {
    state.idComitatoRegionaleCompensazioni = value;
  },
  setDataEmissioneDaCompensazioni: (state, value) => {
    state.dataEmissioneDaCompensazioni = value;
  },
  setDataEmissioneACompensazioni: (state, value) => {
    state.dataEmissioneACompensazioni = value;
  },
  setDataApprovazioneDaCompensazioni: (state, value) => {
    state.dataApprovazioneDaCompensazioni = value;
  },
  setDataApprovazioneACompensazioni: (state, value) => {
    state.dataApprovazioneACompensazioni = value;
  },
  setApprovatoCompensazioni: (state, value) => {
    state.approvatoCompensazioni = value;
  },
  setMeseApprovazioneCompensazioni: (state, value) => {
    state.meseApprovazioneCompensazioni = value;
  },
  setNumeroCompensazioni: (state, value) => {
    state.numeroCompensazioni = value;
  },
  setCausaleCompensazioni: (state, value) => {
    state.causaleCompensazioni = value;
  },
  setFetchRowsCompensazioni: (state, value) => {
    state.currentPageCompensazioni = 1;
    state.rowsToSkipCompensazioni = 0;
    state.fetchRowsCompensazioni = value;
  },
  setCurrentPageCompensazioni: (state, value) => {
    state.currentPageCompensazioni = value;
    state.rowsToSkipCompensazioni = parseInt(
      (state.currentPageCompensazioni - 1) * state.fetchRowsCompensazioni
    );
  },
  setSortColumnCompensazioni: (state, value) => {
    state.sortColumnCompensazioni = value;
  },
  setSortOrderCompensazioni: (state, value) => {
    state.sortOrderCompensazioni = value;
  },
  resetFiltersCompensazioni: (state) => {
    Object.assign(state, getDefaultState());
  },
  resetRowsToSkipCompensazioni: (state) => {
    state.currentPageCompensazioni = 1;
    state.rowsToSkipCompensazioni = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
