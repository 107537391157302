const getDefaultState = () => {
  return {
    societaDenominazioneTesseratoSoc: null,
    idSocietaTesseratoSoc: null,
    idQualificaTesseratoSoc: null,
    nomeTesseratoSoc: null,
    cognomeTesseratoSoc: null,
    inizioTesseramentoTesseratoSoc: null,
    fineTesseramentoTesseratoSoc: null,
    codiceTesseraTesseratoSoc: null,
    certificatoTesseratoSoc: null,
    id_tipo_tesseraTesseratoSoc: null,
    id_disciplinaTesseratoSoc: null,
    rowsToSkipTesseratoSoc: 0,
    fetchRowsTesseratoSoc: 25,
    currentPageTesseratoSoc: 1,
    sortColumnTesseratoSoc: "nominativo_completo",
    sortOrderTesseratoSoc: "asc",
    id_categoriaTesseratoSoc: null,
    comitatoTesseratoSoc: null,
    regioneTesseratoSoc: null,
    provinciaTesseratoSoc: null,
    comuneTesseratoSoc: null,
    comuneTextTesseratoSoc: "",
    categoriaEtaTesseratoSoc: null,
    tesseraStampataTesseratoSoc: null,
    tesseraEstivaTesseratoSoc: null,
    tesseraSatTesseratoSoc: null,
    daApprovareTesseratoSoc: null,
    //verificatoTesseratoSoc: null,
    stampateDaTesseratoSoc: null,
    stampateAlTesseratoSoc: null,
    tutteStagioniTesserati: false,
    selected_categoria: [],
    selected_discipline: [],
    selected_categoria_eta: [],
    tesseramento_tipologie_multi: [],
  };
};
const state = {
  ...getDefaultState(),
  discipline_multi: [],
  tesseramento_categorie_eta_multi: [],
};

const getters = {
  stampateDaTesseratoSoc: (state) => state.stampateDaTesseratoSoc,
  stampateAlTesseratoSoc: (state) => state.stampateAlTesseratoSoc,
  societaDenominazioneTesseratoSoc: (state) =>
    state.societaDenominazioneTesseratoSoc,
  idSocietaTesseratoSoc: (state) => state.idSocietaTesseratoSoc,
  idQualificaTesseratoSoc: (state) => state.idQualificaTesseratoSoc,
  nomeTesseratoSoc: (state) => state.nomeTesseratoSoc,
  cognomeTesseratoSoc: (state) => state.cognomeTesseratoSoc,
  inizioTesseramentoTesseratoSoc: (state) =>
    state.inizioTesseramentoTesseratoSoc,
  fineTesseramentoTesseratoSoc: (state) => state.fineTesseramentoTesseratoSoc,
  codiceTesseraTesseratoSoc: (state) => state.codiceTesseraTesseratoSoc,
  certificatoTesseratoSoc: (state) => state.certificatoTesseratoSoc,
  id_tipo_tesseraTesseratoSoc: (state) => state.id_tipo_tesseraTesseratoSoc,
  id_disciplinaTesseratoSoc: (state) => state.id_disciplinaTesseratoSoc,
  rowsToSkipTesseratoSoc: (state) => state.rowsToSkipTesseratoSoc,
  fetchRowsTesseratoSoc: (state) => state.fetchRowsTesseratoSoc,
  currentPageTesseratoSoc: (state) => state.currentPageTesseratoSoc,
  sortColumnTesseratoSoc: (state) => state.sortColumnTesseratoSoc,
  sortOrderTesseratoSoc: (state) => state.sortOrderTesseratoSoc,
  id_categoriaTesseratoSoc: (state) => state.id_categoriaTesseratoSoc,
  comitatoTesseratoSoc: (state) => state.comitatoTesseratoSoc,
  regioneTesseratoSoc: (state) => state.regioneTesseratoSoc,
  provinciaTesseratoSoc: (state) => state.provinciaTesseratoSoc,
  comuneTesseratoSoc: (state) => state.comuneTesseratoSoc,
  categoriaEtaTesseratoSoc: (state) => state.categoriaEtaTesseratoSoc,
  tesseraEstivaTesseratoSoc: (state) => state.tesseraEstivaTesseratoSoc,
  tesseraSatTesseratoSoc: (state) => state.tesseraSatTesseratoSoc,
  tesseraStampataTesseratoSoc: (state) => state.tesseraStampataTesseratoSoc,
  daApprovareTesseratoSoc: (state) => state.daApprovareTesseratoSoc,
  //verificatoTesseratoSoc: (state) => state.verificatoTesseratoSoc,
  tutteStagioniTesserati: (state) => state.tutteStagioniTesserati,
  comuneTextTesseratoSoc: (state) => state.comuneTextTesseratoSoc,
  selected_categoria: (state) => state.selected_categoria,
  selected_discipline: (state) => state.selected_discipline,
  selected_categoria_eta: (state) => state.selected_categoria_eta,
  tesseramento_tipologie_multi: (state) => state.tesseramento_tipologie_multi,
  discipline_multi: (state) => state.discipline_multi,
  tesseramento_categorie_eta_multi: (state) =>
    state.tesseramento_categorie_eta_multi,
};

const mutations = {
  setComuneTextTesseratoSoc: (state, value) => {
    state.comuneTextTesseratoSoc = value;
  },
  setSocietaDenominazioneTesseratoSoc: (state, value) => {
    state.societaDenominazioneTesseratoSoc = value;
  },
  setStampateDaTesseratoSoc: (state, value) => {
    state.stampateDaTesseratoSoc = value;
  },
  setStampateAlTesseratoSoc: (state, value) => {
    state.stampateAlTesseratoSoc = value;
  },
  setIdSocietaTesseratoSoc: (state, value) => {
    state.idSocietaTesseratoSoc = value;
  },
  setNomeTesseratoSoc: (state, value) => {
    state.nomeTesseratoSoc = value;
  },
  setCognomeTesseratoSoc: (state, value) => {
    state.cognomeTesseratoSoc = value;
  },
  setInizioTesseramentoSoc: (state, value) => {
    state.inizioTesseramentoTesseratoSoc = value;
  },
  setFineTesseramentoSoc: (state, value) => {
    state.fineTesseramentoTesseratoSoc = value;
  },
  setCodiceTesseraSoc: (state, value) => {
    state.codiceTesseraTesseratoSoc = value;
  },
  setCertificatoSoc: (state, value) => {
    state.certificatoTesseratoSoc = value;
  },
  setTipoTesseraSoc: (state, value) => {
    state.id_tipo_tesseraTesseratoSoc = value;
  },
  setSettoreSoc: (state, value) => {
    state.id_disciplinaTesseratoSoc = value;
  },
  setComitatoTesseratoSoc: (state, value) => {
    state.comitatoTesseratoSoc = value;
  },
  setRegioneTesseratoSoc: (state, value) => {
    state.regioneTesseratoSoc = value;
  },
  setProvinciaTesseratoSoc: (state, value) => {
    state.provinciaTesseratoSoc = value;
  },
  setComuneTesseratoSoc: (state, value) => {
    state.comuneTesseratoSoc = value;
  },
  setCategoriaEtaTesseratoSoc: (state, value) => {
    state.categoriaEtaTesseratoSoc = value;
  },
  setTesseraStampataTesseratoSoc: (state, value) => {
    state.tesseraStampataTesseratoSoc = value;
  },
  setTesseraEstivaTesseratoSoc: (state, value) => {
    state.tesseraEstivaTesseratoSoc = value;
  },
  setTesseraSatTesseratoSoc: (state, value) => {
    state.tesseraSatTesseratoSoc = value;
  },
  setdaApprovareTesseratoSoc: (state, value) => {
    state.daApprovareTesseratoSoc = value;
  },
  /* setVerificatoTesseratoSoc: (state, value) => {
    state.verificatoTesseratoSoc = value;
  }, */
  setFetchRowsTesseratoSoc: (state, value) => {
    state.currentPageTesseratoSoc = 1;
    state.rowsToSkipTesseratoSoc = 0;
    state.fetchRowsTesseratoSoc = value;
  },
  setCurrentPageTesseratoSoc: (state, value) => {
    state.currentPageTesseratoSoc = value;
    state.rowsToSkipTesseratoSoc = parseInt(
      (state.currentPageTesseratoSoc - 1) * state.fetchRowsTesseratoSoc
    );
  },
  setSortColumnTesseratoSoc: (state, value) => {
    state.sortColumnTesseratoSoc = value;
  },
  setSortOrderTesseratoSoc: (state, value) => {
    state.sortOrderTesseratoSoc = value;
  },
  setCategoriaTesseraSoc: (state, value) => {
    state.id_categoriaTesseratoSoc = value;
  },
  resetFiltersTesseratoSoc: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipTesseratoSoc: (state) => {
    state.currentPageTesseratoSoc = 1;
    state.rowsToSkipTesseratoSoc = 0;
  },
  setTutteLeStagioniTesserati: (state) => {
    state.tutteStagioniTesserati = !state.tutteStagioniTesserati;
    state.currentPageTesseratoSoc = 1;
    state.rowsToSkipTesseratoSoc = 0;
  },
  setselected_categoria: (state, value) => {
    state.selected_categoria = value;
  },
  setselected_discipline: (state, value) => {
    state.selected_discipline = value;
  },
  setselected_categoria_eta: (state, value) => {
    state.selected_categoria_eta = value;
  },
  settesseramento_tipologie_multi: (state, value) => {
    state.tesseramento_tipologie_multi = value;
  },
  setdiscipline_multi: (state, value) => {
    state.discipline_multi = value;
  },
  settesseramento_categorie_eta_multi: (state, value) => {
    state.tesseramento_categorie_eta_multi = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
