import services from "../axios/dbManag";

export const BulkDelete = (obj) => {
  return services.dbManag
    .post("/sistema/bulkdelete", {
      ...obj,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
