const getDefaultState = () => {
  return {
    rowsToSkipComitati: 0,
    fetchRowsComitati: 25,
    currentPageComitati: 1,
    sortColumnComitati: "data",
    sortOrderComitati: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipComitati: (state) => state.rowsToSkipComitati,
  fetchRowsComitati: (state) => state.fetchRowsComitati,
  currentPageComitati: (state) => state.currentPageComitati,
  sortColumnComitati: (state) => state.sortColumnComitati,
  sortOrderComitati: (state) => state.sortOrderComitati,
};

// const actions = {};
const mutations = {
  setFetchRowsComitati: (state, value) => {
    state.currentPageComitati = 1;
    state.rowsToSkipComitati = 0;
    state.fetchRowsComitati = value;
  },
  setCurrentPageComitati: (state, value) => {
    state.currentPageComitati = value;
    state.rowsToSkipComitati = parseInt(
      (state.currentPageComitati - 1) * state.fetchRowsComitati
    );
  },
  setSortColumnComitati: (state, value) => {
    state.sortColumnComitati = value;
  },
  setSortOrderComitati: (state, value) => {
    state.sortOrderComitati = value;
  },
  resetFiltersComitati: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
