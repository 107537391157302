const getDefaultStateUtentiSocieta = () => {
  return {
    nomeUtenteUtentiSocieta: null,
    denominazioneUtentiSocieta: null,
    codAffiliazioneUtentiSocieta: null,
    rowsToSkipUtentiSocieta: 0,
    fetchRowsUtentiSocieta: 25,
    currentPageUtentiSocieta: 1,
    sortColumnUtentiSocieta: "societa",
    sortOrderUtentiSocieta: "asc",
  };
};
const state = getDefaultStateUtentiSocieta();

const getters = {
  nomeUtenteUtentiSocieta: (state) => state.nomeUtenteUtentiSocieta,
  denominazioneUtentiSocieta: (state) => state.denominazioneUtentiSocieta,
  codAffiliazioneUtentiSocieta: (state) => state.codAffiliazioneUtentiSocieta,
  rowsToSkipUtentiSocieta: (state) => state.rowsToSkipUtentiSocieta,
  fetchRowsUtentiSocieta: (state) => state.fetchRowsUtentiSocieta,
  currentPageUtentiSocieta: (state) => state.currentPageUtentiSocieta,
  sortColumnUtentiSocieta: (state) => state.sortColumnUtentiSocieta,
  sortOrderUtentiSocieta: (state) => state.sortOrderUtentiSocieta,
};

// const actions = {};
const mutations = {
  setNomeUtenteUtentiSocieta: (state, value) => {
    state.nomeUtenteUtentiSocieta = value;
  },
  setDenominazioneUtentiSocieta: (state, value) => {
    state.denominazioneUtentiSocieta = value;
  },
  setCodAffiliazioneUtentiSocieta: (state, value) => {
    state.codAffiliazioneUtentiSocieta = value;
  },
  setFetchRowsUtentiSocieta: (state, value) => {
    state.currentPageUtentiSocieta = 1;
    state.rowsToSkipUtentiSocieta = 0;
    state.fetchRowsUtentiSocieta = value;
  },
  setCurrentPageUtentiSocieta: (state, value) => {
    state.currentPageUtentiSocieta = value;
    state.rowsToSkipUtentiSocieta = parseInt(
      (state.currentPageUtentiSocieta - 1) * state.fetchRowsUtentiSocieta
    );
  },
  setSortColumnUtentiSocieta: (state, value) => {
    state.sortColumnUtentiSocieta = value;
  },
  setSortOrderUtentiSocieta: (state, value) => {
    state.sortOrderUtentiSocieta = value;
  },
  setCategoriaUtentiSocieta: (state, value) => {
    state.id_categoriaUtentiSocieta = value;
  },
  resetFiltersUtentiSocieta: (state) => {
    Object.assign(state, getDefaultStateUtentiSocieta());
  },
  resetRowsToSkipUtentiSocieta: (state) => {
    state.currentPageUtentiSocieta = 1;
    state.rowsToSkipUtentiSocieta = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
