const getDefaultStateTessereGold = () => {
  return {
    stagioneEconomatoTessereGold: null,
    idComitatoRegionaleTessereGold: null,
    dataEmissioneDaTessereGold: null,
    dataEmissioneATessereGold: null,
    dataApprovazioneDaTessereGold: null,
    dataApprovazioneATessereGold: null,
    idTesseratoTessereGold: null,
    nomeTesseratoTessereGold: "",
    nomeSocietaTessereGold: "",
    idSocietaTessereGold: null,
    tipoPagamentoTessereGold: null,
    approvatoTessereGold: null,
    meseTessereGold: null,
    rowsToSkipTessereGold: 0,
    fetchRowsTessereGold: 25,
    currentPageTessereGold: 1,
    sortColumnTessereGold: "numero",
    sortOrderTessereGold: "desc",
  };
};
const state = getDefaultStateTessereGold();

const getters = {
  stagioneEconomatoTessereGold: (state) => state.stagioneEconomatoTessereGold,
  idComitatoRegionaleTessereGold: (state) =>
    state.idComitatoRegionaleTessereGold,
  dataEmissioneDaTessereGold: (state) => state.dataEmissioneDaTessereGold,
  dataEmissioneATessereGold: (state) => state.dataEmissioneATessereGold,
  dataApprovazioneDaTessereGold: (state) => state.dataApprovazioneDaTessereGold,
  dataApprovazioneATessereGold: (state) => state.dataApprovazioneATessereGold,
  idTesseratoTessereGold: (state) => state.idTesseratoTessereGold,
  nomeTesseratoTessereGold: (state) => state.nomeTesseratoTessereGold,
  nomeSocietaTessereGold: (state) => state.nomeSocietaTessereGold,
  idSocietaTessereGold: (state) => state.idSocietaTessereGold,
  tipoPagamentoTessereGold: (state) => state.tipoPagamentoTessereGold,
  approvatoTessereGold: (state) => state.approvatoTessereGold,
  meseTessereGold: (state) => state.meseTessereGold,
  rowsToSkipTessereGold: (state) => state.rowsToSkipTessereGold,
  fetchRowsTessereGold: (state) => state.fetchRowsTessereGold,
  currentPageTessereGold: (state) => state.currentPageTessereGold,
  sortColumnTessereGold: (state) => state.sortColumnTessereGold,
  sortOrderTessereGold: (state) => state.sortOrderTessereGold,
};

// const actions = {};
const mutations = {
  setNomeTesseratoTessereGold: (state, value) => {
    state.nomeTesseratoTessereGold = value ? value : null;
  },
  setNomeSocietaTessereGold: (state, value) => {
    state.nomeSocietaTessereGold = value ? value : null;
  },
  setIdComitatoRegionaleTessereGold: (state, value) => {
    state.idComitatoRegionaleTessereGold = value;
  },
  setDataEmissioneDaTessereGold: (state, value) => {
    state.dataEmissioneDaTessereGold = value;
  },
  setDataEmissioneATessereGold: (state, value) => {
    state.dataEmissioneATessereGold = value;
  },
  setDataApprovazioneDaTessereGold: (state, value) => {
    state.dataApprovazioneDaTessereGold = value;
  },
  setDataApprovazioneATessereGold: (state, value) => {
    state.dataApprovazioneATessereGold = value;
  },
  setTesseratoTessereGold: (state, value) => {
    state.idTesseratoTessereGold = value ? value.value : null;
    state.nomeTesseratoTessereGold = value ? value.name : null;
  },
  setSocietaTessereGold: (state, value) => {
    state.idSocietaTessereGold = value ? value.value : null;
    state.nomeSocietaTessereGold = value ? value.name : null;
  },
  setTipoPagamentoTessereGold: (state, value) => {
    state.tipoPagamentoTessereGold = value;
  },
  setApprovatoTessereGold: (state, value) => {
    state.approvatoTessereGold = value;
  },
  setMeseTessereGold: (state, value) => {
    state.meseTessereGold = value;
  },
  setFetchRowsTessereGold: (state, value) => {
    state.currentPageTessereGold = 1;
    state.rowsToSkipTessereGold = 0;
    state.fetchRowsTessereGold = value;
  },
  setCurrentPageTessereGold: (state, value) => {
    state.currentPageTessereGold = value;
    state.rowsToSkipTessereGold = parseInt(
      (state.currentPageTessereGold - 1) * state.fetchRowsTessereGold
    );
  },
  setSortColumnTessereGold: (state, value) => {
    state.sortColumnTessereGold = value;
  },
  setSortOrderTessereGold: (state, value) => {
    state.sortOrderTessereGold = value;
  },
  setCategoriaTesseraTessereGold: (state, value) => {
    state.id_categoriaTessereGold = value;
  },
  resetFiltersTessereGold: (state) => {
    Object.assign(state, getDefaultStateTessereGold());
  },
  resetRowsToSkipTessereGold: (state) => {
    state.currentPageTessereGold = 1;
    state.rowsToSkipTessereGold = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
