const getDefaultState = () => {
  return {
    disciplinaRanking: 4332,
    categoriaRanking: null,
    gruppoRanking: null,
    tesseratoRanking: null,
    nomeTesseratoRanking: null,
    sessoRanking: null,
    rowsToSkipRanking: 0,
    fetchRowsRanking: 25,
    currentPageRanking: 1,
    sortColumnRanking: "nominativo_completo",
    sortOrderRanking: "asc",
    tutteStagioniRanking: false,
  };
};
const state = getDefaultState();

const getters = {
  disciplinaRanking: (state) => state.disciplinaRanking,
  categoriaRanking: (state) => state.categoriaRanking,
  gruppoRanking: (state) => state.gruppoRanking,
  tesseratoRanking: (state) => state.tesseratoRanking,
  nomeTesseratoRanking: (state) => state.nomeTesseratoRanking,
  sessoRanking: (state) => state.sessoRanking,
  rowsToSkipRanking: (state) => state.rowsToSkipRanking,
  fetchRowsRanking: (state) => state.fetchRowsRanking,
  currentPageRanking: (state) => state.currentPageRanking,
  sortColumnRanking: (state) => state.sortColumnRanking,
  sortOrderRanking: (state) => state.sortOrderRanking,
  tutteStagioniRanking: (state) => state.tutteStagioniRanking,
};

// const actions = {};
const mutations = {
  setDisciplinaRanking: (state, value) => {
    state.disciplinaRanking = value;
  },
  setCategoriaRanking: (state, value) => {
    state.categoriaRanking = value;
  },
  setGruppoRanking: (state, value) => {
    state.gruppoRanking = value;
  },
  setTesseratoRanking: (state, value) => {
    state.tesseratoRanking = value.value;
    state.nomeTesseratoRanking = value.name;
  },
  setNomeTesseratoRanking: (state, value) => {
    state.nomeTesseratoRanking = value;
  },
  setSessoRanking: (state, value) => {
    state.sessoRanking = value;
  },
  setFetchRowsRanking: (state, value) => {
    state.currentPageRanking = 1;
    state.rowsToSkipRanking = 0;
    state.fetchRowsRanking = value;
  },
  setCurrentPageRanking: (state, value) => {
    state.currentPageRanking = value;
    state.rowsToSkipRanking = parseInt(
      (state.currentPageRanking - 1) * state.fetchRowsRanking
    );
  },
  setSortColumnRanking: (state, value) => {
    state.sortColumnRanking = value;
  },
  setSortOrderRanking: (state, value) => {
    state.sortOrderRanking = value;
  },
  resetFiltersRanking: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipRanking: (state) => {
    state.currentPageRanking = 1;
    state.rowsToSkipRanking = 0;
  },
  setTutteLeStagioniRanking: (state) => {
    state.tutteStagioniRanking = !state.tutteStagioniRanking;
    state.currentPageRanking = 1;
    state.rowsToSkipRanking = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
