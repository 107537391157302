const getDefaultState = () => {
  return {
    denominazioneImpAssEntiAggregati: null,
    provinciaImpAssEntiAggregati: null,
    comuneImpAssEntiAggregati: null,
    rowsToSkipImpAssEntiAggregati: 0,
    fetchRowsImpAssEntiAggregati: 25,
    currentPageImpAssEntiAggregati: 1,
    sortColumnImpAssEntiAggregati: "id",
    sortOrderImpAssEntiAggregati: "desc",
  };
};
const state = getDefaultState();

const getters = {
  denominazioneImpAssEntiAggregati: (state) =>
    state.denominazioneImpAssEntiAggregati,
  provinciaImpAssEntiAggregati: (state) => state.provinciaImpAssEntiAggregati,
  comuneImpAssEntiAggregati: (state) => state.comuneImpAssEntiAggregati,
  rowsToSkipImpAssEntiAggregati: (state) => state.rowsToSkipImpAssEntiAggregati,
  fetchRowsImpAssEntiAggregati: (state) => state.fetchRowsImpAssEntiAggregati,
  currentPageImpAssEntiAggregati: (state) =>
    state.currentPageImpAssEntiAggregati,
  sortColumnImpAssEntiAggregati: (state) => state.sortColumnImpAssEntiAggregati,
  sortOrderImpAssEntiAggregati: (state) => state.sortOrderImpAssEntiAggregati,
};

// const actions = {};
const mutations = {
  setDenominazioneImpAssEntiAggregati: (state, value) => {
    state.denominazioneImpAssEntiAggregati = value;
  },
  setProvinciaImpAssEntiAggregati: (state, value) => {
    state.provinciaImpAssEntiAggregati = value;
  },
  setComuneImpAssEntiAggregati: (state, value) => {
    state.comuneImpAssEntiAggregati = value;
  },
  setFetchRowsImpAssEntiAggregati: (state, value) => {
    state.currentPageImpAssEntiAggregati = 1;
    state.rowsToSkipImpAssEntiAggregati = 0;
    state.fetchRowsImpAssEntiAggregati = value;
  },
  setCurrentPageImpAssEntiAggregati: (state, value) => {
    state.currentPageImpAssEntiAggregati = value;
    state.rowsToSkipImpAssEntiAggregati = parseInt(
      (state.currentPageImpAssEntiAggregati - 1) *
        state.fetchRowsImpAssEntiAggregati
    );
  },
  setSortColumnImpAssEntiAggregati: (state, value) => {
    state.sortColumnImpAssEntiAggregati = value;
  },
  setSortOrderImpAssEntiAggregati: (state, value) => {
    state.sortOrderImpAssEntiAggregati = value;
  },
  resetFiltersImpAssEntiAggregati: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipImpAssEntiAggregati: (state) => {
    state.currentPageImpAssEntiAggregati = 1;
    state.rowsToSkipImpAssEntiAggregati = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
