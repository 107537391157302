const getDefaultState = () => {
  return {
    stagione_precRinnovoEntiAggregati: 2023,
    stagione_rinnRinnovoEntiAggregati: 2024,
    id_nazioneRinnovoEntiAggregati: null,
    id_nazioni_genericheRinnovoEntiAggregati: null,
    cittadinanzaRinnovoEntiAggregati: null,
    estivaRinnovoEntiAggregati: null,
    consiglioRinnovoEntiAggregati: false,
    disabileRinnovoEntiAggregati: null,

    cognomeRinnovoEntiAggregati: "",
    data_emissione_daRinnovoEntiAggregati: null,
    data_emissione_aRinnovoEntiAggregati: null,
    codiceTesseraRinnovoEntiAggregati: null,
    certificatoRinnovoEntiAggregati: null,
    id_tipo_tesseraRinnovoEntiAggregati: null,
    id_disciplinaRinnovoEntiAggregati: null,
    rowsToSkipRinnovoEntiAggregati: 0,
    fetchRowsRinnovoEntiAggregati: 25,
    currentPageRinnovoEntiAggregati: 1,
    sortColumnRinnovoEntiAggregati: "id",
    sortOrderRinnovoEntiAggregati: "asc",
    id_categoriaRinnovoEntiAggregati: null,
    id_qualificaRinnovoEntiAggregati: null,
  };
};
const state = getDefaultState();

const getters = {
  cognomeRinnovoEntiAggregati: (state) => state.cognomeRinnovoEntiAggregati,
  data_emissione_daRinnovoEntiAggregati: (state) =>
    state.data_emissione_daRinnovoEntiAggregati,
  data_emissione_aRinnovoEntiAggregati: (state) =>
    state.data_emissione_aRinnovoEntiAggregati,
  codiceTesseraRinnovoEntiAggregati: (state) =>
    state.codiceTesseraRinnovoEntiAggregati,
  certificatoRinnovoEntiAggregati: (state) =>
    state.certificatoRinnovoEntiAggregati,
  id_tipo_tesseraRinnovoEntiAggregati: (state) =>
    state.id_tipo_tesseraRinnovoEntiAggregati,
  id_disciplinaRinnovoEntiAggregati: (state) =>
    state.id_disciplinaRinnovoEntiAggregati,
  rowsToSkipRinnovoEntiAggregati: (state) =>
    state.rowsToSkipRinnovoEntiAggregati,
  fetchRowsRinnovoEntiAggregati: (state) => state.fetchRowsRinnovoEntiAggregati,
  currentPageRinnovoEntiAggregati: (state) =>
    state.currentPageRinnovoEntiAggregati,
  sortColumnRinnovoEntiAggregati: (state) =>
    state.sortColumnRinnovoEntiAggregati,
  sortOrderRinnovoEntiAggregati: (state) => state.sortOrderRinnovoEntiAggregati,
  id_categoriaRinnovoEntiAggregati: (state) =>
    state.id_categoriaRinnovoEntiAggregati,
  id_qualificaRinnovoEntiAggregati: (state) =>
    state.id_qualificaRinnovoEntiAggregati,
  stagione_precRinnovoEntiAggregati: (state) =>
    state.stagione_precRinnovoEntiAggregati,
  stagione_rinnRinnovoEntiAggregati: (state) =>
    state.stagione_rinnRinnovoEntiAggregati,
  id_nazioneRinnovoEntiAggregati: (state) =>
    state.id_nazioneRinnovoEntiAggregati,
  id_nazioni_genericheRinnovoEntiAggregati: (state) =>
    state.id_nazioni_genericheRinnovoEntiAggregati,
  cittadinanzaRinnovoEntiAggregati: (state) =>
    state.cittadinanzaRinnovoEntiAggregati,
  estivaRinnovoEntiAggregati: (state) => state.estivaRinnovoEntiAggregati,
  consiglioRinnovoEntiAggregati: (state) => state.consiglioRinnovoEntiAggregati,
  disabileRinnovoEntiAggregati: (state) => state.disabileRinnovoEntiAggregati,
};

// const actions = {};
const mutations = {
  setCognomeRinnovoEntiAggregati: (state, value) => {
    state.cognomeRinnovoEntiAggregati = value;
  },
  setdata_emissione_daRinnovoEntiAggregati: (state, value) => {
    state.data_emissione_daRinnovoEntiAggregati = value;
  },
  setdata_emissione_aRinnovoEntiAggregati: (state, value) => {
    state.data_emissione_aRinnovoEntiAggregati = value;
  },
  setCodiceTesseraRinnovoEntiAggregati: (state, value) => {
    state.codiceTesseraRinnovoEntiAggregati = value;
  },
  setCertificato: (state, value) => {
    state.certificatoRinnovoEntiAggregati = value;
  },
  setTipoTesseraRinnovoEntiAggregati: (state, value) => {
    state.id_tipo_tesseraRinnovoEntiAggregati = value;
  },
  setSettoreRinnovoEntiAggregati: (state, value) => {
    state.id_disciplinaRinnovoEntiAggregati = value;
  },
  setFetchRowsRinnovoEntiAggregati: (state, value) => {
    state.currentPageRinnovoEntiAggregati = 1;
    state.rowsToSkipRinnovoEntiAggregati = 0;
    state.fetchRowsRinnovoEntiAggregati = value;
  },
  setCurrentPageRinnovoEntiAggregati: (state, value) => {
    state.currentPageRinnovoEntiAggregati = value;
    state.rowsToSkipRinnovoEntiAggregati = parseInt(
      (state.currentPageRinnovoEntiAggregati - 1) *
        state.fetchRowsRinnovoEntiAggregati
    );
  },
  setSortColumnRinnovoEntiAggregati: (state, value) => {
    state.sortColumnRinnovoEntiAggregati = value;
  },
  setSortOrderRinnovoEntiAggregati: (state, value) => {
    state.sortOrderRinnovoEntiAggregati = value;
  },
  setStagionePrec: (state, value) => {
    state.stagione_precRinnovoEntiAggregati = value;
  },
  setStagioneRinn: (state, value) => {
    state.stagione_rinnRinnovoEntiAggregati = value;
  },
  setDisabileRinnovoEntiAggregati: (state, value) => {
    state.disabileRinnovoEntiAggregati = value;
  },
  setCategoriaTesseraRinnovoEntiAggregati: (state, value) => {
    state.id_categoriaRinnovoEntiAggregati = value;
  },
  setEstivaRinnovoEntiAggregati: (state, value) => {
    state.estivaRinnovoEntiAggregati = value;
  },
  setConsiglioRinnovoEntiAggregati: (state, value) => {
    state.consiglioRinnovoEntiAggregati = value;
  },
  setCittadinanzaRinnovoEntiAggregati: (state, value) => {
    state.cittadinanzaRinnovoEntiAggregati = value;
  },
  setNazioneNascitaRinnovoEntiAggregati: (state, value) => {
    state.id_nazioneRinnovoEntiAggregati = value;
  },
  setNazioniGenericheRinnovoEntiAggregati: (state, value) => {
    state.id_nazioni_genericheRinnovoEntiAggregati = value;
  },
  setQualificaRinnovoEntiAggregati: (state, value) => {
    state.id_qualificaRinnovoEntiAggregati = value;
  },
  setDisciplinaRinnovoEntiAggregati: (state, value) => {
    state.id_disciplinaRinnovoEntiAggregati = value;
  },
  resetFiltersRinnovoEntiAggregati: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipRinnovoEntiAggregati: (state) => {
    state.currentPageRinnovoEntiAggregati = 1;
    state.rowsToSkipRinnovoEntiAggregati = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
