const getDefaultState = () => {
  return {
    nomeAlboSnm: "",
    cognomeAlboSnm: "",
    comitatoAlboSnm: null,
    codiceSnmAlboSnm: null,
    rowsToSkipAlboSnm: 0,
    fetchRowsAlboSnm: 25,
    currentPageAlboSnm: 1,
    sortColumnAlboSnm: "data_richiesta",
    sortOrderAlboSnm: "desc",
    tutteStagioniAlbo: false,
  };
};
const state = getDefaultState();

const getters = {
  nomeAlboSnm: (state) => state.nomeAlboSnm,
  cognomeAlboSnm: (state) => state.cognomeAlboSnm,
  comitatoAlboSnm: (state) => state.comitatoAlboSnm,
  codiceSnmAlboSnm: (state) => state.codiceSnmAlboSnm,
  rowsToSkipAlboSnm: (state) => state.rowsToSkipAlboSnm,
  fetchRowsAlboSnm: (state) => state.fetchRowsAlboSnm,
  currentPageAlboSnm: (state) => state.currentPageAlboSnm,
  sortColumnAlboSnm: (state) => state.sortColumnAlboSnm,
  sortOrderAlboSnm: (state) => state.sortOrderAlboSnm,
  tutteStagioniAlbo: (state) => state.tutteStagioniAlbo,
};

// const actions = {};
const mutations = {
  setNomeAlboSnm: (state, value) => {
    state.nomeAlboSnm = value;
  },

  setCognomeAlboSnm: (state, value) => {
    state.cognomeAlboSnm = value;
  },

  setComitatoAlboSnm: (state, value) => {
    state.comitatoAlboSnm = value;
  },

  setCodiceSnmAlboSnm: (state, value) => {
    state.codiceSnmAlboSnm = value;
  },

  setFetchRowsAlboSnm: (state, value) => {
    state.currentPageAlboSnm = 1;
    state.rowsToSkipAlboSnm = 0;
    state.fetchRowsAlboSnm = value;
  },
  setCurrentPageAlboSnm: (state, value) => {
    state.currentPageAlboSnm = value;
    state.rowsToSkipAlboSnm = parseInt(
      (state.currentPageAlboSnm - 1) * state.fetchRowsAlboSnm
    );
  },
  setSortColumnAlboSnm: (state, value) => {
    state.sortColumnAlboSnm = value;
  },
  setSortOrderAlboSnm: (state, value) => {
    state.sortOrderAlboSnm = value;
  },
  resetFiltersAlboSnm: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipAlboSnm: (state) => {
    state.currentPageAlboSnm = 1;
    state.rowsToSkipAlboSnm = 0;
  },
  setTutteLeStagioniAlbo: (state) => {
    state.tutteStagioniAlbo = !state.tutteStagioniAlbo;
    state.currentPageAlboSnm = 1;
    state.rowsToSkipAlboSnm = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
