const getDefaultState = () => {
  return {
    rowsToSkipTrasferimentiUnoEnte: 0,
    fetchRowsTrasferimentiUnoEnte: 25,
    currentPageTrasferimentiUnoEnte: 1,
    sortColumnTrasferimentiUnoEnte: "id",
    sortOrderTrasferimentiUnoEnte: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipTrasferimentiUnoEnte: (state) =>
    state.rowsToSkipTrasferimentiUnoEnte,
  fetchRowsTrasferimentiUnoEnte: (state) => state.fetchRowsTrasferimentiUnoEnte,
  currentPageTrasferimentiUnoEnte: (state) =>
    state.currentPageTrasferimentiUnoEnte,
  sortColumnTrasferimentiUnoEnte: (state) =>
    state.sortColumnTrasferimentiUnoEnte,
  sortOrderTrasferimentiUnoEnte: (state) => state.sortOrderTrasferimentiUnoEnte,
};

// const actions = {};
const mutations = {
  setFetchRowsTrasferimentiUnoEnte: (state, value) => {
    state.currentPageTrasferimentiUnoEnte = 1;
    state.rowsToSkipTrasferimentiUnoEnte = 0;
    state.fetchRowsTrasferimentiUnoEnte = value;
  },
  setCurrentPageTrasferimentiUnoEnte: (state, value) => {
    state.currentPageTrasferimentiUnoEnte = value;
    state.rowsToSkipTrasferimentiUnoEnte = parseInt(
      (state.currentPageTrasferimentiUnoEnte - 1) *
        state.fetchRowsTrasferimentiUnoEnte
    );
  },
  setSortColumnTrasferimentiUnoEnte: (state, value) => {
    state.sortColumnTrasferimentiUnoEnte = value;
  },
  setSortOrderTrasferimentiUnoEnte: (state, value) => {
    state.sortOrderTrasferimentiUnoEnte = value;
  },
  resetFiltersTrasferimentiUnoEnte: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
