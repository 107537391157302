const getDefaultState = () => {
  return {
    codProcedimentoSanzioni: null,
    numDecisioneSanzioni: null,
    idSocietaSanzioni: null,
    idPersonaSanzioni: null,
    nomeSanzioni: null,
    cognomeSanzioni: null,
    idTipoSanzioni: null,
    idStatoPagamentoSanzioni: null,
    inizioDataDecSanzioni: null,
    fineDataDecSanzioni: null,
    rowsToSkipSanzioni: 0,
    fetchRowsSanzioni: 25,
    currentPageSanzioni: 1,
    sortColumnSanzioni: "id_procedimento",
    sortOrderSanzioni: "desc",
    societaDenominazioneProcedimento: null,
    personaDenominazioneProcedimento: null,
    tutteStagioniSanzioni: false,
  };
};
const state = getDefaultState();

const getters = {
  codProcedimentoSanzioni: (state) => state.codProcedimentoSanzioni,
  numDecisioneSanzioni: (state) => state.numDecisioneSanzioni,
  idSocietaSanzioni: (state) => state.idSocietaSanzioni,
  idPersonaSanzioni: (state) => state.idPersonaSanzioni,
  nomeSanzioni: (state) => state.nomeSanzioni,
  cognomeSanzioni: (state) => state.cognomeSanzioni,
  idTipoSanzioni: (state) => state.idTipoSanzioni,
  idStatoPagamentoSanzioni: (state) => state.idStatoPagamentoSanzioni,
  inizioDataDecSanzioni: (state) => state.inizioDataDecSanzioni,
  fineDataDecSanzioni: (state) => state.fineDataDecSanzioni,
  rowsToSkipSanzioni: (state) => state.rowsToSkipSanzioni,
  fetchRowsSanzioni: (state) => state.fetchRowsSanzioni,
  currentPageSanzioni: (state) => state.currentPageSanzioni,
  sortColumnSanzioni: (state) => state.sortColumnSanzioni,
  sortOrderSanzioni: (state) => state.sortOrderSanzioni,
  societaDenominazioneProcedimento: (state) =>
    state.societaDenominazioneProcedimento,
  personaDenominazioneProcedimento: (state) =>
    state.personaDenominazioneProcedimento,
  tutteStagioniSanzioni: (state) => state.tutteStagioniSanzioni,
};

// const actions = {};
const mutations = {
  setProcedimentoSanzioni: (state, value) => {
    state.codProcedimentoSanzioni = value;
  },
  setNumDecisioneSanzioni: (state, value) => {
    state.numDecisioneSanzioni = value;
  },
  setIdSocietaSanzioni: (state, value) => {
    state.idSocietaSanzioni = value;
  },
  setIdPersonaSanzioni: (state, value) => {
    state.idPersonaSanzioni = value;
  },
  setNomeSanzioni: (state, value) => {
    state.nomeSanzioni = value;
  },
  setCognomeSanzioni: (state, value) => {
    state.cognomeSanzioni = value;
  },
  setIdTipoSanzioni: (state, value) => {
    state.idTipoSanzioni = value;
  },
  setIdStatoPagamentoSanzioni: (state, value) => {
    state.idStatoPagamentoSanzioni = value;
  },
  setInizioDataDecSanzioni: (state, value) => {
    state.inizioDataDecSanzioni = value;
  },
  setFineDataDecSanzioni: (state, value) => {
    state.fineDataDecSanzioni = value;
  },
  setSocietaDenominazioneProcedimento: (state, value) => {
    state.societaDenominazioneProcedimento = value;
  },
  setPersonaDenominazioneProcedimento: (state, value) => {
    state.personaDenominazioneProcedimento = value;
  },
  setFetchRowsSanzioni: (state, value) => {
    state.currentPageSanzioni = 1;
    state.rowsToSkipSanzioni = 0;
    state.fetchRowsSanzioni = value;
  },
  setCurrentPageSanzioni: (state, value) => {
    state.currentPageSanzioni = value;
    state.rowsToSkipSanzioni = parseInt(
      (state.currentPageSanzioni - 1) * state.fetchRowsSanzioni
    );
  },
  setSortColumnSanzioni: (state, value) => {
    state.sortColumnSanzioni = value;
  },
  setSortOrderSanzioni: (state, value) => {
    state.sortOrderSanzioni = value;
  },
  resetFiltersSanzioni: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipSanzioni: (state) => {
    state.currentPageSanzioni = 1;
    state.rowsToSkipSanzioni = 0;
  },
  setTutteLeStagioniSanzioni: (state) => {
    state.tutteStagioniSanzioni = !state.tutteStagioniSanzioni;
    state.currentPageSanzioni = 1;
    state.rowsToSkipSanzioni = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
