const getDefaultState = () => {
  return {
    cognomeTesseratoEnte: null,
    inizioTesseramentoTesseratoEnte: null,
    fineTesseramentoTesseratoEnte: null,
    codiceTesseraTesseratoEnte: null,
    certificatoTesseratoEnte: null,
    id_tipo_tesseraTesseratoEnte: null,
    id_disciplinaTesseratoEnte: null,
    rowsToSkipTesseratoEnte: 0,
    fetchRowsTesseratoEnte: 25,
    currentPageTesseratoEnte: 1,
    sortColumnTesseratoEnte: "cognome",
    sortOrderTesseratoEnte: "asc",
    id_categoriaTesseratoEnte: null,
  };
};
const state = getDefaultState();

const getters = {
  cognomeTesseratoEnte: (state) => state.cognomeTesseratoEnte,
  inizioTesseramentoTesseratoEnte: (state) =>
    state.inizioTesseramentoTesseratoEnte,
  fineTesseramentoTesseratoEnte: (state) => state.fineTesseramentoTesseratoEnte,
  codiceTesseraTesseratoEnte: (state) => state.codiceTesseraTesseratoEnte,
  certificatoTesseratoEnte: (state) => state.certificatoTesseratoEnte,
  id_tipo_tesseraTesseratoEnte: (state) => state.id_tipo_tesseraTesseratoEnte,
  id_disciplinaTesseratoEnte: (state) => state.id_disciplinaTesseratoEnte,
  id_qualificaTesseratoEnte: (state) => state.id_qualificaTesseratoEnte,
  rowsToSkipTesseratoEnte: (state) => state.rowsToSkipTesseratoEnte,
  fetchRowsTesseratoEnte: (state) => state.fetchRowsTesseratoEnte,
  currentPageTesseratoEnte: (state) => state.currentPageTesseratoEnte,
  sortColumnTesseratoEnte: (state) => state.sortColumnTesseratoEnte,
  sortOrderTesseratoEnte: (state) => state.sortOrderTesseratoEnte,
  id_categoriaTesseratoEnte: (state) => state.id_categoriaTesseratoEnte,
};

// const actions = {};
const mutations = {
  setCognomeTesseratoEnte: (state, value) => {
    state.cognomeTesseratoEnte = value;
  },
  setInizioTesseramentoTesseratoEnte: (state, value) => {
    state.inizioTesseramentoTesseratoEnte = value;
  },
  setFineTesseramentoTesseratoEnte: (state, value) => {
    state.fineTesseramentoTesseratoEnte = value;
  },
  setCodiceTesseraTesseratoEnte: (state, value) => {
    state.codiceTesseraTesseratoEnte = value;
  },
  setCertificatoTesseratoEnte: (state, value) => {
    state.certificatoTesseratoEnte = value;
  },
  setTipoTesseraTesseratoEnte: (state, value) => {
    state.id_tipo_tesseraTesseratoEnte = value;
  },
  setSettoreTesseratoEnte: (state, value) => {
    state.id_disciplinaTesseratoEnte = value;
  },
  setFetchRowsTesseratoEnte: (state, value) => {
    state.currentPageTesseratoEnte = 1;
    state.rowsToSkipTesseratoEnte = 0;
    state.fetchRowsTesseratoEnte = value;
  },
  setCurrentPageTesseratoEnte: (state, value) => {
    state.currentPageTesseratoEnte = value;
    state.rowsToSkipTesseratoEnte = parseInt(
      (state.currentPageTesseratoEnte - 1) * state.fetchRowsTesseratoEnte
    );
  },
  setSortColumnTesseratoEnte: (state, value) => {
    state.sortColumnTesseratoEnte = value;
  },
  setSortOrderTesseratoEnte: (state, value) => {
    state.sortOrderTesseratoEnte = value;
  },
  setCategoriaTessera: (state, value) => {
    state.id_categoriaTesseratoEnte = value;
  },
  resetFiltersTesseratoEnte: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipTesseratoEnte: (state) => {
    state.currentPageTesseratoEnte = 1;
    state.rowsToSkipTesseratoEnte = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
