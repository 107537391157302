const getDefaultState = () => {
  return {
    tipologiaCorsiAttestati: null,
    specializzCorsiAttestati: null,
    denominazioneCorsiAttestati: null,
    nomeCorsiAttestati: null,
    cognomeCorsiAttestati: null,
    id_personaCorsiAttestati: null,
    rowsToSkipCorsiAttestati: 0,
    fetchRowsCorsiAttestati: 25,
    currentPageCorsiAttestati: 1,
    sortColumnCorsiAttestati: "id",
    sortOrderCorsiAttestati: "asc",
    idoneoCorsiAttestati: null,
    id_tipo_corsiCorsiAttestati: null,
    id_inviatoCorsiAttestati: null,
    id_scaricatoCorsiAttestati: null,
  };
};
const state = getDefaultState();

const getters = {
  idoneoCorsiAttestati: (state) => state.idoneoCorsiAttestati,
  id_tipo_corsiCorsiAttestati: (state) => state.id_tipo_corsiCorsiAttestati,
  nomeCorsiAttestati: (state) => state.nomeCorsiAttestati,
  cognomeCorsiAttestati: (state) => state.cognomeCorsiAttestati,
  tipologiaCorsiAttestati: (state) => state.tipologiaCorsiAttestati,
  specializzCorsiAttestati: (state) => state.specializzCorsiAttestati,
  denominazioneCorsiAttestati: (state) => state.denominazioneCorsiAttestati,
  rowsToSkipCorsiAttestati: (state) => state.rowsToSkipCorsiAttestati,
  fetchRowsCorsiAttestati: (state) => state.fetchRowsCorsiAttestati,
  currentPageCorsiAttestati: (state) => state.currentPageCorsiAttestati,
  sortColumnCorsiAttestati: (state) => state.sortColumnCorsiAttestati,
  sortOrderCorsiAttestati: (state) => state.sortOrderCorsiAttestati,
  id_personaCorsiAttestati: (state) => state.id_personaCorsiAttestati,
  id_inviatoCorsiAttestati: (state) => state.id_inviatoCorsiAttestati,
  id_scaricatoCorsiAttestati: (state) => state.id_scaricatoCorsiAttestati,
};

// const actions = {};
const mutations = {
  setTipologiaCorsiAttestati: (state, value) => {
    state.tipologiaCorsiAttestati = value;
  },
  setSpecializzCorsiAttestati: (state, value) => {
    state.specializzCorsiAttestati = value;
  },
  setDenominazioneCorsiAttestati: (state, value) => {
    state.denominazioneCorsiAttestati = value;
  },
  setFetchRowsCorsiAttestati: (state, value) => {
    state.currentPageCorsiAttestati = 1;
    state.rowsToSkipCorsiAttestati = 0;
    state.fetchRowsCorsiAttestati = value;
  },
  setCurrentPageCorsiAttestati: (state, value) => {
    state.currentPageCorsiAttestati = value;
    state.rowsToSkipCorsiAttestati = parseInt(
      (state.currentPageCorsiAttestati - 1) * state.fetchRowsCorsiAttestati
    );
  },
  setSortColumnCorsiAttestati: (state, value) => {
    state.sortColumnCorsiAttestati = value;
  },
  setSortOrderCorsiAttestati: (state, value) => {
    state.sortOrderCorsiAttestati = value;
  },
  resetFiltersCorsiAttestati: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipCorsiAttestati: (state) => {
    state.currentPageCorsiAttestati = 1;
    state.rowsToSkipCorsiAttestati = 0;
  },
  setIdPersonaCorsiAttestati: (state, value) => {
    state.id_personaCorsiAttestati = value;
  },
  setNomeCorsiAttestati: (state, value) => {
    state.nomeCorsiAttestati = value;
  },
  setCognomeCorsiAttestati: (state, value) => {
    state.cognomeCorsiAttestati = value;
  },
  setIdoneoCorsiAttestati: (state, value) => {
    state.idoneoCorsiAttestati = value;
  },
  setId_tipo_corsiCorsiAttestati: (state, value) => {
    state.id_tipo_corsiCorsiAttestati = value;
  },
  setIdInviatoCorsiAttestati: (state, value) => {
    state.id_inviatoCorsiAttestati = value;
  },
  setIdScaricatoCorsiAttestati: (state, value) => {
    state.id_scaricatoCorsiAttestati = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
