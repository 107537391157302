const getDefaultState = () => {
  return {
    idPersonaCorsoPartecipanti: null,
    idoneoCorsoPartecipanti: null,
    scontatoCorsoPartecipanti: null,
    approvatoCorsoPartecipanti: null,
    pagatoCorsoPartecipanti: null,
    presenteCorsoPartecipanti: null,
    rowsToSkipCorsoPartecipanti: 0,
    fetchRowsCorsoPartecipanti: 25,
    currentPageCorsoPartecipanti: 1,
    sortColumnCorsoPartecipanti: "nominativo_completo",
    sortOrderCorsoPartecipanti: "asc",
    nomePersonaCorsoPartecipanti: "",

    rowsToSkipPartecipanteDoc: 0,
    fetchRowsPartecipanteDoc: 100,
    currentPagePartecipanteDoc: 1,
    sortColumnPartecipanteDoc: "id",
    sortOrderPartecipanteDoc: "asc",
  };
};
const state = getDefaultState();

const getters = {
  nomePersonaCorsoPartecipanti: (state) => state.nomePersonaCorsoPartecipanti,
  idPersonaCorsoPartecipanti: (state) => state.idPersonaCorsoPartecipanti,
  idoneoCorsoPartecipanti: (state) => state.idoneoCorsoPartecipanti,
  scontatoCorsoPartecipanti: (state) => state.scontatoCorsoPartecipanti,
  approvatoCorsoPartecipanti: (state) => state.approvatoCorsoPartecipanti,
  pagatoCorsoPartecipanti: (state) => state.pagatoCorsoPartecipanti,
  presenteCorsoPartecipanti: (state) => state.presenteCorsoPartecipanti,
  rowsToSkipCorsoPartecipanti: (state) => state.rowsToSkipCorsoPartecipanti,
  fetchRowsCorsoPartecipanti: (state) => state.fetchRowsCorsoPartecipanti,
  currentPageCorsoPartecipanti: (state) => state.currentPageCorsoPartecipanti,
  sortColumnCorsoPartecipanti: (state) => state.sortColumnCorsoPartecipanti,
  sortOrderCorsoPartecipanti: (state) => state.sortOrderCorsoPartecipanti,

  rowsToSkipPartecipanteDoc: (state) => state.rowsToSkipPartecipanteDoc,
  fetchRowsPartecipanteDoc: (state) => state.fetchRowsPartecipanteDoc,
  currentPagePartecipanteDoc: (state) => state.currentPagePartecipanteDoc,
  sortColumnPartecipanteDoc: (state) => state.sortColumnPartecipanteDoc,
  sortOrderPartecipanteDoc: (state) => state.sortOrderPartecipanteDoc,
};

// const actions = {};
const mutations = {
  setNomePersonaCorsoPartecipanti: (state, value) => {
    state.nomePersonaCorsoPartecipanti = value;
  },
  setIdPersonaCorsoPartecipanti: (state, value) => {
    state.idPersonaCorsoPartecipanti = value;
  },
  setIdoneoCorsoPartecipanti: (state, value) => {
    state.idoneoCorsoPartecipanti = value;
  },
  setScontatoCorsoPartecipanti: (state, value) => {
    state.scontatoCorsoPartecipanti = value;
  },
  setApprovatoCorsoPartecipanti: (state, value) => {
    state.approvatoCorsoPartecipanti = value;
  },
  setPagatoCorsoPartecipanti: (state, value) => {
    state.pagatoCorsoPartecipanti = value;
  },
  setPresenteCorsoPartecipanti: (state, value) => {
    state.presenteCorsoPartecipanti = value;
  },
  setFetchRowsCorsoPartecipanti: (state, value) => {
    state.currentPageCorsoPartecipanti = 1;
    state.rowsToSkipCorsoPartecipanti = 0;
    state.fetchRowsCorsoPartecipanti = value;
  },
  setCurrentPageCorsoPartecipanti: (state, value) => {
    state.currentPageCorsoPartecipanti = value;
    state.rowsToSkipCorsoPartecipanti = parseInt(
      (state.currentPageCorsoPartecipanti - 1) *
        state.fetchRowsCorsoPartecipanti
    );
  },
  setSortColumnCorsoPartecipanti: (state, value) => {
    state.sortColumnCorsoPartecipanti = value;
  },
  setSortOrderCorsoPartecipanti: (state, value) => {
    state.sortOrderCorsoPartecipanti = value;
  },
  resetFiltersCorsoPartecipanti: (state) => {
    Object.assign(state, getDefaultState());
  },

  setFetchRowsPartecipanteDoc: (state, value) => {
    state.currentPagePartecipanteDoc = 1;
    state.rowsToSkipPartecipanteDoc = 0;
    state.fetchRowsPartecipanteDoc = value;
  },
  setCurrentPagePartecipanteDoc: (state, value) => {
    state.currentPagePartecipanteDoc = value;
    state.rowsToSkipPartecipanteDoc = parseInt(
      (state.currentPagePartecipanteDoc - 1) * state.fetchRowsPartecipanteDoc
    );
  },
  setSortColumnPartecipanteDoc: (state, value) => {
    state.sortColumnPartecipanteDoc = value;
  },
  setSortOrderPartecipanteDoc: (state, value) => {
    state.sortOrderPartecipanteDoc = value;
  },
  resetFiltersPartecipanteDoc: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipCorsoPartecipanti: (state) => {
    state.currentPageCorsoPartecipanti = 1;
    state.rowsToSkipCorsoPartecipanti = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
