const getDefaultState = () => {
  return {
    // anno: 0,
    data_ultimo_invio_raas_consiglio: null, // "2023-01-02T13:35:10.715Z",
    denominazione_raas_consiglio: null, //"string",
    codice_fiscale_raas_consiglio: null, //"string",
    codice_affiliazione_raas_consiglio: null, //"string",
    id_comitato_raas_consiglio: null, //0,
    stati_invio_raas_consiglio: null, //"string",
    rowtoskip_raas_consiglio: 0, //0,
    fetchrows_raas_consiglio: 25, //0,
    sortcolumn_raas_consiglio: "ultimo_invio", //"string",
    sortorder_raas_consiglio: "desc", //"string",
    currentPage_raas_consiglio: 1,
  };
};
const state = getDefaultState();

const getters = {
  //   anno: (state) => state.disciplinaRanking,
  data_ultimo_invio_raas_consiglio: (state) =>
    state.data_ultimo_invio_raas_consiglio,
  denominazione_raas_consiglio: (state) => state.denominazione_raas_consiglio,
  codice_fiscale_raas_consiglio: (state) => state.codice_fiscale_raas_consiglio,
  codice_affiliazione_raas_consiglio: (state) =>
    state.codice_affiliazione_raas_consiglio,
  id_comitato_raas_consiglio: (state) => state.id_comitato_raas_consiglio,
  stati_invio_raas_consiglio: (state) => state.stati_invio_raas_consiglio,
  rowtoskip_raas_consiglio: (state) => state.rowtoskip_raas_consiglio,
  fetchrows_raas_consiglio: (state) => state.fetchrows_raas_consiglio,
  sortcolumn_raas_consiglio: (state) => state.sortcolumn_raas_consiglio,
  sortorder_raas_consiglio: (state) => state.sortorder_raas_consiglio,
  currentPage_raas_consiglio: (state) => state.currentPage_raas_consiglio,
};

const actions = {
  setInviateConsiglioRaas: (context) => {
    context.commit("setStati_invio_raas_consiglio", "2");
  },
  setDaInviareConsiglioRaas: (context) => {
    context.commit("setStati_invio_raas_consiglio", "1");
  },
  setErroriConsiglioRaas: (context) => {
    context.commit("setStati_invio_raas_consiglio", "3");
  },
  setDaReinviareConsiglioRaas: (context) => {
    context.commit("setStati_invio_raas_consiglio", "4");
  },
};
const mutations = {
  setData_ultimo_invio_raas_consiglio: (state, value) => {
    state.data_ultimo_invio_raas_consiglio = value;
  },
  setDenominazione_raas_consiglio: (state, value) => {
    state.denominazione_raas_consiglio = value;
  },
  setCodice_fiscale_raas_consiglio: (state, value) => {
    state.codice_fiscale_raas_consiglio = value;
  },
  setCodice_affiliazione_raas_consiglio: (state, value) => {
    state.codice_affiliazione_raas_consiglio = value;
  },
  setId_comitato_raas_consiglio: (state, value) => {
    state.id_comitato_raas_consiglio = value;
  },
  setStati_invio_raas_consiglio: (state, value) => {
    if (
      state.stati_invio_raas_consiglio &&
      state.stati_invio_raas_consiglio.includes(value)
    ) {
      if (state.stati_invio_raas_consiglio.includes(`,${value}`))
        state.stati_invio_raas_consiglio =
          state.stati_invio_raas_consiglio.replace(`,${value}`, "");
      else if (state.stati_invio_raas_consiglio.includes(`${value},`))
        state.stati_invio_raas_consiglio =
          state.stati_invio_raas_consiglio.replace(`${value},`, "");
      else
        state.stati_invio_raas_consiglio =
          state.stati_invio_raas_consiglio.replace(`${value}`, "");
    } else {
      state.stati_invio_raas_consiglio = `${
        state.stati_invio_raas_consiglio
          ? state.stati_invio_raas_consiglio + ","
          : ""
      }${value}`;
    }
    if (state.stati_invio_raas_consiglio === "")
      state.stati_invio_raas_consiglio = null;
  },
  //   setInviateConsiglioRaas: (state, value) => {
  //     state.stati_invio_raas_consiglio =  `${state.stati_invio_raas_consiglio},${value}`;
  //   },

  setFetchRowsRaasCons: (state, value) => {
    state.currentPage_raas_consiglio = 1;
    state.rowtoskip_raas_consiglio = 0;
    state.fetchrows_raas_consiglio = value;
  },
  setCurrentPageRaasCons: (state, value) => {
    state.currentPage_raas_consiglio = value;
    state.rowtoskip_raas_consiglio = parseInt(
      (state.currentPage_raas_consiglio - 1) * state.fetchrows_raas_consiglio
    );
  },
  setSortColumnRaasCons: (state, value) => {
    state.sortcolumn_raas_consiglio = value;
  },
  setSortOrderRaasCons: (state, value) => {
    state.sortorder_raas_consiglio = value;
  },
  resetFiltersRaasCons: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipRaasCons: (state) => {
    state.currentPage_raas_consiglio = 1;
    state.rowtoskip_raas_consiglio = 0;
  },
  //   setTutteLeStagioniRanking: (state) => {
  //     state.tutteStagioniRanking = !state.tutteStagioniRanking;
  //     state.currentPageRanking = 1;
  //     state.rowsToSkipRanking = 0;
  //   },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
