const getDefaultState = () => {
  return {
    denominazioneAffiliazioniSecondarie: null,
    comitatoAffiliazioniSecondarie: null,
    codAffiliazioneAffiliazioniSecondarie: null,
    disciplinaAffiliazioniSecondarie: null,
    affiliazioneDalAffiliazioniSecondarie: null,
    affiliazioneAlAffiliazioniSecondarie: null,
    statoAffiliazioniSecondarie: null,
    tutteStagioniAffiliazioniSecondarie: false,
    rowsToSkipAffiliazioniSecondarie: 0,
    fetchRowsAffiliazioniSecondarie: 25,
    currentPageAffiliazioniSecondarie: 1,
    sortColumnAffiliazioniSecondarie: "id",
    sortOrderAffiliazioniSecondarie: "desc",
    selectedComitatoAffSecondaria: null,
    emailAffSecondaria: "",
    puo_utilizzare_pulsanti_sezioni: 0,
  };
};
const state = getDefaultState();

const getters = {
  denominazioneAffiliazioniSecondarie: (state) =>
    state.denominazioneAffiliazioniSecondarie,
  comitatoAffiliazioniSecondarie: (state) =>
    state.comitatoAffiliazioniSecondarie,
  codAffiliazioneAffiliazioniSecondarie: (state) =>
    state.codAffiliazioneAffiliazioniSecondarie,
  disciplinaAffiliazioniSecondarie: (state) =>
    state.disciplinaAffiliazioniSecondarie,
  affiliazioneDalAffiliazioniSecondarie: (state) =>
    state.affiliazioneDalAffiliazioniSecondarie,
  affiliazioneAlAffiliazioniSecondarie: (state) =>
    state.affiliazioneAlAffiliazioniSecondarie,
  statoAffiliazioniSecondarie: (state) => state.statoAffiliazioniSecondarie,
  tutteStagioniAffiliazioniSecondarie: (state) =>
    state.tutteStagioniAffiliazioniSecondarie,
  rowsToSkipAffiliazioniSecondarie: (state) =>
    state.rowsToSkipAffiliazioniSecondarie,
  fetchRowsAffiliazioniSecondarie: (state) =>
    state.fetchRowsAffiliazioniSecondarie,
  currentPageAffiliazioniSecondarie: (state) =>
    state.currentPageAffiliazioniSecondarie,
  sortColumnAffiliazioniSecondarie: (state) =>
    state.sortColumnAffiliazioniSecondarie,
  sortOrderAffiliazioniSecondarie: (state) =>
    state.sortOrderAffiliazioniSecondarie,
  selectedComitatoAffSecondaria: (state) => state.selectedComitatoAffSecondaria,
  emailAffSecondaria: (state) => state.emailAffSecondaria,
  puo_utilizzare_pulsanti_sezioni: (state) =>
    state.puo_utilizzare_pulsanti_sezioni,
};

// const actions = {};
const mutations = {
  setDenominazioneAffiliazioniSecondarie: (state, value) => {
    state.denominazioneAffiliazioniSecondarie = value;
  },
  setComitatoAffiliazioniSecondarie: (state, value) => {
    state.comitatoAffiliazioniSecondarie = value;
  },
  setCodAffiliazioneAffiliazioniSecondarie: (state, value) => {
    state.codAffiliazioneAffiliazioniSecondarie = value;
  },
  setDisciplinaAffiliazioniSecondarie: (state, value) => {
    state.disciplinaAffiliazioniSecondarie = value;
  },
  setAffiliazioneDalAffiliazioniSecondarie: (state, value) => {
    state.affiliazioneDalAffiliazioniSecondarie = value;
  },
  setAffiliazioneAlAffiliazioniSecondarie: (state, value) => {
    state.affiliazioneAlAffiliazioniSecondarie = value;
  },
  setStatoAffiliazioniSecondarie: (state, value) => {
    state.statoAffiliazioniSecondarie = value;
  },
  setTutteLeStagioniAffiliazioniSecondarie: (state) => {
    state.tutteStagioniAffiliazioniSecondarie =
      !state.tutteStagioniAffiliazioniSecondarie;
    state.currentPageAffiliazioniSecondarie = 1;
    state.rowsToSkipAffiliazioniSecondarie = 0;
  },
  setFetchRowsAffiliazioniSecondarie: (state, value) => {
    state.currentPageAffiliazioniSecondarie = 1;
    state.rowsToSkipAffiliazioniSecondarie = 0;
    state.fetchRowsAffiliazioniSecondarie = value;
  },
  setCurrentPageAffiliazioniSecondarie: (state, value) => {
    state.currentPageAffiliazioniSecondarie = value;
    state.rowsToSkipAffiliazioniSecondarie = parseInt(
      (state.currentPageAffiliazioniSecondarie - 1) *
        state.fetchRowsAffiliazioniSecondarie
    );
  },
  setSortColumnAffiliazioniSecondarie: (state, value) => {
    state.sortColumnAffiliazioniSecondarie = value;
  },
  setSortOrderAffiliazioniSecondarie: (state, value) => {
    state.sortOrderAffiliazioniSecondarie = value;
  },
  setRowsToSkipAffiliazioniSecondarie: (state) => {
    state.currentPageAffiliazioniSecondarie = 1;
    state.rowsToSkipAffiliazioniSecondarie = 0;
  },
  resetFiltersAffiliazioniSecondarie: (state) => {
    Object.assign(state, getDefaultState());
  },
  setSelectedComitatoAffSecondaria: (state, value) => {
    state.selectedComitatoAffSecondaria = value;
  },
  setEmailAffSecondaria: (state, value) => {
    state.emailAffSecondaria = value;
  },
  set_puo_utilizzare_pulsanti_sezioni: (state, value) => {
    state.puo_utilizzare_pulsanti_sezioni = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
