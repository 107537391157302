const getDefaultState = () => {
  return {
    // anno: 0,
    data_ultimo_invio_raas_sport: null, // "2023-01-02T13:35:10.715Z",
    denominazione_raas_sport: null, //"string",
    codice_fiscale_raas_sport: null, //"string",
    codice_affiliazione_raas_sport: null, //"string",
    id_comitato_raas_sport: null, //0,
    stati_invio_raas_sport: null, //"string",
    rowtoskip_raas_sport: 0, //0,
    fetchrows_raas_sport: 25, //0,
    sortcolumn_raas_sport: "data_inizio", //"string",
    sortorder_raas_sport: "desc", //"string",
    currentPage_raas_sport: 1,
  };
};
const state = getDefaultState();

const getters = {
  //   anno: (state) => state.disciplinaRanking,
  data_ultimo_invio_raas_sport: (state) => state.data_ultimo_invio_raas_sport,
  denominazione_raas_sport: (state) => state.denominazione_raas_sport,
  codice_fiscale_raas_sport: (state) => state.codice_fiscale_raas_sport,
  codice_affiliazione_raas_sport: (state) =>
    state.codice_affiliazione_raas_sport,
  id_comitato_raas_sport: (state) => state.id_comitato_raas_sport,
  stati_invio_raas_sport: (state) => state.stati_invio_raas_sport,
  rowtoskip_raas_sport: (state) => state.rowtoskip_raas_sport,
  fetchrows_raas_sport: (state) => state.fetchrows_raas_sport,
  sortcolumn_raas_sport: (state) => state.sortcolumn_raas_sport,
  sortorder_raas_sport: (state) => state.sortorder_raas_sport,
  currentPage_raas_sport: (state) => state.currentPage_raas_sport,
};

const actions = {
  setInviateSportRaas: (context) => {
    context.commit("setStati_invio_raas_sport", "2");
  },
  setDaInviareSportRaas: (context) => {
    context.commit("setStati_invio_raas_sport", "1");
  },
  setErroriSportRaas: (context) => {
    context.commit("setStati_invio_raas_sport", "3");
  },
  setDaReinviareSportRaas: (context) => {
    context.commit("setStati_invio_raas_sport", "4");
  },
};
const mutations = {
  setData_ultimo_invio_raas_sport: (state, value) => {
    state.data_ultimo_invio_raas_sport = value;
  },
  setDenominazione_raas_sport: (state, value) => {
    state.denominazione_raas_sport = value;
  },
  setCodice_fiscale_raas_sport: (state, value) => {
    state.codice_fiscale_raas_sport = value;
  },
  setCodice_affiliazione_raas_sport: (state, value) => {
    state.codice_affiliazione_raas_sport = value;
  },
  setId_comitato_raas_sport: (state, value) => {
    state.id_comitato_raas_sport = value;
  },
  setStati_invio_raas_sport: (state, value) => {
    if (
      state.stati_invio_raas_sport &&
      state.stati_invio_raas_sport.includes(value)
    ) {
      if (state.stati_invio_raas_sport.includes(`,${value}`))
        state.stati_invio_raas_sport = state.stati_invio_raas_sport.replace(
          `,${value}`,
          ""
        );
      else if (state.stati_invio_raas_sport.includes(`${value},`))
        state.stati_invio_raas_sport = state.stati_invio_raas_sport.replace(
          `${value},`,
          ""
        );
      else
        state.stati_invio_raas_sport = state.stati_invio_raas_sport.replace(
          `${value}`,
          ""
        );
    } else {
      state.stati_invio_raas_sport = `${
        state.stati_invio_raas_sport ? state.stati_invio_raas_sport + "," : ""
      }${value}`;
    }
    if (state.stati_invio_raas_sport === "")
      state.stati_invio_raas_sport = null;
  },
  //   setInviateSportRaas: (state, value) => {
  //     state.stati_invio_raas_sport =  `${state.stati_invio_raas_sport},${value}`;
  //   },

  setFetchRowsSportRaas: (state, value) => {
    state.currentPage_raas_sport = 1;
    state.rowtoskip_raas_sport = 0;
    state.fetchrows_raas_sport = value;
  },
  setCurrentPageSportRaas: (state, value) => {
    state.currentPage_raas_sport = value;
    state.rowtoskip_raas_sport = parseInt(
      (state.currentPage_raas_sport - 1) * state.fetchrows_raas_sport
    );
  },
  setSortColumnSportRaas: (state, value) => {
    state.sortcolumn_raas_sport = value;
  },
  setSortOrderSportRaas: (state, value) => {
    state.sortorder_raas_sport = value;
  },
  resetFiltersSportRaas: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipSportRaas: (state) => {
    state.currentPage_raas_sport = 1;
    state.rowtoskip_raas_sport = 0;
  },
  //   setTutteLeStagioniRanking: (state) => {
  //     state.tutteStagioniRanking = !state.tutteStagioniRanking;
  //     state.currentPageRanking = 1;
  //     state.rowsToSkipRanking = 0;
  //   },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
