import {
  getDataFromApi,
  getDataFromListApi,
  getDataFromListApiGet,
} from "../../axios/apiRequests";

export default {
  state: {},
  getters: {
    getStateFromName: (state) => (stateName) => {
      return state[stateName];
    },
  },
  actions: {
    setStoreListData: async (
      stateObj,
      { keys, apiLink, itemName, version, method = "post" }
    ) => {
      const { state } = stateObj;
      // se esiste, puliscilo ad ogni chiamata
      if (state[`results${itemName}`])
        state[`results${itemName}`].splice(
          0,
          state[`results${itemName}`].length
        );
      state[`loaded${itemName}`] = false;
      let res = {};
      if (method == "post")
        res = await getDataFromListApi(keys, apiLink, version);
      else res = await getDataFromListApiGet(keys, apiLink, version);
      const {
        results,
        record,
        campi_Locked,
        comandi_Locked,
        status,
        read_only,
      } = res;
      state[`results${itemName}`] = results;
      state[`record${itemName}`] = record;
      state[`campi_Locked${itemName}`] = campi_Locked;
      state[`comandi_Locked${itemName}`] = comandi_Locked;
      state[`status${itemName}`] = status ? status : 503;
      state[`loaded${itemName}`] = true;
      state[`read_only${itemName}`] = read_only;
    },
    setStoreData: async (stateObj, { keys, apiLink, itemName }) => {
      const { state } = stateObj;
      // carica la lookup nello state con il nome che arriva da api
      const res = await getDataFromApi(keys, apiLink);

      if (itemName === undefined) {
        if (Array.isArray(res)) {
          res.forEach((element) => {
            for (const item in element) {
              state[item] = element[item];
              // state[item].push({ id: 0, label: "-" });
            }
          });
        } else {
          for (const item in res) {
            state[item] = res[item];
          }
        }
      } else {
        // carica la lookup nello state con il nome passato da function
        state[itemName] = res;
      }
    },
  },
  mutations: {
    modifyStoreDataFromName: (state, name) => {
      state[name].push({ id: "T", nome: "Test" });
    },
    assignStateValue: (state, { value, stateName }) => {
      state[stateName] = value;
    },
    spliceArrayFromName: (state, stateName) => {
      if (state[stateName] === undefined) return;
      state[stateName].splice(0, state[stateName].length);
    },
    resetObjectFromName: (state, stateName) => {
      if (state[stateName] === undefined) return;
      state[stateName] = {};
    },
    populateArrayFromName: (state, { stateName, array }) => {
      array.forEach((element) => {
        state[stateName].push(element);
      });
    },
  },
};
