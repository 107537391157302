const getDefaultState = () => {
  return {
    // anno: 0,
    data_ultimo_invio_raas_documenti: null, // "2023-01-02T13:35:10.715Z",
    denominazione_raas_documenti: null, //"string",
    codice_fiscale_raas_documenti: null, //"string",
    codice_affiliazione_raas_documenti: null, //"string",
    id_comitato_raas_documenti: null, //0,
    stati_invio_raas_documenti: null, //"string",
    rowtoskip_raas_documenti: 0, //0,
    fetchrows_raas_documenti: 25, //0,
    sortcolumn_raas_documenti: "ultimo_invio", //"string",
    sortorder_raas_documenti: "desc", //"string",
    currentPage_raas_documenti: 1,
  };
};
const state = getDefaultState();

const getters = {
  //   anno: (state) => state.disciplinaRanking,
  data_ultimo_invio_raas_documenti: (state) =>
    state.data_ultimo_invio_raas_documenti,
  denominazione_raas_documenti: (state) => state.denominazione_raas_documenti,
  codice_fiscale_raas_documenti: (state) => state.codice_fiscale_raas_documenti,
  codice_affiliazione_raas_documenti: (state) =>
    state.codice_affiliazione_raas_documenti,
  id_comitato_raas_documenti: (state) => state.id_comitato_raas_documenti,
  stati_invio_raas_documenti: (state) => state.stati_invio_raas_documenti,
  rowtoskip_raas_documenti: (state) => state.rowtoskip_raas_documenti,
  fetchrows_raas_documenti: (state) => state.fetchrows_raas_documenti,
  sortcolumn_raas_documenti: (state) => state.sortcolumn_raas_documenti,
  sortorder_raas_documenti: (state) => state.sortorder_raas_documenti,
  currentPage_raas_documenti: (state) => state.currentPage_raas_documenti,
};

const actions = {
  setInviateDocumentiRaas: (context) => {
    context.commit("setStati_invio_raas_documenti", "2");
  },
  setDaInviareDocumentiRaas: (context) => {
    context.commit("setStati_invio_raas_documenti", "1");
  },
  setErroriDocumentiRaas: (context) => {
    context.commit("setStati_invio_raas_documenti", "3");
  },
  setDaReinviareDocumentiRaas: (context) => {
    context.commit("setStati_invio_raas_documenti", "4");
  },
};
const mutations = {
  setData_ultimo_invio_raas_documenti: (state, value) => {
    state.data_ultimo_invio_raas_documenti = value;
  },
  setDenominazione_raas_documenti: (state, value) => {
    state.denominazione_raas_documenti = value;
  },
  setCodice_fiscale_raas_documenti: (state, value) => {
    state.codice_fiscale_raas_documenti = value;
  },
  setCodice_affiliazione_raas_documenti: (state, value) => {
    state.codice_affiliazione_raas_documenti = value;
  },
  setId_comitato_raas_documenti: (state, value) => {
    state.id_comitato_raas_documenti = value;
  },
  setStati_invio_raas_documenti: (state, value) => {
    if (
      state.stati_invio_raas_documenti &&
      state.stati_invio_raas_documenti.includes(value)
    ) {
      if (state.stati_invio_raas_documenti.includes(`,${value}`))
        state.stati_invio_raas_documenti =
          state.stati_invio_raas_documenti.replace(`,${value}`, "");
      else if (state.stati_invio_raas_documenti.includes(`${value},`))
        state.stati_invio_raas_documenti =
          state.stati_invio_raas_documenti.replace(`${value},`, "");
      else
        state.stati_invio_raas_documenti =
          state.stati_invio_raas_documenti.replace(`${value}`, "");
    } else {
      state.stati_invio_raas_documenti = `${
        state.stati_invio_raas_documenti
          ? state.stati_invio_raas_documenti + ","
          : ""
      }${value}`;
    }
    if (state.stati_invio_raas_documenti === "")
      state.stati_invio_raas_documenti = null;
  },
  //   setInviateDocumentiRaas: (state, value) => {
  //     state.stati_invio_raas_documenti =  `${state.stati_invio_raas_documenti},${value}`;
  //   },

  setFetchRowsDocumentiRaas: (state, value) => {
    state.currentPage_raas_documenti = 1;
    state.rowtoskip_raas_documenti = 0;
    state.fetchrows_raas_documenti = value;
  },
  setCurrentPageDocumentiRaas: (state, value) => {
    state.currentPage_raas_documenti = value;
    state.rowtoskip_raas_documenti = parseInt(
      (state.currentPage_raas_documenti - 1) * state.fetchrows_raas_documenti
    );
  },
  setSortColumnDocumentiRaas: (state, value) => {
    state.sortcolumn_raas_documenti = value;
  },
  setSortOrderDocumentiRaas: (state, value) => {
    state.sortorder_raas_documenti = value;
  },
  resetFiltersDocumentiRaas: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipDocumentiRaas: (state) => {
    state.currentPage_raas_documenti = 1;
    state.rowtoskip_raas_documenti = 0;
  },
  //   setTutteLeStagioniRanking: (state) => {
  //     state.tutteStagioniRanking = !state.tutteStagioniRanking;
  //     state.currentPageRanking = 1;
  //     state.rowsToSkipRanking = 0;
  //   },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
