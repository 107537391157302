const getDefaultState = () => {
  return {
    rowsToSkipAlboInsegnante: 0,
    fetchRowsAlboInsegnante: 25,
    currentPageAlboInsegnante: 1,
    sortColumnAlboInsegnante: "stagione",
    sortOrderAlboInsegnante: "desc",
  };
};
const state = getDefaultState();

const getters = {
  rowsToSkipAlboInsegnante: (state) => state.rowsToSkipAlboInsegnante,
  fetchRowsAlboInsegnante: (state) => state.fetchRowsAlboInsegnante,
  currentPageAlboInsegnante: (state) => state.currentPageAlboInsegnante,
  sortColumnAlboInsegnante: (state) => state.sortColumnAlboInsegnante,
  sortOrderAlboInsegnante: (state) => state.sortOrderAlboInsegnante,
};

// const actions = {};
const mutations = {
  setFetchRowsAlboInsegnante: (state, value) => {
    state.currentPageAlboInsegnante = 1;
    state.rowsToSkipAlboInsegnante = 0;
    state.fetchRowsAlboInsegnante = value;
  },
  setCurrentPageAlboInsegnante: (state, value) => {
    state.currentPageAlboInsegnante = value;
    state.rowsToSkipAlboInsegnante = parseInt(
      (state.currentPageAlboInsegnante - 1) * state.fetchRowsAlboInsegnante
    );
  },
  setSortColumnAlboInsegnante: (state, value) => {
    state.sortColumnAlboInsegnante = value;
  },
  setSortOrderAlboInsegnante: (state, value) => {
    state.sortOrderAlboInsegnante = value;
  },
  resetFiltersAlboInsegnante: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
