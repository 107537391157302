const getDefaultState = () => {
  return {
    tipologiaCorsi: null,
    specializzCorsi: null,
    denominazioneCorsi: null,
    pubblicatoCorsi: null,
    visibileOnlineCorsi: null,
    iscrizioneOnlineCorsi: null,
    rowsToSkipCorsi: 0,
    fetchRowsCorsi: 25,
    currentPageCorsi: 1,
    sortColumnCorsi: "id",
    sortOrderCorsi: "asc",
  };
};
const state = getDefaultState();

const getters = {
  tipologiaCorsi: (state) => state.tipologiaCorsi,
  specializzCorsi: (state) => state.specializzCorsi,
  denominazioneCorsi: (state) => state.denominazioneCorsi,
  pubblicatoCorsi: (state) => state.pubblicatoCorsi,
  visibileOnlineCorsi: (state) => state.visibileOnlineCorsi,
  iscrizioneOnlineCorsi: (state) => state.iscrizioneOnlineCorsi,
  rowsToSkipCorsi: (state) => state.rowsToSkipCorsi,
  fetchRowsCorsi: (state) => state.fetchRowsCorsi,
  currentPageCorsi: (state) => state.currentPageCorsi,
  sortColumnCorsi: (state) => state.sortColumnCorsi,
  sortOrderCorsi: (state) => state.sortOrderCorsi,
};

// const actions = {};
const mutations = {
  setTipologiaCorsi: (state, value) => {
    state.tipologiaCorsi = value;
  },
  setSpecializzCorsi: (state, value) => {
    state.specializzCorsi = value;
  },
  setDenominazioneCorsi: (state, value) => {
    state.denominazioneCorsi = value;
  },
  setPubblicatoCorsi: (state, value) => {
    state.pubblicatoCorsi = value;
  },
  setVisibileOnlineCorsi: (state, value) => {
    state.visibileOnlineCorsi = value;
  },
  setIscrizioneOnlineCorsi: (state, value) => {
    state.iscrizioneOnlineCorsi = value;
  },
  setFetchRowsCorsi: (state, value) => {
    state.currentPageCorsi = 1;
    state.rowsToSkipCorsi = 0;
    state.fetchRowsCorsi = value;
  },
  setCurrentPageCorsi: (state, value) => {
    state.currentPageCorsi = value;
    state.rowsToSkipCorsi = parseInt(
      (state.currentPageCorsi - 1) * state.fetchRowsCorsi
    );
  },
  setSortColumnCorsi: (state, value) => {
    state.sortColumnCorsi = value;
  },
  setSortOrderCorsi: (state, value) => {
    state.sortOrderCorsi = value;
  },
  resetFiltersCorsi: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipCorsi: (state) => {
    state.currentPageCorsi = 1;
    state.rowsToSkipCorsi = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
