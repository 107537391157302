const getDefaultState = () => {
  return {
    idTipoAttestatiAlbo: null,
    idCategoriaAttestatiAlbo: null,
    idQualificaAttestatiAlbo: null,
    codiceSnmAttestatiAlbo: null,
    dataAcqDaAttestatiAlbo: null,
    dataAcqAAttestatiAlbo: null,
    dataDecDaAttestatiAlbo: new Date("2024-01-01"),
    dataDecAAttestatiAlbo: null,
    dataFineDaAttestatiAlbo: null,
    dataFineAAttestatiAlbo: null,
    nomeAttestatiAlbo: null,
    cognomeAttestatiAlbo: null,
    idSocietaAttestatiAlbo: null,
    verificaAttestatiAlbo: null,
    statoAttestatiAlbo: null,
    statoUtenzaAttestatiAlbo: null,
    rowsToSkipAttestatiAlbo: 0,
    fetchRowsAttestatiAlbo: 25,
    currentPageAttestatiAlbo: 1,
    sortColumnAttestatiAlbo: "nominativo_completo",
    sortOrderAttestatiAlbo: "asc",
    societaDenominazioneAttestatiAlbo: null,
    statoAggiornamentoAttestatiAlbo: null,
    statoTesseramentoAttestatiAlbo: null,
    stagionePagamentoDaAttestatiAlbo: null,
    stagionePagamentoAAttestatiAlbo: null,
    pagamentiNonRegistratiAttestatiAlbo: null,
    idComitatoRegionaleAttestatiAlbo: null,
    id_personaAttestatiAlbo: null,
    nomePersonaAttestatiAlbo: null,

    idTipoAlboAttestatiAlbo: null,
    idCategoriaAlboAttestatiAlbo: null,
    idQualificaAlboAttestatiAlbo: null,
    id_inviatoAttestatiAlbo: null,
    id_scaricatoAttestatiAlbo: null,
    searchStatusAttestatiAlbo: false,
  };
};
const state = getDefaultState();

const getters = {
  searchStatusAttestatiAlbo: (state) => state.searchStatusAttestatiAlbo,
  id_inviatoAttestatiAlbo: (state) => state.id_inviatoAttestatiAlbo,
  id_scaricatoAttestatiAlbo: (state) => state.id_scaricatoAttestatiAlbo,
  idTipoAlboAttestatiAlbo: (state) => state.idTipoAlboAttestatiAlbo,
  idCategoriaAlboAttestatiAlbo: (state) => state.idCategoriaAlboAttestatiAlbo,
  idQualificaAlboAttestatiAlbo: (state) => state.idQualificaAlboAttestatiAlbo,

  nomePersonaAttestatiAlbo: (state) => state.nomePersonaAttestatiAlbo,
  id_personaAttestatiAlbo: (state) => state.id_personaAttestatiAlbo,
  idTipoAttestatiAlbo: (state) => state.idTipoAttestatiAlbo,
  idCategoriaAttestatiAlbo: (state) => state.idCategoriaAttestatiAlbo,
  idQualificaAttestatiAlbo: (state) => state.idQualificaAttestatiAlbo,
  codiceSnmAttestatiAlbo: (state) => state.codiceSnmAttestatiAlbo,
  dataAcqDaAttestatiAlbo: (state) => state.dataAcqDaAttestatiAlbo,
  dataAcqAAttestatiAlbo: (state) => state.dataAcqAAttestatiAlbo,
  dataDecDaAttestatiAlbo: (state) => state.dataDecDaAttestatiAlbo,
  dataDecAAttestatiAlbo: (state) => state.dataDecAAttestatiAlbo,
  dataFineDaAttestatiAlbo: (state) => state.dataFineDaAttestatiAlbo,
  dataFineAAttestatiAlbo: (state) => state.dataFineAAttestatiAlbo,
  nomeAttestatiAlbo: (state) => state.nomeAttestatiAlbo,
  cognomeAttestatiAlbo: (state) => state.cognomeAttestatiAlbo,
  idSocietaAttestatiAlbo: (state) => state.idSocietaAttestatiAlbo,
  verificaAttestatiAlbo: (state) => state.verificaAttestatiAlbo,
  statoAttestatiAlbo: (state) => state.statoAttestatiAlbo,
  statoUtenzaAttestatiAlbo: (state) => state.statoUtenzaAttestatiAlbo,
  societaDenominazioneAttestatiAlbo: (state) =>
    state.societaDenominazioneAttestatiAlbo,
  rowsToSkipAttestatiAlbo: (state) => state.rowsToSkipAttestatiAlbo,
  fetchRowsAttestatiAlbo: (state) => state.fetchRowsAttestatiAlbo,
  currentPageAttestatiAlbo: (state) => state.currentPageAttestatiAlbo,
  sortColumnAttestatiAlbo: (state) => state.sortColumnAttestatiAlbo,
  sortOrderAttestatiAlbo: (state) => state.sortOrderAttestatiAlbo,
  statoAggiornamentoAttestatiAlbo: (state) =>
    state.statoAggiornamentoAttestatiAlbo,
  statoTesseramentoAttestatiAlbo: (state) =>
    state.statoTesseramentoAttestatiAlbo,
  stagionePagamentoDaAttestatiAlbo: (state) =>
    state.stagionePagamentoDaAttestatiAlbo,
  stagionePagamentoAAttestatiAlbo: (state) =>
    state.stagionePagamentoAAttestatiAlbo,
  pagamentiNonRegistratiAttestatiAlbo: (state) =>
    state.pagamentiNonRegistratiAttestatiAlbo,
  idComitatoRegionaleAttestatiAlbo: (state) =>
    state.idComitatoRegionaleAttestatiAlbo,
};

// const actions = {};
const mutations = {
  setIdTipoAttestatiAlbo: (state, value) => {
    state.idTipoAttestatiAlbo = value;
  },
  setIdCategoriaAttestatiAlbo: (state, value) => {
    state.idCategoriaAttestatiAlbo = value;
  },
  setIdQualificaAttestatiAlbo: (state, value) => {
    state.idQualificaAttestatiAlbo = value;
  },
  setCodiceSnmAttestatiAlbo: (state, value) => {
    state.codiceSnmAttestatiAlbo = value;
  },
  setdataAcqDaAttestatiAlbo: (state, value) => {
    state.dataAcqDaAttestatiAlbo = value;
  },
  setdataAcqAAttestatiAlbo: (state, value) => {
    state.dataAcqAAttestatiAlbo = value;
  },
  setDataDecDaAttestatiAlbo: (state, value) => {
    state.dataDecDaAttestatiAlbo = value;
  },
  setDataDecAAttestatiAlbo: (state, value) => {
    state.dataDecAAttestatiAlbo = value;
  },
  setDataFineDaAttestatiAlbo: (state, value) => {
    state.dataFineDaAttestatiAlbo = value;
  },
  setDataFineAAttestatiAlbo: (state, value) => {
    state.dataFineAAttestatiAlbo = value;
  },
  setNomeAttestatiAlbo: (state, value) => {
    state.nomeAttestatiAlbo = value;
  },
  setCognomeAttestatiAlbo: (state, value) => {
    state.cognomeAttestatiAlbo = value;
  },
  setIdSocietaAttestatiAlbo: (state, value) => {
    state.idSocietaAttestatiAlbo = value;
  },
  setVerificaAttestatiAlbo: (state, value) => {
    state.verificaAttestatiAlbo = value;
  },
  setStatoAttestatiAlbo: (state, value) => {
    state.statoAttestatiAlbo = value;
  },
  setStatoUtenzaAttestatiAlbo: (state, value) => {
    state.statoUtenzaAttestatiAlbo = value;
  },
  setSocietaDenominazioneAttestatiAlbo: (state, value) => {
    state.societaDenominazioneAttestatiAlbo = value;
  },
  setStatoAggiornamentoAttestatiAlbo: (state, value) => {
    state.statoAggiornamentoAttestatiAlbo = value;
  },
  setStatoTesseramentoAttestatiAlbo: (state, value) => {
    state.statoTesseramentoAttestatiAlbo = value;
  },
  setstagionePagamentoDaAttestatiAlbo: (state, value) => {
    state.stagionePagamentoDaAttestatiAlbo = value;
  },
  setstagionePagamentoAAttestatiAlbo: (state, value) => {
    state.stagionePagamentoAAttestatiAlbo = value;
  },
  setpagamentiNonRegistratiAttestatiAlbo: (state, value) => {
    state.pagamentiNonRegistratiAttestatiAlbo = value;
  },
  setIdComitatoRegionaleAttestatiAlbo: (state, value) => {
    state.idComitatoRegionaleAttestatiAlbo = value;
  },
  setId_personaAttestatiAlbo: (state, value) => {
    state.id_personaAttestatiAlbo = value;
  },
  setNomepersonaAttestatiAlbo: (state, value) => {
    state.nomePersonaAttestatiAlbo = value;
  },
  setFetchRowsAttestatiAlbo: (state, value) => {
    state.currentPageAttestatiAlbo = 1;
    state.rowsToSkipAttestatiAlbo = 0;
    state.fetchRowsAttestatiAlbo = value;
  },
  setCurrentPageAttestatiAlbo: (state, value) => {
    state.currentPageAttestatiAlbo = value;
    state.rowsToSkipAttestatiAlbo = parseInt(
      (state.currentPageAttestatiAlbo - 1) * state.fetchRowsAttestatiAlbo
    );
  },
  setSortColumnAttestatiAlbo: (state, value) => {
    state.sortColumnAttestatiAlbo = value;
  },
  setSortOrderAttestatiAlbo: (state, value) => {
    state.sortOrderAttestatiAlbo = value;
  },
  resetFiltersAttestatiAlbo: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipAttestatiAlbo: (state) => {
    state.currentPageAttestatiAlbo = 1;
    state.rowsToSkipAttestatiAlbo = 0;
  },

  setIdCategoriaAlboAttestatiAlbo: (state, value) => {
    state.idCategoriaAlboAttestatiAlbo = value;
  },
  setIdTipoAlboAttestatiAlbo: (state, value) => {
    state.idTipoAlboAttestatiAlbo = value;
  },
  setIdQualificaAlboAttestatiAlbo: (state, value) => {
    state.idQualificaAlboAttestatiAlbo = value;
  },
  setIdInviatoAttestatiAlbo: (state, value) => {
    state.id_inviatoAttestatiAlbo = value;
  },
  setIdScaricatoAttestatiAlbo: (state, value) => {
    state.id_scaricatoAttestatiAlbo = value;
  },
  setSearchStatusAttestatiAlbo: (state) => {
    state.searchStatusAttestatiAlbo = true;
  },
};

export default {
  state,
  getters,
  mutations,
};
