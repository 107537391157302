enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
}
enum Payments {
  MIN_PER_PAGAMENTI_BONIFICO = 101,
  PAGAMENTO_CDC = "04",
  PAGAMENTO_MAV_LIGHT = "05",
  PAGAMENTO_BONIFICO = "01",
  PAGAMENTO_CDC_NEXI = "09",
}

enum CodiceEsito {
  APPROVED = 0, // Approvata/carta verificata
  ORDINE_NON_PRESENTE = 20, // Ordine non presente
  PARAMETRI_ERRATI_O_MANCANTI = 101, // Parametri errati o mancanti
  PAN_ERRATO = 102, // PAN errato - il pan specificato non può eseguire ulteriori autorizzazioni
  TRANSAZIONE_BLOCCATA_PROFILO = 103, // Transazione bloccata per annullamento in caso di notifica server to server verso l’urlpost fallita.
  ERRORE_GENERICO = 104, // Errore generico
  ORDINE_GIA_REGISTRATO = 108, // Ordine già registrato
  ERRORE_TECNICO = 109, // Errore tecnico
  NUMERO_CONTRATTO_GIA_PRESENTE = 110, // Numero contratto già presente
  MAC_ERRATO = 111, // Mac errato
  AUTENTICAZIONE_FALLITA = 112, // Transazione negata per autenticazione VBV/SC fallita o non possibile
  NUMERO_CONTRATTO_NON_PRESENTE = 113, // Numero contratto non presente in archivio
  MERCHANT_NON_ABILITATO_PAGAMENTO_MULTIPLO = 114, // Merchant non abilitato al pagamento multiplo sul gruppo
  CODICE_GRUPPO_NON_PRESENTE = 115, // Codice Gruppo non presente
  SECURE_ANNULLATO_DA_UTENTE = 116, // 3D Secure annullato da utente
  CARTA_NON_AUTORIZZATA = 117, // Carta non autorizzata causa applicazione regole BIN Table
  TRANSAZIONE_NON_AUTORIZZATA = 118, // Transazione non autorizzata per vari motivi
  ESERCENTE_NON_ABILITATO = 119, // Esercente non abilitato ad operare in questa modalità
  CIRCUITO_NON_ACCETTATO = 120, // Circuito non accettato
  TRANSAZIONE_CHIUSA_TIMEOUT = 121, // Transazione chiusa per timeout
  TENTATIVI_RETRY_ESURITI = 122, // Numero di tentativi di retry esauriti
  CARTA_NON_VALIDA = 129, // Carta non valida per l’addebito (scaduta o bloccata)
  AUTH_DENIED = 400, // Diniego generico, il buyer dovrebbe controllare i dati o contattare la banca
  EXPIRED_CARD = 401, // Scadenza non corretta
  RESTRICTED_CARD = 402, // Carta invalida, errata o chiusa
  INVALID_MERCHANT = 403, // Merchant non valido, contattare il supporto
  TRANSAZIONE_NON_PERMESSA = 404, // Transazione non permessa dall'emittente della carta
  INSUFFICIENT_FUNDS = 405, // Mancanza fondi
  TECHNICAL_PROBLEM = 406, // Problema tecnico sui sistemi autorizzativi
  HOST_NOT_FOUND = 407, // Impossibile contattare la banca emittente
  SOFT_DECLINE = 408, // Soft Decline, risottomettere con autenticazione
  SUSPECTED_FRAUD = 409, // Sospetto di frode
  PIN_TRIES_EXCEEDED = 410, // Superato il numero massimo di tentativi PIN
  REFER_TO_CARD_ISSUER = 411, // L'acquirente deve contattare la banca
  EXPIRED_LOST_STOLEN_CARD = 412, // La carta potrebbe essere smarrita o contraffata
  EXCESS_REATTEMPTS = 413, // Eccesso di tentativi di ri-sottomissione
  LIMITE_SPESA_SUPERATO = 414, // Superamento dei limiti di spesa giornalieri
}

export { Actions, Mutations, Payments, CodiceEsito };
