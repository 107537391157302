const getDefaultState = () => {
  return {
    // anno: 0,
    data_ultimo_invio_raas_didattica: null, // "2023-01-02T13:35:10.715Z",
    denominazione_raas_didattica: null, //"string",
    codice_fiscale_raas_didattica: null, //"string",
    codice_affiliazione_raas_didattica: null, //"string",
    id_comitato_raas_didattica: null, //0,
    stati_invio_raas_didattica: null, //"string",
    rowtoskip_raas_didattica: 0, //0,
    fetchrows_raas_didattica: 25, //0,
    sortcolumn_raas_didattica: "data_inizio", //"string",
    sortorder_raas_didattica: "desc", //"string",
    currentPage_raas_didattica: 1,
  };
};
const state = getDefaultState();

const getters = {
  //   anno: (state) => state.disciplinaRanking,
  data_ultimo_invio_raas_didattica: (state) =>
    state.data_ultimo_invio_raas_didattica,
  denominazione_raas_didattica: (state) => state.denominazione_raas_didattica,
  codice_fiscale_raas_didattica: (state) => state.codice_fiscale_raas_didattica,
  codice_affiliazione_raas_didattica: (state) =>
    state.codice_affiliazione_raas_didattica,
  id_comitato_raas_didattica: (state) => state.id_comitato_raas_didattica,
  stati_invio_raas_didattica: (state) => state.stati_invio_raas_didattica,
  rowtoskip_raas_didattica: (state) => state.rowtoskip_raas_didattica,
  fetchrows_raas_didattica: (state) => state.fetchrows_raas_didattica,
  sortcolumn_raas_didattica: (state) => state.sortcolumn_raas_didattica,
  sortorder_raas_didattica: (state) => state.sortorder_raas_didattica,
  currentPage_raas_didattica: (state) => state.currentPage_raas_didattica,
};

const actions = {
  setInviateDidatticaRaas: (context) => {
    context.commit("setStati_invio_raas_didattica", "2");
  },
  setDaInviareDidatticaRaas: (context) => {
    context.commit("setStati_invio_raas_didattica", "1");
  },
  setErroriDidatticaRaas: (context) => {
    context.commit("setStati_invio_raas_didattica", "3");
  },
  setDaReinviareDidatticaRaas: (context) => {
    context.commit("setStati_invio_raas_didattica", "4");
  },
};
const mutations = {
  setData_ultimo_invio_raas_didattica: (state, value) => {
    state.data_ultimo_invio_raas_didattica = value;
  },
  setDenominazione_raas_didattica: (state, value) => {
    state.denominazione_raas_didattica = value;
  },
  setCodice_fiscale_raas_didattica: (state, value) => {
    state.codice_fiscale_raas_didattica = value;
  },
  setCodice_affiliazione_raas_didattica: (state, value) => {
    state.codice_affiliazione_raas_didattica = value;
  },
  setId_comitato_raas_didattica: (state, value) => {
    state.id_comitato_raas_didattica = value;
  },
  setStati_invio_raas_didattica: (state, value) => {
    if (
      state.stati_invio_raas_didattica &&
      state.stati_invio_raas_didattica.includes(value)
    ) {
      if (state.stati_invio_raas_didattica.includes(`,${value}`))
        state.stati_invio_raas_didattica =
          state.stati_invio_raas_didattica.replace(`,${value}`, "");
      else if (state.stati_invio_raas_didattica.includes(`${value},`))
        state.stati_invio_raas_didattica =
          state.stati_invio_raas_didattica.replace(`${value},`, "");
      else
        state.stati_invio_raas_didattica =
          state.stati_invio_raas_didattica.replace(`${value}`, "");
    } else {
      state.stati_invio_raas_didattica = `${
        state.stati_invio_raas_didattica
          ? state.stati_invio_raas_didattica + ","
          : ""
      }${value}`;
    }
    if (state.stati_invio_raas_didattica === "")
      state.stati_invio_raas_didattica = null;
  },
  //   setInviateDidatticaRaas: (state, value) => {
  //     state.stati_invio_raas_didattica =  `${state.stati_invio_raas_didattica},${value}`;
  //   },

  setFetchRowsDidatticaRaas: (state, value) => {
    state.currentPage_raas_didattica = 1;
    state.rowtoskip_raas_didattica = 0;
    state.fetchrows_raas_didattica = value;
  },
  setCurrentPageDidatticaRaas: (state, value) => {
    state.currentPage_raas_didattica = value;
    state.rowtoskip_raas_didattica = parseInt(
      (state.currentPage_raas_didattica - 1) * state.fetchrows_raas_didattica
    );
  },
  setSortColumnDidatticaRaas: (state, value) => {
    state.sortcolumn_raas_didattica = value;
  },
  setSortOrderDidatticaRaas: (state, value) => {
    state.sortorder_raas_didattica = value;
  },
  resetFiltersDidatticaRaas: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipDidatticaRaas: (state) => {
    state.currentPage_raas_didattica = 1;
    state.rowtoskip_raas_didattica = 0;
  },
  //   setTutteLeStagioniRanking: (state) => {
  //     state.tutteStagioniRanking = !state.tutteStagioniRanking;
  //     state.currentPageRanking = 1;
  //     state.rowsToSkipRanking = 0;
  //   },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
