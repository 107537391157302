const getDefaultState = () => {
  return {
    idTipoAttestatiQualifiche: null,
    idCategoriaAttestatiQualifiche: null,
    idQualificaAttestatiQualifiche: null,
    codiceSnmAttestatiQualifiche: null,
    dataAcqDaAttestatiQualifiche: null,
    dataAcqAAttestatiQualifiche: null,
    dataDecDaAttestatiQualifiche: new Date("2024-01-01"),
    dataDecAAttestatiQualifiche: null,
    dataFineDaAttestatiQualifiche: null,
    dataFineAAttestatiQualifiche: null,
    nomeAttestatiQualifiche: null,
    cognomeAttestatiQualifiche: null,
    idSocietaAttestatiQualifiche: null,
    verificaAttestatiQualifiche: null,
    statoAttestatiQualifiche: null,
    statoUtenzaAttestatiQualifiche: null,
    rowsToSkipAttestatiQualifiche: 0,
    fetchRowsAttestatiQualifiche: 25,
    currentPageAttestatiQualifiche: 1,
    sortColumnAttestatiQualifiche: "nominativo_completo",
    sortOrderAttestatiQualifiche: "asc",
    societaDenominazioneAttestatiQualifiche: null,
    statoAggiornamentoAttestatiQualifiche: null,
    statoTesseramentoAttestatiQualifiche: null,
    stagionePagamentoDaAttestatiQualifiche: null,
    stagionePagamentoAAttestatiQualifiche: null,
    pagamentiNonRegistratiAttestatiQualifiche: null,
    idComitatoRegionaleAttestatiQualifiche: null,
    id_personaAttestatiQualifiche: null,
    nomePersonaAttestatiQualifiche: null,
    tipo_qualificaAttestatiQualifiche: null,

    idTipoAlboAttestatiQualifiche: null,
    idCategoriaAlboAttestatiQualifiche: null,
    idQualificaAlboAttestatiQualifiche: null,
    id_inviatoAttestatiQualifiche: null,
    id_scaricatoAttestatiQualifiche: null,
    searchStatusAttestatiQualifiche: false,
  };
};
const state = getDefaultState();

const getters = {
  searchStatusAttestatiQualifiche: (state) =>
    state.searchStatusAttestatiQualifiche,
  id_inviatoAttestatiQualifiche: (state) => state.id_inviatoAttestatiQualifiche,
  id_scaricatoAttestatiQualifiche: (state) =>
    state.id_scaricatoAttestatiQualifiche,
  idTipoAlboAttestatiQualifiche: (state) => state.idTipoAlboAttestatiQualifiche,
  idCategoriaAlboAttestatiQualifiche: (state) =>
    state.idCategoriaAlboAttestatiQualifiche,
  idQualificaAlboAttestatiQualifiche: (state) =>
    state.idQualificaAlboAttestatiQualifiche,

  nomePersonaAttestatiQualifiche: (state) =>
    state.nomePersonaAttestatiQualifiche,
  id_personaAttestatiQualifiche: (state) => state.id_personaAttestatiQualifiche,
  idTipoAttestatiQualifiche: (state) => state.idTipoAttestatiQualifiche,
  idCategoriaAttestatiQualifiche: (state) =>
    state.idCategoriaAttestatiQualifiche,
  idQualificaAttestatiQualifiche: (state) =>
    state.idQualificaAttestatiQualifiche,
  codiceSnmAttestatiQualifiche: (state) => state.codiceSnmAttestatiQualifiche,
  dataAcqDaAttestatiQualifiche: (state) => state.dataAcqDaAttestatiQualifiche,
  dataAcqAAttestatiQualifiche: (state) => state.dataAcqAAttestatiQualifiche,
  dataDecDaAttestatiQualifiche: (state) => state.dataDecDaAttestatiQualifiche,
  dataDecAAttestatiQualifiche: (state) => state.dataDecAAttestatiQualifiche,
  dataFineDaAttestatiQualifiche: (state) => state.dataFineDaAttestatiQualifiche,
  dataFineAAttestatiQualifiche: (state) => state.dataFineAAttestatiQualifiche,
  nomeAttestatiQualifiche: (state) => state.nomeAttestatiQualifiche,
  cognomeAttestatiQualifiche: (state) => state.cognomeAttestatiQualifiche,
  idSocietaAttestatiQualifiche: (state) => state.idSocietaAttestatiQualifiche,
  verificaAttestatiQualifiche: (state) => state.verificaAttestatiQualifiche,
  statoAttestatiQualifiche: (state) => state.statoAttestatiQualifiche,
  statoUtenzaAttestatiQualifiche: (state) =>
    state.statoUtenzaAttestatiQualifiche,
  societaDenominazioneAttestatiQualifiche: (state) =>
    state.societaDenominazioneAttestatiQualifiche,
  rowsToSkipAttestatiQualifiche: (state) => state.rowsToSkipAttestatiQualifiche,
  fetchRowsAttestatiQualifiche: (state) => state.fetchRowsAttestatiQualifiche,
  currentPageAttestatiQualifiche: (state) =>
    state.currentPageAttestatiQualifiche,
  sortColumnAttestatiQualifiche: (state) => state.sortColumnAttestatiQualifiche,
  sortOrderAttestatiQualifiche: (state) => state.sortOrderAttestatiQualifiche,
  statoAggiornamentoAttestatiQualifiche: (state) =>
    state.statoAggiornamentoAttestatiQualifiche,
  statoTesseramentoAttestatiQualifiche: (state) =>
    state.statoTesseramentoAttestatiQualifiche,
  stagionePagamentoDaAttestatiQualifiche: (state) =>
    state.stagionePagamentoDaAttestatiQualifiche,
  stagionePagamentoAAttestatiQualifiche: (state) =>
    state.stagionePagamentoAAttestatiQualifiche,
  pagamentiNonRegistratiAttestatiQualifiche: (state) =>
    state.pagamentiNonRegistratiAttestatiQualifiche,
  idComitatoRegionaleAttestatiQualifiche: (state) =>
    state.idComitatoRegionaleAttestatiQualifiche,
  tipo_qualificaAttestatiQualifiche: (state) =>
    state.tipo_qualificaAttestatiQualifiche,
};

// const actions = {};
const mutations = {
  setIdTipoAttestatiQualifiche: (state, value) => {
    state.idTipoAttestatiQualifiche = value;
  },
  setIdCategoriaAttestatiQualifiche: (state, value) => {
    state.idCategoriaAttestatiQualifiche = value;
  },
  setIdQualificaAttestatiQualifiche: (state, value) => {
    state.idQualificaAttestatiQualifiche = value;
  },
  setCodiceSnmAttestatiQualifiche: (state, value) => {
    state.codiceSnmAttestatiQualifiche = value;
  },
  setdataAcqDaAttestatiQualifiche: (state, value) => {
    state.dataAcqDaAttestatiQualifiche = value;
  },
  setdataAcqAAttestatiQualifiche: (state, value) => {
    state.dataAcqAAttestatiQualifiche = value;
  },
  setDataDecDaAttestatiQualifiche: (state, value) => {
    state.dataDecDaAttestatiQualifiche = value;
  },
  setDataDecAAttestatiQualifiche: (state, value) => {
    state.dataDecAAttestatiQualifiche = value;
  },
  setDataFineDaAttestatiQualifiche: (state, value) => {
    state.dataFineDaAttestatiQualifiche = value;
  },
  setDataFineAAttestatiQualifiche: (state, value) => {
    state.dataFineAAttestatiQualifiche = value;
  },
  setNomeAttestatiQualifiche: (state, value) => {
    state.nomeAttestatiQualifiche = value;
  },
  setCognomeAttestatiQualifiche: (state, value) => {
    state.cognomeAttestatiQualifiche = value;
  },
  setIdSocietaAttestatiQualifiche: (state, value) => {
    state.idSocietaAttestatiQualifiche = value;
  },
  setVerificaAttestatiQualifiche: (state, value) => {
    state.verificaAttestatiQualifiche = value;
  },
  setStatoAttestatiQualifiche: (state, value) => {
    state.statoAttestatiQualifiche = value;
  },
  setStatoUtenzaAttestatiQualifiche: (state, value) => {
    state.statoUtenzaAttestatiQualifiche = value;
  },
  setSocietaDenominazioneAttestatiQualifiche: (state, value) => {
    state.societaDenominazioneAttestatiQualifiche = value;
  },
  setStatoAggiornamentoAttestatiQualifiche: (state, value) => {
    state.statoAggiornamentoAttestatiQualifiche = value;
  },
  setStatoTesseramentoAttestatiQualifiche: (state, value) => {
    state.statoTesseramentoAttestatiQualifiche = value;
  },
  setstagionePagamentoDaAttestatiQualifiche: (state, value) => {
    state.stagionePagamentoDaAttestatiQualifiche = value;
  },
  setstagionePagamentoAAttestatiQualifiche: (state, value) => {
    state.stagionePagamentoAAttestatiQualifiche = value;
  },
  setpagamentiNonRegistratiAttestatiQualifiche: (state, value) => {
    state.pagamentiNonRegistratiAttestatiQualifiche = value;
  },
  setIdComitatoRegionaleAttestatiQualifiche: (state, value) => {
    state.idComitatoRegionaleAttestatiQualifiche = value;
  },
  setId_personaAttestatiQualifiche: (state, value) => {
    state.id_personaAttestatiQualifiche = value;
  },
  setNomepersonaAttestatiQualifiche: (state, value) => {
    state.nomePersonaAttestatiQualifiche = value;
  },
  setFetchRowsAttestatiQualifiche: (state, value) => {
    state.currentPageAttestatiQualifiche = 1;
    state.rowsToSkipAttestatiQualifiche = 0;
    state.fetchRowsAttestatiQualifiche = value;
  },
  setCurrentPageAttestatiQualifiche: (state, value) => {
    state.currentPageAttestatiQualifiche = value;
    state.rowsToSkipAttestatiQualifiche = parseInt(
      (state.currentPageAttestatiQualifiche - 1) *
        state.fetchRowsAttestatiQualifiche
    );
  },
  setSortColumnAttestatiQualifiche: (state, value) => {
    state.sortColumnAttestatiQualifiche = value;
  },
  setSortOrderAttestatiQualifiche: (state, value) => {
    state.sortOrderAttestatiQualifiche = value;
  },
  resetFiltersAttestatiQualifiche: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipAttestatiQualifiche: (state) => {
    state.currentPageAttestatiQualifiche = 1;
    state.rowsToSkipAttestatiQualifiche = 0;
  },

  setIdCategoriaAlboAttestatiQualifiche: (state, value) => {
    state.idCategoriaAlboAttestatiQualifiche = value;
  },
  setIdTipoAlboAttestatiQualifiche: (state, value) => {
    state.idTipoAlboAttestatiQualifiche = value;
  },
  setIdQualificaAlboAttestatiQualifiche: (state, value) => {
    state.idQualificaAlboAttestatiQualifiche = value;
  },
  setIdInviatoAttestatiQualifiche: (state, value) => {
    state.id_inviatoAttestatiQualifiche = value;
  },
  setIdScaricatoAttestatiQualifiche: (state, value) => {
    state.id_scaricatoAttestatiQualifiche = value;
  },
  setSearchStatusAttestatiQualifiche: (state) => {
    state.searchStatusAttestatiQualifiche = true;
  },
  settipo_qualificaAttestatiQualifiche: (state, value) => {
    state.tipo_qualificaAttestatiQualifiche = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
