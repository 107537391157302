const getDefaultState = () => {
  return {
    dataDaDocSoc: null,
    dataADocSoc: null,
    idTipoDocSoc: null,
    permessiDocSoc: null,
    rowsToSkipDocSoc: 0,
    fetchRowsDocSoc: 25,
    currentPageDocSoc: 1,
    sortColumnDocSoc: "data",
    sortOrderDocSoc: "desc",
  };
};
const state = getDefaultState();

const getters = {
  dataDaDocSoc: (state) => state.dataDaDocSoc,
  dataADocSoc: (state) => state.dataADocSoc,
  idTipoDocSoc: (state) => state.idTipoDocSoc,
  permessiDocSoc: (state) => state.permessiDocSoc,
  rowsToSkipDocSoc: (state) => state.rowsToSkipDocSoc,
  fetchRowsDocSoc: (state) => state.fetchRowsDocSoc,
  currentPageDocSoc: (state) => state.currentPageDocSoc,
  sortColumnDocSoc: (state) => state.sortColumnDocSoc,
  sortOrderDocSoc: (state) => state.sortOrderDocSoc,
};

// const actions = {};
const mutations = {
  setDataDaDocSoc: (state, value) => {
    state.dataDaDocSoc = value;
  },
  setDataADocSoc: (state, value) => {
    state.dataADocSoc = value;
  },
  setIdTipoDocSoc: (state, value) => {
    state.idTipoDocSoc = value;
  },
  setPermessiDocSoc: (state, value) => {
    state.permessiDocSoc = value;
  },
  setFetchRowsDocSoc: (state, value) => {
    state.currentPageDocSoc = 1;
    state.rowsToSkipDocSoc = 0;
    state.fetchRowsDocSoc = value;
  },
  setCurrentPageDocSoc: (state, value) => {
    state.currentPageDocSoc = value;
    state.rowsToSkipDocSoc = parseInt(
      (state.currentPageDocSoc - 1) * state.fetchRowsDocSoc
    );
  },
  setSortColumnDocSoc: (state, value) => {
    state.sortColumnDocSoc = value;
  },
  setSortOrderDocSoc: (state, value) => {
    state.sortOrderDocSoc = value;
  },
  resetFiltersDocSoc: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipDocSoc: (state) => {
    state.currentPageDocSoc = 1;
    state.rowsToSkipDocSoc = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
