const getDefaultState = () => {
  return {
    cognomeTesserato: null,
    inizioTesseramentoTesserato: null,
    fineTesseramentoTesserato: null,
    codiceTesseraTesserato: null,
    certificatoTesserato: null,
    id_tipo_tesseraTesserato: null,
    id_disciplinaTesserato: null,
    rowsToSkipTesserato: 0,
    fetchRowsTesserato: 25,
    currentPageTesserato: 1,
    sortColumnTesserato: "cognome",
    sortOrderTesserato: "asc",
    id_categoriaTesserato: null,
  };
};
const state = getDefaultState();

const getters = {
  cognomeTesserato: (state) => state.cognomeTesserato,
  inizioTesseramentoTesserato: (state) => state.inizioTesseramentoTesserato,
  fineTesseramentoTesserato: (state) => state.fineTesseramentoTesserato,
  codiceTesseraTesserato: (state) => state.codiceTesseraTesserato,
  certificatoTesserato: (state) => state.certificatoTesserato,
  id_tipo_tesseraTesserato: (state) => state.id_tipo_tesseraTesserato,
  id_disciplinaTesserato: (state) => state.id_disciplinaTesserato,
  id_qualificaTesserato: (state) => state.id_qualificaTesserato,
  rowsToSkipTesserato: (state) => state.rowsToSkipTesserato,
  fetchRowsTesserato: (state) => state.fetchRowsTesserato,
  currentPageTesserato: (state) => state.currentPageTesserato,
  sortColumnTesserato: (state) => state.sortColumnTesserato,
  sortOrderTesserato: (state) => state.sortOrderTesserato,
  id_categoriaTesserato: (state) => state.id_categoriaTesserato,
};

// const actions = {};
const mutations = {
  setCognomeTesserato: (state, value) => {
    state.cognomeTesserato = value;
  },
  setInizioTesseramento: (state, value) => {
    state.inizioTesseramentoTesserato = value;
  },
  setFineTesseramento: (state, value) => {
    state.fineTesseramentoTesserato = value;
  },
  setCodiceTessera: (state, value) => {
    state.codiceTesseraTesserato = value;
  },
  setCertificato: (state, value) => {
    state.certificatoTesserato = value;
  },
  setTipoTessera: (state, value) => {
    state.id_tipo_tesseraTesserato = value;
  },
  setSettore: (state, value) => {
    state.id_disciplinaTesserato = value;
  },
  setFetchRowsTesserato: (state, value) => {
    state.currentPageTesserato = 1;
    state.rowsToSkipTesserato = 0;
    state.fetchRowsTesserato = value;
  },
  setCurrentPageTesserato: (state, value) => {
    state.currentPageTesserato = value;
    state.rowsToSkipTesserato = parseInt(
      (state.currentPageTesserato - 1) * state.fetchRowsTesserato
    );
  },
  setSortColumnTesserato: (state, value) => {
    state.sortColumnTesserato = value;
  },
  setSortOrderTesserato: (state, value) => {
    state.sortOrderTesserato = value;
  },
  setCategoriaTessera: (state, value) => {
    state.id_categoriaTesserato = value;
  },
  resetFiltersTesserato: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipTesserato: (state) => {
    state.currentPageTesserato = 1;
    state.rowsToSkipTesserato = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
