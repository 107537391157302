const getDefaultStateTessereSpeciali = () => {
  return {
    stagioneEconomatoTessereSpeciali: null,
    idComitatoRegionaleTessereSpeciali: null,
    dataEmissioneDaTessereSpeciali: null,
    dataEmissioneATessereSpeciali: null,
    dataApprovazioneDaTessereSpeciali: null,
    dataApprovazioneATessereSpeciali: null,
    idTesseratoTessereSpeciali: null,
    nomeTesseratoTessereSpeciali: "",
    rowsToSkipTessereSpeciali: 0,
    fetchRowsTessereSpeciali: 25,
    currentPageTessereSpeciali: 1,
    sortColumnTessereSpeciali: "numero",
    sortOrderTessereSpeciali: "desc",
  };
};
const state = getDefaultStateTessereSpeciali();

const getters = {
  stagioneEconomatoTessereSpeciali: (state) =>
    state.stagioneEconomatoTessereSpeciali,
  idComitatoRegionaleTessereSpeciali: (state) =>
    state.idComitatoRegionaleTessereSpeciali,
  dataEmissioneDaTessereSpeciali: (state) =>
    state.dataEmissioneDaTessereSpeciali,
  dataEmissioneATessereSpeciali: (state) => state.dataEmissioneATessereSpeciali,
  dataApprovazioneDaTessereSpeciali: (state) =>
    state.dataApprovazioneDaTessereSpeciali,
  dataApprovazioneATessereSpeciali: (state) =>
    state.dataApprovazioneATessereSpeciali,
  idTesseratoTessereSpeciali: (state) => state.idTesseratoTessereSpeciali,
  nomeTesseratoTessereSpeciali: (state) => state.nomeTesseratoTessereSpeciali,
  rowsToSkipTessereSpeciali: (state) => state.rowsToSkipTessereSpeciali,
  fetchRowsTessereSpeciali: (state) => state.fetchRowsTessereSpeciali,
  currentPageTessereSpeciali: (state) => state.currentPageTessereSpeciali,
  sortColumnTessereSpeciali: (state) => state.sortColumnTessereSpeciali,
  sortOrderTessereSpeciali: (state) => state.sortOrderTessereSpeciali,
};

// const actions = {};
const mutations = {
  setNomeTesseratoTessereSpeciali: (state, value) => {
    state.nomeTesseratoTessereSpeciali = value ? value : null;
  },
  setIdComitatoRegionaleTessereSpeciali: (state, value) => {
    state.idComitatoRegionaleTessereSpeciali = value;
  },
  setDataEmissioneDaTessereSpeciali: (state, value) => {
    state.dataEmissioneDaTessereSpeciali = value;
  },
  setDataEmissioneATessereSpeciali: (state, value) => {
    state.dataEmissioneATessereSpeciali = value;
  },
  setDataApprovazioneDaTessereSpeciali: (state, value) => {
    state.dataApprovazioneDaTessereSpeciali = value;
  },
  setDataApprovazioneATessereSpeciali: (state, value) => {
    state.dataApprovazioneATessereSpeciali = value;
  },
  setTesseratoTessereSpeciali: (state, value) => {
    state.idTesseratoTessereSpeciali = value ? value.value : null;
    state.nomeTesseratoTessereSpeciali = value ? value.name : null;
  },
  setFetchRowsTessereSpeciali: (state, value) => {
    state.currentPageTessereSpeciali = 1;
    state.rowsToSkipTessereSpeciali = 0;
    state.fetchRowsTessereSpeciali = value;
  },
  setCurrentPageTessereSpeciali: (state, value) => {
    state.currentPageTessereSpeciali = value;
    state.rowsToSkipTessereSpeciali = parseInt(
      (state.currentPageTessereSpeciali - 1) * state.fetchRowsTessereSpeciali
    );
  },
  setSortColumnTessereSpeciali: (state, value) => {
    state.sortColumnTessereSpeciali = value;
  },
  setSortOrderTessereSpeciali: (state, value) => {
    state.sortOrderTessereSpeciali = value;
  },
  setCategoriaTesseraTessereSpeciali: (state, value) => {
    state.id_categoriaTessereSpeciali = value;
  },
  resetFiltersTessereSpeciali: (state) => {
    Object.assign(state, getDefaultStateTessereSpeciali());
  },
  resetRowsToSkipTessereSpeciali: (state) => {
    state.currentPageTessereSpeciali = 1;
    state.rowsToSkipTessereSpeciali = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
