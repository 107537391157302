import { Login } from "@/requests/oldLogin";

const getDefaultState = () => {
  return {
    credentials: {},
    errorStatusLogin: 0,
    errorStatusEmail: 0,
    errorMessageLoginFinto: null,
    oldEmail: "",
  };
};

const state = getDefaultState();

const getters = {
  oldEmail: (state) => state.oldEmail,
  credentials: (state) => state.credentials,
  errorStatusLogin: (state) => state.errorStatusLogin,
  errorMessageLoginFinto: (state) => state.errorMessageLoginFinto,
  errorStatusEmail: (state) => state.errorStatusEmail,
};
const actions = {
  oldLogin: async (stateObj, credentials) => {
    stateObj.commit("setErrorEmail", 200);
    stateObj.commit("setErrorMessage", "");
    await Login(credentials).then((res) => {
      if (res.status === 200) {
        stateObj.commit("setErrorEmail", res.status);
        stateObj.commit("setCredentials", { credentials, res: res.data });
      } else {
        stateObj.commit("setErrorLogin", res);
      }
    });
  },
  EmailChange: async (stateObj, { mail }) => {
    // stateObj.commit("setErrorEmail", 0);
    stateObj.commit("setEmailInCredentials", mail);
    stateObj.commit("setErrorEmail", 200);
    stateObj.commit("setErrorMessage", "");
    await Login(stateObj.state.credentials).then((res) => {
      if (res.status === 200) {
        stateObj.commit("setErrorEmail", res.status);
      } else {
        stateObj.commit("setErrorEmail", res.data.statusCode);
        stateObj.commit("setErrorMessage", res.data.message);
      }
    });
  },
};
const mutations = {
  setCredentials: (state, { credentials, res }) => {
    state.credentials = { ...credentials, ...res };
    state.oldEmail = res.mail;
  },
  setEmailInCredentials: (state, mail) => {
    state.credentials = { ...state.credentials, ...mail };
  },
  setErrorLogin: (state, res) => {
    state.errorStatusLogin = res.status;
    state.errorMessageLoginFinto = res.data.message;
  },
  setErrorEmail: (state, status) => {
    state.errorStatusEmail = status;
  },
  setErrorMessage: (state, msg) => {
    state.errorMessageLoginFinto = msg;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
