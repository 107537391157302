const getDefaultState = () => {
  return {
    numberOfAjaxCAllPending: 0,
  };
};
const state = getDefaultState();
const getters = {
  numberOfAjaxCAllPending: (state) => state.numberOfAjaxCAllPending,
};

export default {
  state,
  getters,
  // mutations,
};
