import services from "../axios/dbManag";
export const checkTokenFromFCH = (token) => {
  services.dbManagControlloTokenFCH.interceptors.request.use((config) => {
    config.headers["Authorization"] = "bearer " + token;
    return config;
  });
  return services.dbManagControlloTokenFCH
    .post("/login")
    .then((res) => {
      return res.status;
    })
    .catch(async (e) => {
      return e.response.status;
    });
};
