const getDefaultState = () => {
  return {
    causaleEconomatoEnte: null,
    tipoEconomatoEnte: null,
    descrizioneEconomatoEnte: null,
    statoApprovazioneEconomatoEnte: null,
    dataRegistDaEconomatoEnte: null,
    dataRegistAEconomatoEnte: null,
    dataApprovDaEconomatoEnte: null,
    dataApprovAEconomatoEnte: null,
    rowsToSkipEconomatoEnte: 0,
    fetchRowsEconomatoEnte: 25,
    currentPageEconomatoEnte: 1,
    sortColumnEconomatoEnte: "numero",
    sortOrderEconomatoEnte: "desc",
  };
};
const state = getDefaultState();

const getters = {
  causaleEconomatoEnte: (state) => state.causaleEconomatoEnte,
  tipoEconomatoEnte: (state) => state.tipoEconomatoEnte,
  descrizioneEconomatoEnte: (state) => state.descrizioneEconomatoEnte,
  statoApprovazioneEconomatoEnte: (state) =>
    state.statoApprovazioneEconomatoEnte,
  dataRegistDaEconomatoEnte: (state) => state.dataRegistDaEconomatoEnte,
  dataRegistAEconomatoEnte: (state) => state.dataRegistAEconomatoEnte,
  dataApprovDaEconomatoEnte: (state) => state.dataApprovDaEconomatoEnte,
  dataApprovAEconomatoEnte: (state) => state.dataApprovAEconomatoEnte,
  rowsToSkipEconomatoEnte: (state) => state.rowsToSkipEconomatoEnte,
  fetchRowsEconomatoEnte: (state) => state.fetchRowsEconomatoEnte,
  currentPageEconomatoEnte: (state) => state.currentPageEconomatoEnte,
  sortColumnEconomatoEnte: (state) => state.sortColumnEconomatoEnte,
  sortOrderEconomatoEnte: (state) => state.sortOrderEconomatoEnte,
};

// const actions = {};
const mutations = {
  setCausaleEconomatoEnte: (state, value) => {
    state.causaleEconomatoEnte = value;
  },
  setTipoEconomatoEnte: (state, value) => {
    state.tipoEconomatoEnte = value;
  },
  setDescrizioneEconomatoEnte: (state, value) => {
    state.descrizioneEconomatoEnte = value;
  },
  setStatoApprovazioneEconomatoEnte: (state, value) => {
    state.statoApprovazioneEconomatoEnte = value;
  },
  setDataRegistDaEconomatoEnte: (state, value) => {
    state.dataRegistDaEconomatoEnte = value;
  },
  setDataRegistAEconomatoEnte: (state, value) => {
    state.dataRegistAEconomatoEnte = value;
  },
  setDataApprovDaEconomatoEnte: (state, value) => {
    state.dataApprovDaEconomatoEnte = value;
  },
  setDataApprovAEconomatoEnte: (state, value) => {
    state.dataApprovAEconomatoEnte = value;
  },
  setFetchRowsEconomatoEnte: (state, value) => {
    state.currentPageEconomatoEnte = 1;
    state.rowsToSkipEconomatoEnte = 0;
    state.fetchRowsEconomatoEnte = value;
  },
  setCurrentPageEconomatoEnte: (state, value) => {
    state.currentPageEconomatoEnte = value;
    state.rowsToSkipEconomatoEnte = parseInt(
      (state.currentPageEconomatoEnte - 1) * state.fetchRowsEconomatoEnte
    );
  },
  setSortColumnEconomatoEnte: (state, value) => {
    state.sortColumnEconomatoEnte = value;
  },
  setSortOrderEconomatoEnte: (state, value) => {
    state.sortOrderEconomatoEnte = value;
  },
  resetFiltersEconomatoEnte: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipEconomatoEnte: (state) => {
    state.currentPageEconomatoEnte = 1;
    state.rowsToSkipEconomatoEnte = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
