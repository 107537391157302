const getDefaultState = () => {
  return {
    dataDaReportPretesseramento: null,
    dataAReportPretesseramento: null,
    rowsToSkipReportPretesseramento: 0,
    fetchRowsReportPretesseramento: 25,
    currentPageReportPretesseramento: 1,
    sortColumnReportPretesseramento: "data_richiesta",
    sortOrderReportPretesseramento: "desc",
  };
};
const state = getDefaultState();

const getters = {
  dataDaReportPretesseramento: (state) => state.dataDaReportPretesseramento,
  dataAReportPretesseramento: (state) => state.dataAReportPretesseramento,
  rowsToSkipReportPretesseramento: (state) =>
    state.rowsToSkipReportPretesseramento,
  fetchRowsReportPretesseramento: (state) =>
    state.fetchRowsReportPretesseramento,
  currentPageReportPretesseramento: (state) =>
    state.currentPageReportPretesseramento,
  sortColumnReportPretesseramento: (state) =>
    state.sortColumnReportPretesseramento,
  sortOrderReportPretesseramento: (state) =>
    state.sortOrderReportPretesseramento,
};

// const actions = {};
const mutations = {
  setdataDaReportPretesseramento: (state, value) => {
    state.dataDaReportPretesseramento = value;
  },
  setdataAReportPretesseramento: (state, value) => {
    state.dataAReportPretesseramento = value;
  },
  setFetchRowsReportPretesseramento: (state, value) => {
    state.currentPageReportPretesseramento = 1;
    state.rowsToSkipReportPretesseramento = 0;
    state.fetchRowsReportPretesseramento = value;
  },
  setCurrentPageReportPretesseramento: (state, value) => {
    state.currentPageReportPretesseramento = value;
    state.rowsToSkipReportPretesseramento = parseInt(
      (state.currentPageReportPretesseramento - 1) *
        state.fetchRowsReportPretesseramento
    );
  },
  setSortColumnReportPretesseramento: (state, value) => {
    state.sortColumnReportPretesseramento = value;
  },
  setSortOrderReportPretesseramento: (state, value) => {
    state.sortOrderReportPretesseramento = value;
  },
  resetFiltersReportPretesseramento: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipReportPretesseramento: (state) => {
    state.currentPageReportPretesseramento = 1;
    state.rowsToSkipReportPretesseramento = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
