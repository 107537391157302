import Swal from "sweetalert2/dist/sweetalert2.min.js";
import services from "@/axios/dbManag";

export const alertSuccess = (message, idModalToClose) => {
  const confirmButtonText = `
              <span
                type="button"
                class="me-3 badge bg-light-danger rounded text-danger fs-5"
                data-bs-dismiss="modal"
                data-bs-target="#${idModalToClose}"
              >
                Chiudi
              </span>`;
  Swal.fire({
    html: message,
    icon: "success",
    buttonsStyling: false,
    customClass: idModalToClose
      ? ""
      : {
          confirmButton: "me-3 badge bg-light-danger rounded text-danger fs-5",
        },
    confirmButtonText: idModalToClose ? confirmButtonText : "Chiudi",
    allowOutsideClick: false,
  });
};

export const alertFailed = (message, idModalToClose) => {
  const confirmButtonText = `
              <span
                type="button"
                class="me-3 badge bg-light-danger rounded text-danger fs-5"
                data-bs-dismiss="modal"
                data-bs-target="#${idModalToClose}"
              >
                Chiudi
              </span>`;
  return Swal.fire({
    html: message,
    icon: "error",
    buttonsStyling: false,
    customClass: idModalToClose
      ? ""
      : {
          confirmButton: "me-3 badge bg-light-danger rounded text-danger fs-5",
        },
    confirmButtonText: idModalToClose ? confirmButtonText : "Chiudi",
    allowOutsideClick: false,
  }).then((e) => {
    if (e.isDismissed) {
      return false;
    }
    return true;
  });
};

export const alertChoice = (
  message,
  text,
  cancelButtonText,
  confirmButtonText
) => {
  Swal.fire({
    title: message,
    text: text,
    buttonsStyling: false,
    showCancelButton: true,
    cancelButtonText,
    confirmButtonText,
    customClass: {
      confirmButton: "btn fw-bold btn-light-danger",
      cancelButton: "btn fw-bold btn-light-primary",
    },
  }).then((e) => {
    if (e.isDismissed) {
      return false;
    }
    return true;
  });
};
export const callFunctionAfterAlert = async (
  endpoint,
  payload,
  question,
  textAlertSuccess,
  textAlertFailed
) => {
  return Swal.fire({
    html: question ? question : "Sei sicuro?",
    confirmButtonText: "Conferma",
    showCancelButton: true,
    icon: "warning",
    cancelButtonText: "Annulla",
    allowOutsideClick: false,
    showLoaderOnConfirm: true,
    customClass: {
      confirmButton: "btn fw-bold btn-light-primary",
      cancelButton: "btn fw-bold btn-light-danger",
    },
    preConfirm: () => {
      return services.dbManag
        .post(endpoint, payload)
        .then(({ data, status: reqStatus, statusText }) => {
          return { data, status: reqStatus, statusText };
        })
        .catch((e) => {
          return e.response;
        });
    },
  }).then(async (result) => {
    const { data, status: reqStatus } = result.value;
    if (result.isConfirmed) {
      if (
        (reqStatus != 200 && reqStatus != 204) ||
        (reqStatus == 200 &&
          ((data.statusCode && data.statusCode != 200) ||
            (data.StatusCode && data.StatusCode != 200)))
      ) {
        alertFailed(data.message ? data.message : textAlertFailed);
        return { data, success: false };
      } else {
        alertSuccess(textAlertSuccess);
        return { data, success: true };
      }
    } else {
      return { data, success: false };
    }
  });
};
