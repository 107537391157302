const getDefaultState = () => {
  return {
    idSocProvenienzaTrasf: null,
    idSocDestinazioneTrasf: null,
    dataDaTrasf: null,
    dataATrasf: null,
    nomeTrasf: null,
    cognomeTrasf: null,
    matricolaTrasf: null,
    statoTrasf: null,
    onerosoTrasf: null,
    societaProvenienzaTrasf: null,
    societaDestinazioneTrasf: null,
    rowsToSkipTrasf: 0,
    fetchRowsTrasf: 25,
    currentPageTrasf: 1,
    sortColumnTrasf: "tesserato",
    sortOrderTrasf: "asc",
    tutteTrasfList: false,
  };
};
const state = getDefaultState();

const getters = {
  idSocProvenienzaTrasf: (state) => state.idSocProvenienzaTrasf,
  idSocDestinazioneTrasf: (state) => state.idSocDestinazioneTrasf,
  dataDaTrasf: (state) => state.dataDaTrasf,
  dataATrasf: (state) => state.dataATrasf,
  nomeTrasf: (state) => state.nomeTrasf,
  cognomeTrasf: (state) => state.cognomeTrasf,
  matricolaTrasf: (state) => state.matricolaTrasf,
  statoTrasf: (state) => state.statoTrasf,
  onerosoTrasf: (state) => state.onerosoTrasf,
  societaProvenienzaTrasf: (state) => state.societaProvenienzaTrasf,
  societaDestinazioneTrasf: (state) => state.societaDestinazioneTrasf,
  rowsToSkipTrasf: (state) => state.rowsToSkipTrasf,
  fetchRowsTrasf: (state) => state.fetchRowsTrasf,
  currentPageTrasf: (state) => state.currentPageTrasf,
  sortColumnTrasf: (state) => state.sortColumnTrasf,
  sortOrderTrasf: (state) => state.sortOrderTrasf,
  tutteTrasfList: (state) => state.tutteTrasfList,
};

// const actions = {};
const mutations = {
  setNomeSocProvenienzaTrasf: (state, value) => {
    state.societaProvenienzaTrasf = value;
  },
  setNomeSocDestinazioneTrasf: (state, value) => {
    state.societaDestinazioneTrasf = value;
  },
  setIdSocProvenienzaTrasf: (state, value) => {
    state.idSocProvenienzaTrasf = value;
  },
  setIdSocDestinazioneTrasf: (state, value) => {
    state.idSocDestinazioneTrasf = value;
  },
  setDataDaTrasf: (state, value) => {
    state.dataDaTrasf = value;
  },
  setDataATrasf: (state, value) => {
    state.dataATrasf = value;
  },
  setNomeTrasf: (state, value) => {
    state.nomeTrasf = value;
  },
  setCognomeTrasf: (state, value) => {
    state.cognomeTrasf = value;
  },
  setMatricolaTrasf: (state, value) => {
    state.matricolaTrasf = value;
  },
  setStatoTrasf: (state, value) => {
    state.statoTrasf = value;
  },
  setOnerosoTrasf: (state, value) => {
    state.onerosoTrasf = value;
  },
  setSocietaProvenienzaTrasf: (state, value) => {
    state.societaProvenienzaTrasf = value;
  },
  setSocietaDestinazioneTrasf: (state, value) => {
    state.societaDestinazioneTrasf = value;
  },
  setFetchRowsTrasf: (state, value) => {
    state.currentPageTrasf = 1;
    state.rowsToSkipTrasf = 0;
    state.fetchRowsTrasf = value;
  },
  setCurrentPageTrasf: (state, value) => {
    state.currentPageTrasf = value;
    state.rowsToSkipTrasf = parseInt(
      (state.currentPageTrasf - 1) * state.fetchRowsTrasf
    );
  },
  setSortColumnTrasf: (state, value) => {
    state.sortColumnTrasf = value;
  },
  setSortOrderTrasf: (state, value) => {
    state.sortOrderTrasf = value;
  },
  resetFiltersTrasf: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipTrasf: (state) => {
    state.currentPageTrasf = 1;
    state.rowsToSkipTrasf = 0;
  },
  setTutteLeTrasfList: (state) => {
    state.tutteTrasfList = !state.tutteTrasfList;
    state.currentPageTrasf = 1;
    state.rowsToSkipTrasf = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
