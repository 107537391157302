const getDefaultState = () => {
  return {
    stagioneReports: null,
    comparaReports: false,
    ids_comitatiReports: null,
    ids_livelliReports: null,
    data_daReports: null,
    data_aReports: null,
  };
};
const state = getDefaultState();

const getters = {
  stagioneReports: (state) => state.stagioneReports,
  comparaReports: (state) => state.comparaReports,
  ids_comitatiReports: (state) => state.ids_comitatiReports,
  ids_livelliReports: (state) => state.ids_livelliReports,
  data_daReports: (state) => state.data_daReports,
  data_aReports: (state) => state.data_aReports,
};

// const actions = {};
const mutations = {
  setstagioneReports: (state, value) => {
    state.stagioneReports = value;
  },
  setcomparaReports: (state, value) => {
    state.comparaReports = value;
  },
  setids_comitatiReports: (state, value) => {
    value = value.substring(0, value.length - 1);
    state.ids_comitatiReports = value;
  },
  setids_livelliReports: (state, value) => {
    value = value.substring(0, value.length - 1);
    state.ids_livelliReports = value;
  },
  setdata_daReports: (state, value) => {
    state.data_daReports = value;
  },
  setdata_aReports: (state, value) => {
    state.data_aReports = value;
  },
  resetReports: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
