const getDefaultState = () => {
  return {
    idStatoScuole: null,
    idDisciplinaScuole: null,
    idTipologiaScuole: null,
    idSocietaScuole: null,
    idComitatoScuole: null,
    societaDenominazioneScuole: null,
    rowsToSkipScuole: 0,
    fetchRowsScuole: 25,
    currentPageScuole: 1,
    sortColumnScuole: "societa",
    sortOrderScuole: "asc",
    tutteStagioniScuole: false,
  };
};
const state = getDefaultState();

const getters = {
  idStatoScuole: (state) => state.idStatoScuole,
  idDisciplinaScuole: (state) => state.idDisciplinaScuole,
  idTipologiaScuole: (state) => state.idTipologiaScuole,
  idSocietaScuole: (state) => state.idSocietaScuole,
  idComitatoScuole: (state) => state.idComitatoScuole,
  societaDenominazioneScuole: (state) => state.societaDenominazioneScuole,
  rowsToSkipScuole: (state) => state.rowsToSkipScuole,
  fetchRowsScuole: (state) => state.fetchRowsScuole,
  currentPageScuole: (state) => state.currentPageScuole,
  sortColumnScuole: (state) => state.sortColumnScuole,
  sortOrderScuole: (state) => state.sortOrderScuole,
  tutteStagioniScuole: (state) => state.tutteStagioniScuole,
};

// const actions = {};
const mutations = {
  setidStatoScuole: (state, value) => {
    state.idStatoScuole = value;
  },
  setidDisciplinaScuole: (state, value) => {
    state.idDisciplinaScuole = value;
  },
  setidTipologiaScuole: (state, value) => {
    state.idTipologiaScuole = value;
  },
  setidSocietaScuole: (state, value) => {
    state.idSocietaScuole = value;
  },
  setidComitatoScuole: (state, value) => {
    state.idComitatoScuole = value;
  },
  setsocietaDenominazioneScuole: (state, value) => {
    state.societaDenominazioneScuole = value;
  },
  setFetchRowsScuole: (state, value) => {
    state.currentPageScuole = 1;
    state.rowsToSkipScuole = 0;
    state.fetchRowsScuole = value;
  },
  setCurrentPageScuole: (state, value) => {
    state.currentPageScuole = value;
    state.rowsToSkipScuole = parseInt(
      (state.currentPageScuole - 1) * state.fetchRowsScuole
    );
  },
  setSortColumnScuole: (state, value) => {
    state.sortColumnScuole = value;
  },
  setSortOrderScuole: (state, value) => {
    state.sortOrderScuole = value;
  },
  resetFiltersScuole: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipScuole: (state) => {
    state.currentPageScuole = 1;
    state.rowsToSkipScuole = 0;
  },
  setTutteLeStagioniScuole: (state) => {
    state.tutteStagioniScuole = !state.tutteStagioniScuole;
    state.currentPageScuole = 1;
    state.rowsToSkipScuole = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
