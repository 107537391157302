const getDefaultState = () => {
  return {
    id_societaInCaricoOmologazione: null,
    denominazioneSocietaInCaricoOmologazione: "",
    id_omologatoreInCaricoOmologazione: null,
    denominazioneOmologatoreInCaricoOmologazione: "",
    tipologieInCaricoOmologazione: "6",
    disciplineInCaricoOmologazione: null,
    rowstoskipInCaricoOmologazione: 0,
    fetchrowsInCaricoOmologazione: 25,
    currentpageInCaricoOmologazione: 1,
    sortcolumnInCaricoOmologazione: "societa",
    sortorderInCaricoOmologazione: "asc",
    tutteLeStagioniInCaricoOmologazione: false,
  };
};
const state = getDefaultState();

const getters = {
  id_societaInCaricoOmologazione: (state) =>
    state.id_societaInCaricoOmologazione,
  id_omologatoreInCaricoOmologazione: (state) =>
    state.id_omologatoreInCaricoOmologazione,
  denominazioneSocietaInCaricoOmologazione: (state) =>
    state.denominazioneSocietaInCaricoOmologazione,
  denominazioneOmologatoreInCaricoOmologazione: (state) =>
    state.denominazioneOmologatoreInCaricoOmologazione,
  tipologieInCaricoOmologazione: (state) => state.tipologieInCaricoOmologazione,
  disciplineInCaricoOmologazione: (state) =>
    state.disciplineInCaricoOmologazione,
  rowstoskipInCaricoOmologazione: (state) =>
    state.rowstoskipInCaricoOmologazione,
  fetchrowsInCaricoOmologazione: (state) => state.fetchrowsInCaricoOmologazione,
  currentpageInCaricoOmologazione: (state) =>
    state.currentpageInCaricoOmologazione,
  sortcolumnInCaricoOmologazione: (state) =>
    state.sortcolumnInCaricoOmologazione,
  sortorderInCaricoOmologazione: (state) => state.sortorderInCaricoOmologazione,
  tutteLeStagioniInCaricoOmologazione: (state) =>
    state.tutteLeStagioniInCaricoOmologazione,
};

// const actions = {};
const mutations = {
  setid_societaInCaricoOmologazione: (state, value) => {
    state.id_societaInCaricoOmologazione = value;
  },
  setid_omologatoreInCaricoOmologazione: (state, value) => {
    state.id_omologatoreInCaricoOmologazione = value;
  },
  setdenominazioneSocietaInCaricoOmologazione: (state, value) => {
    state.denominazioneSocietaInCaricoOmologazione = value;
  },
  setdenominazioneOmologatoreInCaricoOmologazione: (state, value) => {
    state.denominazioneOmologatoreInCaricoOmologazione = value;
  },
  settipologieInCaricoOmologazione: (state, value) => {
    state.tipologieInCaricoOmologazione = value;
  },
  setdisciplineInCaricoOmologazione: (state, value) => {
    state.disciplineInCaricoOmologazione = value;
  },
  setfetchrowsInCaricoOmologazione: (state, value) => {
    state.currentpageInCaricoOmologazione = 1;
    state.rowstoskipInCaricoOmologazione = 0;
    state.fetchrowsInCaricoOmologazione = value;
  },
  setcurrentpageInCaricoOmologazione: (state, value) => {
    state.currentpageInCaricoOmologazione = value;
    state.rowstoskipInCaricoOmologazione = parseInt(
      (state.currentpageInCaricoOmologazione - 1) *
        state.fetchrowsInCaricoOmologazione
    );
  },
  setsortcolumnInCaricoOmologazione: (state, value) => {
    state.sortcolumnInCaricoOmologazione = value;
  },
  setsortorderInCaricoOmologazione: (state, value) => {
    state.sortorderInCaricoOmologazione = value;
  },
  setrowstoskipInCaricoOmologazione: (state) => {
    state.currentpageInCaricoOmologazione = 1;
    state.rowstoskipInCaricoOmologazione = 0;
  },
  setTutteLeStagioniInCaricoOmologazione: (state) => {
    state.tutteLeStagioniInCaricoOmologazione =
      !state.tutteLeStagioniInCaricoOmologazione;
    state.currentpageInCaricoOmologazione = 1;
    state.rowstoskipInCaricoOmologazione = 0;
  },
  resetFiltersInCaricoOmologazione: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
};
