import services from "../../axios/dbManag";
import { trimInput } from "@/composables/trimInput";

const getEntiAggregatiNomeCodAffApi = (denominazione) => {
  return services.dbManag
    .post("/anagrafiche/enti-aggregati/list", {
      denominazione,
      fetchRows: 15,
      rowsToSkip: 0,
      sortColumn: "id",
      sortOrder: "DESC",
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      state.errorPersone = true;
    });
};

const getEntiAggregatiProvApi = (denominazione) => {
  return services.dbManag
    .post("/lookup/enti-aggregati/trasferienti/list", {
      denominazione,
      fetchRows: 15,
      rowsToSkip: 0,
      sortColumn: "id",
      sortOrder: "DESC",
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      state.errorPersone = true;
    });
};

const getEntiAggregatiListApi = (
  cod_affiliazione,
  denominazione,
  comitato,
  rowsToSkip,
  fetchRows,
  sortColumn,
  sortOrder,
  stagione,
  stagioneCorrenteEntiAggregati,
  disciplina,
  affiliazioneDal,
  affiliazioneAl,
  regione,
  provincia,
  comune,
  documentiModificabili,
  stato
) => {
  return services.dbManag
    .post("/anagrafiche/enti-aggregati/list", {
      cod_affiliazione: trimInput(cod_affiliazione),
      denominazione: trimInput(denominazione),
      comitato,
      rowsToSkip,
      fetchRows,
      sortColumn,
      sortOrder,
      stagione,
      stagioneCorrenteEntiAggregati,
      disciplina,
      affiliazioneDal,
      affiliazioneAl,
      regione,
      provincia,
      comune,
      documentiModificabili,
      stato,
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      // state.errorEntiAggregati = true;
    });
};

const getDefaultState = () => {
  return {
    entiAggregati: [],
    totalElementsEntiAggregatiEntiAggregati: 0,
    currentPageEntiAggregati: 1,
    loadingEntiAggregati: true,
    errorEntiAggregati: false,
    campiLockedEntiAggregati: "",
    comandiLockedEntiAggregati: "",

    cod_affiliazioneEntiAggregati: null,
    denominazioneEntiAggregati: null,
    comitatoEntiAggregati: null,
    rowsToSkipEntiAggregati: 0,
    fetchRowsEntiAggregati: 25,
    sortColumnEntiAggregati: "id",
    sortOrderEntiAggregati: "desc",
    stagioneEntiAggregati: null,
    stagioneCorrenteEntiAggregati: null,
    tutteStagioniEntiAggregati: false,
    disciplinaEntiAggregati: null,
    affiliazioneDalEntiAggregati: null,
    affiliazioneAlEntiAggregati: null,
    regioneEntiAggregati: null,
    provinciaEntiAggregati: null,
    comuneEntiAggregati: null,
    comuneTextEntiAggregati: "",
    documentiModificabiliEntiAggregati: null,
    statoEntiAggregati: null,
    entiAggregatiNomeCodAff: [],
  };
};
const state = getDefaultState();

const getters = {
  entiAggregati: (state) => state.entiAggregati,
  loadingEntiAggregati: (state) => state.loadingEntiAggregati,
  errorEntiAggregati: (state) => state.errorEntiAggregati,
  currentPageEntiAggregati: (state) => state.currentPageEntiAggregati,

  tutteStagioniEntiAggregati: (state) => state.tutteStagioniEntiAggregati,

  totalElementsEntiAggregati: (state) => state.totalElementsEntiAggregati,
  campiLockedEntiAggregati: (state) => state.campiLockedEntiAggregati,
  comandiLockedEntiAggregati: (state) => state.comandiLockedEntiAggregati,

  cod_affiliazioneEntiAggregati: (state) => state.cod_affiliazioneEntiAggregati,
  denominazioneEntiAggregati: (state) => state.denominazioneEntiAggregati,
  comitatoEntiAggregati: (state) => state.comitatoEntiAggregati,
  rowsToSkipEntiAggregati: (state) => state.rowsToSkipEntiAggregati,
  fetchRowsEntiAggregati: (state) => state.fetchRowsEntiAggregati,
  sortColumnEntiAggregati: (state) => state.sortColumnEntiAggregati,
  sortOrderEntiAggregati: (state) => state.sortOrderEntiAggregati,
  stagioneEntiAggregati: (state) => state.stagioneEntiAggregati,
  stagioneCorrenteEntiAggregati: (state) => state.stagioneCorrenteEntiAggregati,
  disciplinaEntiAggregati: (state) => state.disciplinaEntiAggregati,
  affiliazioneDalEntiAggregati: (state) => state.affiliazioneDalEntiAggregati,
  affiliazioneAlEntiAggregati: (state) => state.affiliazioneAlEntiAggregati,
  regioneEntiAggregati: (state) => state.regioneEntiAggregati,
  provinciaEntiAggregati: (state) => state.provinciaEntiAggregati,
  comuneEntiAggregati: (state) => state.comuneEntiAggregati,
  documentiModificabiliEntiAggregati: (state) =>
    state.documentiModificabiliEntiAggregati,
  statoEntiAggregati: (state) => state.statoEntiAggregati,
  entiAggregatiNomeCodAff: (state) => state.entiAggregatiNomeCodAff,
  comuneTextEntiAggregati: (state) => state.comuneTextEntiAggregati,
};
const actions = {
  getEntiAggregatiList: async (stateObj) => {
    const { state } = stateObj;
    if (state.stagioneEntiAggregati === null)
      state.stagioneEntiAggregati =
        stateObj.rootState.LookupStagione.defaultStagione;
    state.errorEntiAggregati = false;
    if (!state.loadingEntiAggregati) stateObj.commit("setloading");
    const res = await getEntiAggregatiListApi(
      state.cod_affiliazioneEntiAggregati,
      state.denominazioneEntiAggregati,
      state.comitatoEntiAggregati,
      state.rowsToSkipEntiAggregati,
      state.fetchRowsEntiAggregati,
      state.sortColumnEntiAggregati,
      state.sortOrderEntiAggregati,
      state.tutteStagioniEntiAggregati ? 10000 : state.stagioneEntiAggregati,
      state.stagioneCorrenteEntiAggregati,
      state.disciplinaEntiAggregati,
      state.affiliazioneDalEntiAggregati,
      state.affiliazioneAlEntiAggregati,
      state.regioneEntiAggregati,
      state.provinciaEntiAggregati,
      state.comuneEntiAggregati,
      state.documentiModificabiliEntiAggregati,
      state.statoEntiAggregati
    );
    stateObj.commit("setEntiAggregatiList", res);
  },

  getEntiAggregatiNomeCodAff: async (stateObj, string) => {
    const denominazione = string;
    // const cod_affiliazione = string.split(" ")[1];

    const res = await getEntiAggregatiNomeCodAffApi(denominazione);

    stateObj.commit("setEntiAggregatiNomeCodAffList", res);
  },

  getEntiAggregatiProv: async (stateObj, string) => {
    const denominazione = string;
    // const cod_affiliazione = string.split(" ")[1];

    const res = await getEntiAggregatiProvApi(denominazione);
    stateObj.commit("setEntiAggregatiProvList", res);
  },
};

const mutations = {
  emptyEntiAggregatiNomeCodAff: (state) => {
    state.entiAggregatiNomeCodAff.splice(
      0,
      state.entiAggregatiNomeCodAff.length
    );
  },

  setEntiAggregatiNomeCodAffList: (state, res) => {
    const { results } = res;
    state.entiAggregatiNomeCodAff.splice(
      0,
      state.entiAggregatiNomeCodAff.length
    );
    results.forEach((element) => {
      state.entiAggregatiNomeCodAff.push(element);
    });
  },
  setEntiAggregatiProvList: (state, res) => {
    const { results } = res;
    state.entiAggregatiNomeCodAff.splice(
      0,
      state.entiAggregatiNomeCodAff.length
    );
    results.forEach((element) => {
      state.entiAggregatiNomeCodAff.push(element);
    });
  },
  setStagioneEntiAggregati: (state, value) => {
    state.stagioneEntiAggregati = value;
  },
  setEntiAggregatiList: (
    state,
    { campi_Locked, comandi_Locked, record, results }
  ) => {
    state.entiAggregati.splice(0, state.entiAggregati.length);
    state.campiLockedEntiAggregati = campi_Locked;
    state.comandiLockedEntiAggregati = comandi_Locked;
    state.totalElementsEntiAggregati = record;
    results.forEach((soc) => {
      state.entiAggregati.push(soc);
    });
    if (state.loadingEntiAggregati)
      state.loadingEntiAggregati = !state.loadingEntiAggregati;
  },
  seterrorEntiAggregati: (state) => {
    state.errorEntiAggregati = !state.errorEntiAggregati;
  },
  setloadingEntiAggregati: (state) => {
    state.loadingEntiAggregati = !state.loadingEntiAggregati;
  },
  setFetchRowsEntiAggregati: (state, value) => {
    state.fetchRowsEntiAggregati = value;
  },
  setPageEntiAggregati: (state, value) => {
    state.currentPageEntiAggregati = value;
    state.rowsToSkipEntiAggregati = parseInt(
      (state.currentPageEntiAggregati - 1) * state.fetchRowsEntiAggregati
    );
  },
  resetEntiAggregati: (state) => {
    state.currentPageEntiAggregati = 1;
    state.denominazioneEntiAggregati = null;
    state.comitatoEntiAggregati = null;
    state.cod_affiliazioneEntiAggregati = null;
    state.disciplinaEntiAggregati = null;
    state.regioneEntiAggregati = null;
    state.provinciaEntiAggregati = null;
    state.comuneEntiAggregati = null;
    state.stagioneEntiAggregati = null;
    state.statoEntiAggregati = null;
    state.affiliazioneDalEntiAggregati = null;
    state.affiliazioneAlEntiAggregati = null;
    state.documentiModificabiliEntiAggregati = null;
    state.sortColumnEntiAggregati = "id";
    state.sortOrderEntiAggregati = "desc";
    state.rowsToSkipEntiAggregati = 0;
    state.comuneTextEntiAggregati = "";
  },
  setRowsToSkipEntiAggregati: (state) => {
    state.currentPageEntiAggregati = 1;
    state.rowsToSkipEntiAggregati = 0;
  },
  setDocumentiModificabiliEntiAggregati: (state, value) => {
    state.documentiModificabiliEntiAggregati = value;
  },
  setStatoEntiAggregati: (state, value) => {
    state.statoEntiAggregati = value;
  },
  setCodAffiliazioneEntiAggregati: (state, value) => {
    state.cod_affiliazioneEntiAggregati = value;
  },
  setDenominazioneEntiAggregati: (state, value) => {
    state.denominazioneEntiAggregati = value;
  },
  setComitatoEntiAggregati: (state, value) => {
    state.comitatoEntiAggregati = value;
  },
  setDisciplinaEntiAggregati: (state, value) => {
    state.disciplinaEntiAggregati = value;
  },
  setInizioAffEntiAggregati: (state, value) => {
    state.affiliazioneDalEntiAggregati = value;
  },
  setFineAffEntiAggregati: (state, value) => {
    state.affiliazioneAlEntiAggregati = value;
  },
  setRegioneSocEntiAggregati: (state, value) => {
    state.regioneEntiAggregati = value;
  },
  setProvinciaSocEntiAggregati: (state, value) => {
    state.provinciaEntiAggregati = value;
  },
  setComuneSocEntiAggregati: (state, value) => {
    state.comuneEntiAggregati = value;
  },
  setSelectedComuneTextEntiAggregati: (state, value) => {
    state.comuneTextEntiAggregati = value;
  },
  setSortColumnEntiAggregatiEntiAggregati: (state, value) => {
    state.sortColumnEntiAggregati = value;
  },
  setSortOrderEntiAggregatiEntiAggregati: (state, value) => {
    state.sortOrderEntiAggregati = value;
  },
  setTutteStagioniEntiAggregati: (state) => {
    state.tutteStagioniEntiAggregati = !state.tutteStagioniEntiAggregati;
    state.currentPageEntiAggregati = 1;
    state.rowToSkipEntiAggregati = 0;
  },
  resetFiltersEntiAggregati: (state) => {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
