const getDefaultState = () => {
  return {
    showCreateUser: false,
  };
};
const state = getDefaultState();

const getters = {
  showCreateUser: (state) => state.showCreateUser,
};

// const actions = {};
const mutations = {
  setShowCreateUser: (state, value) => {
    state.showCreateUser = value ? value : null;
  },
};

export default {
  state,
  getters,
  mutations,
};
