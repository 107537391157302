const getDefaultState = () => {
  return {
    // anno: 0,
    data_ultimo_invio_raas_tesseramenti: null, // "2023-01-02T13:35:10.715Z",
    denominazione_raas_tesseramenti: null, //"string",
    codice_fiscale_raas_tesseramenti: null, //"string",
    codice_affiliazione_raas_tesseramenti: null, //"string",
    id_comitato_raas_tesseramenti: null, //0,
    stati_invio_raas_tesseramenti: null, //"string",
    rowtoskip_raas_tesseramenti: 0, //0,
    fetchrows_raas_tesseramenti: 25, //0,
    sortcolumn_raas_tesseramenti: "ultimo_invio", //"string",
    sortorder_raas_tesseramenti: "desc", //"string",
    currentPage_raas_tesseramenti: 1,
  };
};
const state = getDefaultState();

const getters = {
  //   anno: (state) => state.disciplinaRanking,
  data_ultimo_invio_raas_tesseramenti: (state) =>
    state.data_ultimo_invio_raas_tesseramenti,
  denominazione_raas_tesseramenti: (state) =>
    state.denominazione_raas_tesseramenti,
  codice_fiscale_raas_tesseramenti: (state) =>
    state.codice_fiscale_raas_tesseramenti,
  codice_affiliazione_raas_tesseramenti: (state) =>
    state.codice_affiliazione_raas_tesseramenti,
  id_comitato_raas_tesseramenti: (state) => state.id_comitato_raas_tesseramenti,
  stati_invio_raas_tesseramenti: (state) => state.stati_invio_raas_tesseramenti,
  rowtoskip_raas_tesseramenti: (state) => state.rowtoskip_raas_tesseramenti,
  fetchrows_raas_tesseramenti: (state) => state.fetchrows_raas_tesseramenti,
  sortcolumn_raas_tesseramenti: (state) => state.sortcolumn_raas_tesseramenti,
  sortorder_raas_tesseramenti: (state) => state.sortorder_raas_tesseramenti,
  currentPage_raas_tesseramenti: (state) => state.currentPage_raas_tesseramenti,
};

const actions = {
  setInviateTesseramentiRaas: (context) => {
    context.commit("setStati_invio_raas_tesseramenti", "2");
  },
  setDaInviareTesseramentiRaas: (context) => {
    context.commit("setStati_invio_raas_tesseramenti", "1");
  },
  setErroriTesseramentiRaas: (context) => {
    context.commit("setStati_invio_raas_tesseramenti", "3");
  },
  setDaReinviareTesseramentiRaas: (context) => {
    context.commit("setStati_invio_raas_tesseramenti", "4");
  },
};
const mutations = {
  setData_ultimo_invio_raas_tesseramenti: (state, value) => {
    state.data_ultimo_invio_raas_tesseramenti = value;
  },
  setDenominazione_raas_tesseramenti: (state, value) => {
    state.denominazione_raas_tesseramenti = value;
  },
  setCodice_fiscale_raas_tesseramenti: (state, value) => {
    state.codice_fiscale_raas_tesseramenti = value;
  },
  setCodice_affiliazione_raas_tesseramenti: (state, value) => {
    state.codice_affiliazione_raas_tesseramenti = value;
  },
  setId_comitato_raas_tesseramenti: (state, value) => {
    state.id_comitato_raas_tesseramenti = value;
  },
  setStati_invio_raas_tesseramenti: (state, value) => {
    if (
      state.stati_invio_raas_tesseramenti &&
      state.stati_invio_raas_tesseramenti.includes(value)
    ) {
      if (state.stati_invio_raas_tesseramenti.includes(`,${value}`))
        state.stati_invio_raas_tesseramenti =
          state.stati_invio_raas_tesseramenti.replace(`,${value}`, "");
      else if (state.stati_invio_raas_tesseramenti.includes(`${value},`))
        state.stati_invio_raas_tesseramenti =
          state.stati_invio_raas_tesseramenti.replace(`${value},`, "");
      else
        state.stati_invio_raas_tesseramenti =
          state.stati_invio_raas_tesseramenti.replace(`${value}`, "");
    } else {
      state.stati_invio_raas_tesseramenti = `${
        state.stati_invio_raas_tesseramenti
          ? state.stati_invio_raas_tesseramenti + ","
          : ""
      }${value}`;
    }
    if (state.stati_invio_raas_tesseramenti === "")
      state.stati_invio_raas_tesseramenti = null;
  },
  //   setInviateTesseramentiRaas: (state, value) => {
  //     state.stati_invio_raas_tesseramenti =  `${state.stati_invio_raas_tesseramenti},${value}`;
  //   },

  setFetchRowsTesseramentiRaas: (state, value) => {
    state.currentPage_raas_tesseramenti = 1;
    state.rowtoskip_raas_tesseramenti = 0;
    state.fetchrows_raas_tesseramenti = value;
  },
  setCurrentPageTesseramentiRaas: (state, value) => {
    state.currentPage_raas_tesseramenti = value;
    state.rowtoskip_raas_tesseramenti = parseInt(
      (state.currentPage_raas_tesseramenti - 1) *
        state.fetchrows_raas_tesseramenti
    );
  },
  setSortColumnTesseramentiRaas: (state, value) => {
    state.sortcolumn_raas_tesseramenti = value;
  },
  setSortOrderTesseramentiRaas: (state, value) => {
    state.sortorder_raas_tesseramenti = value;
  },
  resetFiltersTesseramentiRaas: (state) => {
    Object.assign(state, getDefaultState());
  },
  setRowsToSkipTesseramentiRaas: (state) => {
    state.currentPage_raas_tesseramenti = 1;
    state.rowtoskip_raas_tesseramenti = 0;
  },
  //   setTutteLeStagioniRanking: (state) => {
  //     state.tutteStagioniRanking = !state.tutteStagioniRanking;
  //     state.currentPageRanking = 1;
  //     state.rowsToSkipRanking = 0;
  //   },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
