const getDefaultStateMovimenti = () => {
  return {
    stagioneEconomatoMovimenti: null,
    idComitatoRegionaleMovimenti: null,
    dataInsDaMovimenti: null,
    dataInsAMovimenti: null,
    dataApprDaMovimenti: null,
    dataApprAMovimenti: null,

    idFamigliaMovimentoMovimenti: null,
    idMovimentoManualeMovimenti: null,
    tipoCredDebMovimenti: null,
    idSocietaMovimenti: null,
    nomeSocietaMovimenti: "",
    idTesseratoMovimenti: null,
    nomeTesseratoMovimenti: null,
    numeroMovimenti: null,
    meseApprovazioneMovimenti: null,
    causaleMovimenti: null,

    approvatoMovimenti: null,
    rowsToSkipMovimenti: 0,
    fetchRowsMovimenti: 25,
    currentPageMovimenti: 1,
    sortColumnMovimenti: "numero",
    sortOrderMovimenti: "desc",
  };
};
const state = getDefaultStateMovimenti();

const getters = {
  stagioneEconomatoMovimenti: (state) => state.stagioneEconomatoMovimenti,
  idComitatoRegionaleMovimenti: (state) => state.idComitatoRegionaleMovimenti,
  dataInsDaMovimenti: (state) => state.dataInsDaMovimenti,
  dataInsAMovimenti: (state) => state.dataInsAMovimenti,
  dataApprDaMovimenti: (state) => state.dataApprDaMovimenti,
  dataApprAMovimenti: (state) => state.dataApprAMovimenti,

  idFamigliaMovimentoMovimenti: (state) => state.idFamigliaMovimentoMovimenti,
  idMovimentoManualeMovimenti: (state) => state.idMovimentoManualeMovimenti,
  tipoCredDebMovimenti: (state) => state.tipoCredDebMovimenti,
  idSocietaMovimenti: (state) => state.idSocietaMovimenti,
  nomeSocietaMovimenti: (state) => state.nomeSocietaMovimenti,
  idTesseratoMovimenti: (state) => state.idTesseratoMovimenti,
  nomeTesseratoMovimenti: (state) => state.nomeTesseratoMovimenti,
  numeroMovimenti: (state) => state.numeroMovimenti,
  meseApprovazioneMovimenti: (state) => state.meseApprovazioneMovimenti,
  causaleMovimenti: (state) => state.causaleMovimenti,
  approvatoMovimenti: (state) => state.approvatoMovimenti,

  rowsToSkipMovimenti: (state) => state.rowsToSkipMovimenti,
  fetchRowsMovimenti: (state) => state.fetchRowsMovimenti,
  currentPageMovimenti: (state) => state.currentPageMovimenti,
  sortColumnMovimenti: (state) => state.sortColumnMovimenti,
  sortOrderMovimenti: (state) => state.sortOrderMovimenti,
};

// const actions = {};
const mutations = {
  setNomeSocietaMovimenti: (state, value) => {
    state.nomeSocietaMovimenti = value ? value : null;
  },
  setNomeTesseratoMovimenti: (state, value) => {
    state.nomeTesseratoMovimenti = value ? value : null;
  },
  setIdComitatoRegionaleMovimenti: (state, value) => {
    state.idComitatoRegionaleMovimenti = value;
  },
  setDataInsDaMovimenti: (state, value) => {
    state.dataInsDaMovimenti = value;
  },
  setDataInsAMovimenti: (state, value) => {
    state.dataInsAMovimenti = value;
  },
  setDataApprDaMovimenti: (state, value) => {
    state.dataApprDaMovimenti = value;
  },
  setDataApprAMovimenti: (state, value) => {
    state.dataApprAMovimenti = value;
  },

  setIdFamigliaMovimentoMovimenti: (state, value) => {
    state.idFamigliaMovimentoMovimenti = value;
  },
  setIdMovimentoManualeMovimenti: (state, value) => {
    state.idMovimentoManualeMovimenti = value;
  },
  setTipoCredDebMovimenti: (state, value) => {
    state.tipoCredDebMovimenti = value;
  },
  setSocietaMovimenti: (state, value) => {
    state.idSocietaMovimenti = value ? value.value : null;
    state.nomeSocietaMovimenti = value ? value.name : null;
  },
  setPersonaMovimenti: (state, value) => {
    state.idTesseratoMovimenti = value ? value.value : null;
    state.nomeTesseratoMovimenti = value ? value.name : null;
  },
  setNumeroMovimenti: (state, value) => {
    state.numeroMovimenti = value;
  },
  setMeseApprovazioneMovimenti: (state, value) => {
    state.meseApprovazioneMovimenti = value;
  },
  setCausaleMovimenti: (state, value) => {
    state.causaleMovimenti = value;
  },
  setApprovatoMovimenti: (state, value) => {
    state.approvatoMovimenti = value;
  },

  setFetchRowsMovimenti: (state, value) => {
    state.currentPageMovimenti = 1;
    state.rowsToSkipMovimenti = 0;
    state.fetchRowsMovimenti = value;
  },
  setCurrentPageMovimenti: (state, value) => {
    state.currentPageMovimenti = value;
    state.rowsToSkipMovimenti = parseInt(
      (state.currentPageMovimenti - 1) * state.fetchRowsMovimenti
    );
  },
  setSortColumnMovimenti: (state, value) => {
    state.sortColumnMovimenti = value;
  },
  setSortOrderMovimenti: (state, value) => {
    state.sortOrderMovimenti = value;
  },
  setCategoriaTesseraMovimenti: (state, value) => {
    state.id_categoriaMovimenti = value;
  },
  resetFiltersMovimenti: (state) => {
    Object.assign(state, getDefaultStateMovimenti());
  },
  resetRowsToSkipMovimenti: (state) => {
    state.currentPageMovimenti = 1;
    state.rowsToSkipMovimenti = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
